import CoreService from "../services/Core";
import _ from "lodash";
import AuthConfig from "../config/auth";
import qs from "querystring";

class Login {

    constructor () {
        this.path = "/login";
        this.config = AuthConfig;
    }


  BuildLoginUrl () {
    const {baseUrl, appId, redirectUri} = this.config;
    let path = "oauth/authorize";
    let params = {
      scope: "openid",
      response_type: "code",
      client_id: appId,
      redirect_uri: redirectUri
    };
    return `${baseUrl}${path}?${qs.stringify(params)}`;
  }    

    async Authenticate (username, password) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.post(`${this.path}`, {username, password});
            _.set(result, "data", response.data.data );
        } catch (e) {
            _.set(result, "error",  ((e.response)) ? _.get(e, "response.data.error.message") : "Invalid User Credentials" );
        }
        return result;
    }

    async Token (userId) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.post(`/token`, {userId});
            _.set(result, "data", response.data.data );
        } catch (e) {
            _.set(result, "error",  ((e.response)) ? _.get(e, "response.data.error.message") : "Cannot Communicate to the API Server." );
        }
        return result;
    }


}

export default new Login();