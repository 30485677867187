import React, { Component } from "react";


class NotFound extends Component {
  render () {

    return (
      <div className="main-panel">
        <div className="content">
          <h1>Resource is not found!</h1>
        </div>
      </div> 
    );        
  }
}

export default NotFound;  