import React, { Fragment } from "react";

//common
import Input from "../../../common/form/input";

const Form = props => {
    const {isEditMode} = props.state; 
    return (

        <Fragment>
            <div className="row">
                <div className="col-md-6">
                    <Input 
                        label={"Code"} 
                        onChange={props.onChangeCode} 
                        value={props.state.code} 
                        name={"code"} 
                        disabled={!isEditMode}                        
                    />                
                </div>
                <div className="col-md-6">
                    <Input 
                        label={"Name"} 
                        onChange={props.onChangeName} 
                        value={props.state.name} 
                        name={"name"} 
                        disabled={!isEditMode}                        
                    />
                </div>
            </div>
        </Fragment>            

    );
}


export default Form;
