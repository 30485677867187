import React  from "react";
import {includes} from "lodash";
// import {withRouter} from "react-router-dom";

import AclService from "../../services/AclService";

const Dashboard = props => {
    const {items} = props;
    let role = AclService.getRole();
    const tilePermissions = AclService.getTilePermission();
    return (
        <>
            <div className="dashboard-item-wrap">
                {items.map( (item, i) => {
                    if (role === "user") {
                        return (includes(tilePermissions, item.id)) ? <DashboardItem {...item} key={i}/> : null;
                    } else {
                        return <DashboardItem {...item} key={i}/>    
                    }
                })}
            </div>
        </>
    );
};

const LinkJumper = props => {
    const FILTER_STORAGE_KEY = "poi-filters";
    localStorage.removeItem( FILTER_STORAGE_KEY );
    let filters = {brand: props.brands}; 
    localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(filters) );        
    window.location = "/point-of-interests/maps";
    return true;
}


const DashboardItem = props => {
    return (
        <div className="item">
            <div className="card">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-3">
                            <div className="icon-big text-center icon-warning">
                                <i className="ti-location-pin"></i>
                            </div>
                        </div>                    
                        <div className="col-xs-9">
                            <div className="numbers">
                                <p>{props.name}</p>
                                {props.count} Pins
                            </div>
                        </div>
                    </div>
                    <div className="footer tile-dashboard">
                        <hr />
                        <div className="stats">
                            <button href={`#`} onClick={() => {LinkJumper(props)}}> View Map</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    );
};

export default Dashboard;