import React from "react";
import _ from "lodash";

const Modal = props => {
    const {title, element} = props;
    const modalClass = `modal-dialog  modal-dialog-centered modal-md`;
    // let formBody = (_.isFunction (FormBody) ) ? <FormBody isSuccessful={isSuccessful}/> : FormBody;
    return (
        <div className="modal fade"  id={_.trim(element, "#")}>
            <div className={modalClass} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{ title }</h5>
                        <button type="button" className="close" data-dismiss="modal">
                          <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        { props.children }
                    </div> 
                </div>
            </div>
        </div>  
    );

};

export default Modal;