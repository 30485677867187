import React from "react";

const Success = ({success}) => {
    if (success) {
        return (
                <div className="card">
                    <div className="alert alert-info alert-dismissible">
                        {/*<button type="button" data-dismiss="alert" className="close">×</button>*/}
                        <div className="icon"><i className="icon ti-check-box"></i></div>
                        <div className="message"><b> {success} </b>  </div>
                    </div>
                </div>        );
    } else {
        return null;
    }
}

export default Success;