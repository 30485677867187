import React from "react";

const TableHeader = props => {
    return (
        <thead className="table-header">
            <tr>
                {
                    props.headers.map( (column, i) => {
                        return <th key={i}>{column}</th>
                    })
                }
            </tr>
        </thead>         
    );
}

export default TableHeader;