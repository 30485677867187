import React, {Fragment} from "react";
import {NavLink} from "react-router-dom";

import Input from "../common/form/input";
import Loader from "../common/loader";



const Profile = props => {
    let {showLoader, buttonDisabled} = props.state;
    let loader = (showLoader) ? <Loader message={"Saving..."}/> : null;
    return (
        <Fragment>

            <form onSubmit={props.onSubmit}>
            <div className="col-md-8">
                <div className="card">
                    <div className="header">
                        <h4 className="title">My Profile Details</h4>
                    </div>
                    <br/>

                    <div className="content">

                        <div className="row">

                            <div className="col-md-6">
                                <Input 
                                    label={"Username"} 
                                    value={props.state.username} 
                                    disabled={1}
                                />
                                <Input 
                                    label={"First Name"} 
                                    value={props.state.firstName} 
                                    onChange={props.onChangeFirstName}
                                />  
                            </div>

                            <div className="col-md-6">
                                <Input 
                                    label={"Email"} 
                                    value={props.state.email} 
                                    onChange={props.onChangeEmail}
                                />  
                                <Input 
                                    label={"Last Name"} 
                                    value={props.state.lastName} 
                                    onChange={props.onChangeLastName}
                                    
                                />                                  
                            </div>

                            <div className="col-md-12">
                                <NavLink to={'/profile/change-password'}><h6><span className="ti-lock"></span> Change Password</h6></NavLink>
                            </div>    
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                            </div>
                            <div className="col-md-3 text-center">
                                {loader}
                            </div>
                            <div className="col-md-5 text-right">
                                <div className="form-group">
                                    <input type="submit" className="btn btn-info btn-fill" value="Submit"  disabled={buttonDisabled}   />
                                </div>
                            </div>
                        </div>

                    </div>
                   
                </div>  
            </div>
            </form>
        </Fragment>
    );
};

export default Profile;