import React from "react";
import { Route } from "react-router-dom";
import { withRouter } from "react-router";
import AclService from "../services/AclService";
import Errors from "./common/notif/errors";

const AclRoute = props => {
    let {roles = []} = props;
    let isRoleAllowed = AclService.checkRolePermission(roles);
    const currentRole = AclService.getRole();
    let isAllowed = true;

    if (isRoleAllowed) {//role based checking
        
        if ( props.module && props.roleChecking === currentRole) {
            isAllowed = AclService.checkModulePermission(props.module, props.manage);
        } else {
            isAllowed = true;
        }

    } else {
        isAllowed  = false;
    }
    return (isAllowed) ? <Route {...props}></Route> : <NotAllowed />;
}

const NotAllowed = props => {
    return (
        <div className="col-md-12">
            <Errors error={'You are not allowed to access this page.'} /> 
        </div>    
    )
}



export default withRouter(AclRoute);