import React from "react";
import TableHeader from "./table-header";
import TableBody from "./table-body";

const Table = props => {
    return (
        <div className="col-md-12">
            <div className="card">
                <div className="content table-responsive table-full-width">
                    <table className="table table-striped">

                        <TableHeader headers={props.headers}/>
                        <TableBody {...props} />
                    </table>           
                </div>
            </div>
        </div>        
    );
};

export default Table;