import React from "react";

const Errors = ({error}) => {
    if (error) {
        return (
            <div className="card">
                <div className="alert alert-warning">
                    {/*<button type="button" data-dismiss="alert" className="close">×</button>                    */}
                    <div className="icon"><i className="icon ti-alert"></i></div>
                    <div className="message"><b> {error} </b> </div>
                </div>
            </div>        
        );
    } else {
        return null;
    }
}

export default Errors;