import React, {Fragment} from "react";

//common
import Loader from "../common/loader";

// import AclService from "../../services/AclService";
// let userRole = AclService.getRole();
const ButtonRow = props => {
    const {
      isEditMode,
      showLoader,
      buttonDisabled,
      disableEditMode,
      deleteAction = () => {}
    } = props;

    if (isEditMode) {
        let loader = (showLoader) ? <Loader message={"Saving..."}/> : null;
        return (
            <div className="row">
                <div className="col-md-4">

                    <button type="button" className="btn btn-danger btn-fill" onClick={ (e) => {deleteWrapper(deleteAction) } }   disabled={buttonDisabled}  >
                        <i className="ti-trash"></i> Delete
                    </button>
                </div>
                <div className="col-md-3 text-center">
                    {loader}
                </div>
                <div className="col-md-5 text-right">
                    <div className="form-group">
                        <Fragment>
                            <input type="submit" className="btn btn-info btn-fill" value="Submit"  disabled={buttonDisabled}   />
                            &nbsp;
                            <button type="button" className="btn btn-info" onClick={disableEditMode}  disabled={buttonDisabled} >
                                Exit Edit Mode
                            </button>
                        </Fragment>
                    </div>
                </div>
            </div>        
        );

    } else {
        return null;
    }

}

const deleteWrapper = (action) => {
    let ans = window.confirm("Are you sure you want to delete?");
    return (ans) ? action() : () => {}
}

export default ButtonRow;