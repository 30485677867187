import React, {useState, useEffect} from "react";

import PoiEntity from "../../../../../models/PoiEntity";
import CheckItem from "../check-item";
import {orderBy} from "lodash";

let DataModel = new PoiEntity("refilling plant");

const RefillingPlants = props => {

    const {checkHandler, checkedItems} = props;

    const [data, setData] = useState([]); 
    const [error, setError] = useState([]); 

    useEffect(() => {

        async function GetData () {
            let {data, error} = await DataModel.GetDropdownList();
            setData( orderBy(data, 'label', 'asc') ) ;
            setError(error);
        }
        GetData();

    }, []);
    if (error) {
        return <p>{error}</p>
    }
    if (!data) {
        return <p>Loading checklist...</p>
    }
    return (
        <div className="col-md-12">
            <h5 className="field-label" data-toggle="collapse" href=".check-wrapper.refilling-plants">
                <a href={"#!"}> RefillingPlants</a>
            </h5>
            <div className="check-wrapper refilling-plants collapse in">
              <ul>
                    {
                        data.map( ( d ) => <CheckItem {...d} checkHandler={checkHandler} checkedItems={checkedItems} />)
                    }
              </ul>
            </div>      
        </div>
    );
};

export default RefillingPlants;