import React, { Component, Fragment } from "react";
import _ from "lodash";
import BreadCrumbs from "../../layout/breadcrumbs";
import {Redirect} from "react-router-dom";
import RecordMetaData from "../../layout/metadata";
import Errors from "../../common/notif/errors";
import Success from "../../common/notif/success";
import Loader from "../../common/loader";

/*Load Models*/
import Poi from "../../../models/Poi";
import _PoiEntity from "../../../models/PoiEntity";
// import District from "../../../models/District";
import Brand from "../../../models/Brand";
import Type from "../../../models/Type";
import Province from "../../../models/Province";
// import Owner from "../../../models/Owner";
import PoiGeofence from "../../../models/PoiGeofence";

import AclService from "../../../services/AclService";


/**/
import Form from "../../views/point-of-interest/pin/form";
let RefillingPlant = new _PoiEntity("refilling plant");

class PointOfInterest extends Component {
    _currentUserRole = null;
    _permittedBrands = null;
    _brandManageAccess = null;

    constructor (props) {
        super(props);
        this.poiId =  _.get(this.props, "match.params.id");

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeDistrict = this.onChangeDistrict.bind(this);
        this.onChangeBrand = this.onChangeBrand.bind(this);
        this.onChangeType = this.onChangeType.bind(this);
        this.onChangeSubType = this.onChangeSubType.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeProvince = this.onChangeProvince.bind(this);
        this.onChangeMunicipality = this.onChangeMunicipality.bind(this);
        this.onChangeLandMark = this.onChangeLandMark.bind(this);
        this.onChangeOwner = this.onChangeOwner.bind(this);
        this.onChangeRemarks = this.onChangeRemarks.bind(this);
        this.onChangeLandline = this.onChangeLandline.bind(this);
        this.onChangeLandline2 = this.onChangeLandline2.bind(this);
        this.onChangeLandline3 = this.onChangeLandline3.bind(this);
        this.onChangeMobile = this.onChangeMobile.bind(this);
        this.onChangeMobile2 = this.onChangeMobile2.bind(this);
        this.onChangeMobile3 = this.onChangeMobile3.bind(this);
        this.geometryHandler = this.geometryHandler.bind(this);
        this.onChangePoiEntity = this.onChangePoiEntity.bind(this);
        this.enableEditMode = this.enableEditMode.bind(this);
        this.disableEditMode = this.disableEditMode.bind(this);
        this.deleteAction = this.deleteAction.bind(this);
        this.setGeofence = this.setGeofence.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.onChangeSpTravelTime = this.onChangeSpTravelTime.bind(this);
        this.onChangeSpDistance = this.onChangeSpDistance.bind(this);


        this._currentUserRole = AclService.getRole();
        if (this._currentUserRole === "user") {
            this._permittedBrands = _.map(AclService.getBrandPermissions(), "id");
            this._brandManageAccess = _.map(_.filter(AclService.getBrandPermissions(), (obj) => {return obj.manage}), "id");        
        }



        this.state = {

            //on page states
            buttonDisabled: false,
            showLoader: false,
            error: null,
            success: null,
            dependencyComplete: false,
            typeList: [],
            provinceList: [],
            hasSubType: false,
            subTypeList: [],
            isEditMode: false,

            //dropdown states
            districts: [],
            brands: [],
            types: [],
            provinces: [],
            municipalities: [],
            owners: [],
            refillingPlants: [],            

            //field states
            name: "",
            district: "",
            brand: "",
            brandObject: {},
            type: "",
            subType: "",
            address: "",
            province: "",
            municipality: "",
            landMark: "",
            landLine: "",
            landLine2: "",
            landLine3: "",
            mobile: "",
            mobile2: "",
            mobile3: "",
            remarks: "",
            geometry: {},
            poientity: "",            
            createdAt: "",
            updatedAt: "",
            geofence: {},
            geofenceData: {},
            updatedBy: "",
            createdBy: "",
            spDistance: "",
            spTravelTime: ""

            
        };

    }


    setGeofence (gValues) {
        this.setState({
            geofence: gValues
        });
    }



    async deleteAction () {
        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null,
            isEditMode: false
        });

        let {data, error} = await Poi.Delete(this.poiId);
        if (data) {
            this.setState({
                redirect: true,
                success: "Point of Interest Deleted",
                error: null,
                showLoader: false,
                buttonDisabled: false,
            });
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
            window.scrollTo(0, 0);            
        }        
    }        

    onChangeSpDistance (e) {
        this.setState({
            spDistance: e.target.value
        });
    }

    onChangeSpTravelTime (time) {
        this.setState({
            spTravelTime: time
        });
    }


    onChangeName (e) {
        this.setState({
            name: e.target.value
        });
    }

    geometryHandler (coords) {
        let geometry = {
            "type": "Point",
            "coordinates": [coords.lat(), coords.lng()]
        };
        this.setState({
            geometry: geometry      
        });
    }

    onChangeDistrict (e) {
        this.setState({
            district: e.target.value
        });
    }

    onChangeBrand (e) {
        this.setState({
            brand: e.target.value
        });
    }

    onChangeSubType (e) {
        this.setState({
            subType: e.target.value || undefined
        });
    }

    onChangeAddress (e) {
        this.setState({
            address: e.target.value
        });
    }

    onChangeLandMark (e) {
        this.setState({
            landMark: e.target.value
        });
    }

    onChangeOwner (e) {
        this.setState({
            owner: e.target.value || ""
        });
    }

    onChangeRemarks (e) {
        this.setState({
            remarks: e.target.value
        });
    }

    onChangeMobile (e) {
        this.setState({
            mobile: e.target.value
        });
    }
    onChangeMobile2 (e) {
        this.setState({
            mobile2: e.target.value
        });
    }
    onChangeMobile3 (e) {
        this.setState({
            mobile3: e.target.value
        });
    }

    onChangeLandline (e) {
        this.setState({
            landLine: e.target.value
        });
    }

    onChangeLandline2 (e) {
        this.setState({
            landLine2: e.target.value
        });
    }

    onChangeLandline3 (e) {
        this.setState({
            landLine3: e.target.value
        });
    }

    enableEditMode () {
        this.setState({
            isEditMode: true
        });
    }

    disableEditMode () {
        this.setState({
            isEditMode: false
        });
    }

    onChangeProvince (e) {
        
        let provinceId = e.target.value;
        let provinceList = this.state.provinceList;
        let selectedProvince = _.find(provinceList, {id: provinceId}) || {};
        let municipalities = [];
        if (_.size(selectedProvince.municipalities) > 0) {
            _.each(selectedProvince.municipalities, (item) => {
                municipalities.push({
                    key: item.id,
                    value: item.id,
                    label: item.name
                });
            } );
        }
        this.setState({
            province: e.target.value,
            municipalities: municipalities
        });
    }

    onChangeMunicipality (e) {
        this.setState({
            municipality: e.target.value
        });
    }

    onChangeType (e) {
        let typeId = e.target.value;
        let typeList = this.state.typeList;
        let selectedType = _.find(typeList, {id: typeId}) || {};
        let hasSubType = false;
        let subTypeList = [];
        if (_.size(selectedType.subTypeList) > 0) {
            hasSubType = true;
            _.each(selectedType.subTypeList, (item) => {
                subTypeList.push({
                    key: item,
                    value: item,
                    label: item
                });
            } );            
        }
        this.setState({
            type: e.target.value || undefined,
            hasSubType: hasSubType,
            subTypeList: subTypeList
        });
    }

    onChangePoiEntity (e) {
        this.setState({
            poientity: e.target.value
        });
    }    


    async onSubmit (e) {
        e.preventDefault();
        let {geometry, geofence} = this.state;
        if (_.isEmpty(geometry)) {
            this.setState({
                error: "Please pin a location on the map."
            });
            window.scrollTo(0, 0);                                
            return false;
        } 
        
        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null
        });

        let params = {
            name: this.state.name,
            address: this.state.address,
            district: this.state.district,
            brand: this.state.brand,
            municipality: this.state.municipality,
            province: this.state.province,
            type: this.state.type || null,
            subType: this.state.subType || null,
            landMark: this.state.landMark,
            landLine: this.state.landLine,
            landLine2: this.state.landLine2,
            landLine3: this.state.landLine3,
            owner: this.state.owner || "",
            mobile: this.state.mobile,
            mobile2: this.state.mobile2,
            mobile3: this.state.mobile3,
            remarks: this.state.remarks,
            geometry: geometry,
            poientity: this.state.poientity,
            spDistance: this.state.spDistance,
            spTravelTime: this.state.spTravelTime

        };

        let {data, error} = await Poi.Update(this.poiId, params);
        if (data) {
            geofence.poiId = this.poiId;
            await PoiGeofence.Create(geofence);

            this.setState({
                success: "Point of Interest Updated.",
                error: null,
                showLoader: false,
                buttonDisabled: false
            });            
            window.scrollTo(0, 0);    
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
            window.scrollTo(0, 0);                                
        }        

    }


    async getDropdownValues () {
        //district
        // let districtResult = await District.GetDropdownList();
        // if (districtResult.error) { 
        //     this.setState({ dependencyError: districtResult.error});
        // } else {
        //     this.setState({districts: districtResult.data});
        // }

        //brand
        let brandResult = await Brand.GetDropdownList();
        if (brandResult.error) { 
            this.setState({ dependencyError: brandResult.error});
        } else {
            this.setState({brands: brandResult.data});
        }

        //type
        let typeResult = await Type.GetDropdownList();
        if (typeResult.error) { 
            this.setState({ dependencyError: typeResult.error});
        } else {
            this.setState({types: typeResult.data, typeList: typeResult.list});
        }

        //provinces
        let provinceResult = await Province.GetDropdown();
        if (provinceResult.error) { 
            this.setState({ dependencyError: provinceResult.error});
        } else {
            this.setState({provinces: provinceResult.data, provinceList: provinceResult.list});
        }

        //owners
        // let ownerResult = await Owner.GetDropdownList();
        // if (ownerResult.error) { 
        //     this.setState({ dependencyError: ownerResult.error});
        // } else {
        //     this.setState({owners: ownerResult.data});
        // }


        //refilling plants
        let refillingPlantResult = await RefillingPlant.GetDropdownList();
        if (refillingPlantResult.error) { 
            this.setState({dependencyError: refillingPlantResult.error});
        } else {
            this.setState({refillingPlants: refillingPlantResult.data});
        }


        this.setState({
            dependencyComplete: true
        });

    }


    async componentDidMount () {
        let {data} = this.state;
        await this.getDropdownValues();
        if (_.isEmpty(data) || _.isNil(data) ) {
            await this.getData(this.poiId);
        }
    }

    async getData ( id ) {
        let {data, error} = await Poi.Show( id );
        let geofenceResult = await PoiGeofence.Show( id );
        if (error) this.setState({error: error});
        const provinceId = _.get(data, "province.id");
        const typeId = _.get(data, "type.id");
        this.onChangeProvince({target: {value: provinceId}});
        this.onChangeType({target: {value: typeId}});
        if (data && _.size(data) > 0) {
            this.setState({
                data: data,
                name: data.name,
                address: data.address,
                district: _.get(data, "district.id"),
                province: _.get(data, "province.id"),
                municipality: _.get(data, "municipality.id"),
                landMark: data.landMark,
                landLine: data.landLine,
                landLine2: data.landLine2,
                landLine3: data.landLine3,
                mobile: data.mobile,
                mobile2: data.mobile2,
                mobile3: data.mobile3,
                owner: data.owner,
                brand: _.get(data, "brand.id"),
                brandObject: data.brand,
                type: _.get(data, "type.id"),
                poientity: _.get(data, "poientity.id"),
                subType: data.subType,
                remarks: data.remarks,
                geometry: data.geometry,
                updatedAt: data.updatedAt,
                createdAt: data.createdAt,
                geofenceData: geofenceResult.data || {},
                updatedBy: data.updatedBy,
                createdBy: data.createdBy,
                spDistance: data.spDistance,
                spTravelTime: data.spTravelTime,
                dependencyComplete: true,

            });
            
        } else {
            this.setState({error: "No Record"});
        }
    }    

    render () {

        let {dependencyComplete, dependencyError, error, success, redirect} = this.state;
        const btnClass = (this.state.isEditMode) ? "hide " : null;          
        const recordMetaData = {createdAt: this.state.createdAt, updatedAt: this.state.updatedAt, updatedBy: this.state.updatedBy, createdBy: this.state.createdBy};
        let showEdit = true;
        if (this._currentUserRole === "user") {
            if (!_.includes(this._brandManageAccess, this.state.brand)) {
                showEdit = false;
            }
        }
        if (dependencyError) {
            return (
                <Fragment>
                    <BreadCrumbs />
                    <div className="col-md-12">
                        <Errors error={dependencyError} /> 
                        <p><a href="/point-of-interests/register">Click here</a> to try again.</p>
                    </div>
                </Fragment>
            );
        }
        if (redirect) {
            return (<Redirect to = {{
                pathname: '/point-of-interests',
                state: { updateNotif: success} 
            }}/>) 
        }
        let wazeNavUrl = null;
        if (dependencyComplete || error || success) {
            let {geometry} = this.state;
            if (!_.isEmpty(geometry)){
               wazeNavUrl = `https://www.waze.com/ul?ll=${geometry.coordinates[0]},${geometry.coordinates[1]}&navigate=yes`;
            }
            
            
            return (
                <Fragment>
                    <BreadCrumbs />
                    <div className="col-md-12">
                        <Errors error={error} /> 
                    </div>
                    <div className="col-md-12">
                        <Success success={success} /> 
                    </div>
                    <div className="col-md-12">

                        <div className="card">
                            <div className="header">
                                <div className="col-md-6">
                                    <h4 className="title">Viewing Point of Interest &nbsp; &nbsp; 
                                    { wazeNavUrl && <a className="btn btn-info btn-sm btn-fill"  href={wazeNavUrl}>Waze Navigation</a> }
                                    </h4>
                                </div>
                                { 
                                    (showEdit) ?
                                     <div className="col-md-1 float-right poi-edit-btn">
                                        <button  onClick={this.enableEditMode}  type="button" className={`btn btn-xs btn-danger float-right ${btnClass}`}>
                                            <i className="ti-pencil"></i> Edit
                                        </button>
                                    </div>                                
                                    : null
                                }
                                
                            </div>
                            <br />

                            <div className="content">
                                <form onSubmit={this.onSubmit}>
                                    <Form {...this}/>
                                </form>    
                                <RecordMetaData {...recordMetaData} />                    
                            </div>
                        </div>

                    </div>
                    
              </Fragment>
            );
        } else {
            return <div className="loading-screen"><Loader message={"Loading dependencies..."}/></div>
        }
    }
};

export default PointOfInterest;