import React, {useEffect, useState} from "react";
import { withRouter } from "react-router";
import AuthConfig from "../../config/auth";
import qs from "querystring";
import Loader from "../common/loader";

import AuthSDK from "../../libs/identity-server/Auth";
import AuthUtilSDK from "../../libs/identity-server/Util";
import User from "../../models/User";

const AuthCallback = props => {

  const [error, setError] = useState("");
  const AuthSDKObj = new AuthSDK(AuthConfig);
  const AuthUtilSDKObj = new AuthUtilSDK(AuthConfig);
  const TokenExchange = async code => {
    const {search} = props.location;
    const params = qs.parse( search );
    
    let token = null;
    try {
      token = await AuthSDKObj.AuthCodeToAccessToken( params["?code"]  );
    } catch (e) {
      setError(e);
    }
    if (token) {
      localStorage.setItem(AuthConfig.tokenKey, token.access_token);
      localStorage.setItem(AuthConfig.storageKey, token.id_token);

      let decoded = AuthUtilSDKObj.decode( token.id_token );
      let {data, error} = await User.ValidateUserLogin( decoded.userid, decoded.employee_no );
      if (error) {
        localStorage.removeItem(AuthConfig.storageKey);
        localStorage.removeItem(AuthConfig.tokenKey);
        setError({message: "Restricted! Please contact your system administrator!"});
        return;
      }
      localStorage.setItem(AuthConfig.rbacKey, AuthUtilSDKObj.encrypt(data));
      window.location = "/";
    }
  }

  useEffect( () => {

    TokenExchange();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    return (
      <div id="login-container">
        <div className="card">
          <div className="content">
            <br />
              <p className="text-center" style={{color: '#CC0000'}}>{error.message || "Oops! Server responded with an error." }</p>
              <span style={{display:"block"}} className="text-center">Please try again..</span>
                    
                    <a href={ AuthSDKObj.BuildLoginUrl() } type="submit" className="btn btn-fill btn-info btn-block">
                        <span className="ti-unlock"></span> Login
                    </a>

            <br />
          </div>
        </div>
      </div>        
      
    );
  }
  return (

      <div id="login-container">
        <div className="card">
          <div className="content">
            <br />
              <h5 className="text-center">Obtaining login information...</h5>
              <Loader />
            <br />
          </div>
        </div>
      </div>    
        
    );
}


export default withRouter(AuthCallback);