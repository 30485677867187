/**
MAIN ENTRY POINT FOR DYNAMIC COMPONENTS & ROUTING
**/

import React, { Component } from "react";

/*Load Routes*/
import Routes from "../Routes";

class Main extends Component {
    render () {
        return ( 
            <Routes />
        );
    }
}

export default Main;