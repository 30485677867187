import React, { Component } from "react";
import View from "./views/login";
import LoginService from "../models/Login";
import AuthConfig from "../config/auth";
import _ from "lodash";

class Login extends Component {

    constructor (props) {

        super(props);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            username: "",
            password: "",
            isAuthenticated: false,
            data: {},
            error: null,
            buttonDisabled: false,
            showLoader: false
        }
    }

    onChangeUsername (e) {
        this.setState({
            username: e.target.value
        });
    }

    onChangePassword (e) {
        this.setState({
            password: e.target.value
        });
    }

    componentDidMount() {
        if (_.includes(window.location.search, "session")) {
            this.setState({
                error: "Session Expired."
            });
        }
    }    


    async onSubmit (e) {
        e.preventDefault();
        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null
        });


        const {username, password} = this.state;
        const {data, error} = await LoginService.Authenticate(username, password);
        if (data) {
            localStorage.setItem(AuthConfig.storageKey, data.id_token);
            localStorage.setItem(AuthConfig.tokenKey, data.access_token);
            localStorage.setItem(AuthConfig.adminKey, data.admin_token);
            this.setState({
                isAuthenticated: true
            });
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
        }

    }
  
    render () {
        // const {match} = this;
        return (this.state.isAuthenticated) ? window.location.href="/dashboard" :  <View {...this}/>;
    }

};

export default Login;