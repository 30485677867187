import React, { Fragment } from "react";

//common
import Input from "../../../common/form/input";

//config
const Form = props => {
    const {isEditMode} = props.state;
    
    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <Input 
                        label={"Name"} 
                        onChange={props.onChangeName} 
                        value={props.state.name} 
                        name={"name"} 
                        disabled={!isEditMode}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Sub Selection</label>
                        <input type="text" disabled={!isEditMode} placeholder="press enter to add" onKeyPress={props.addSubSelection} className="form-control border-input" />
                    </div>
                    <SubTypeBadge list={props.state.subTypeList || []} remove={props.removeSubSelection} isEditMode={!isEditMode}/>
                </div>

            </div>

            <div className="row">
                
            </div>            

        </Fragment>

    );
}


const SubTypeBadge = ({list, remove, isEditMode = false}) => {
    return list.map( (item, i) => {
        return (
            <span className="badge badge-primary" key={i}>{item}
                { 
                    (!isEditMode) ?
                        <button type="button" className="close" aria-label="Close" value={i} onClick={remove}>
                          <span aria-hidden="true">&times;</span>
                        </button>
                    : null
                }
            </span>            
        )

    });    
};

export default Form;