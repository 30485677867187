import axios from "axios";
import _ from "lodash";
import AuthConfig from "../config/auth";
import SimpleEncryptor from "simple-encryptor";

//load config
import Services from '../config/services';

import Login from "../models/Login";

class Core {

  constructor () {

    let userInfo = this.extractUserInfo();

    let instance = axios.create({
      baseURL: Services.core.url,
      headers: {
        'X-Requested-By': 'Twisted Logix',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      validateStatus: function (status) {
        return status === 200 || status === 401; // default
      },
    });

    instance.interceptors.request.use( (config) => {
        config.headers.authorization = `bearer ${localStorage.getItem(AuthConfig.tokenKey)}`;
        return config;
    }, (error) => {
        return error;
    });

    instance.interceptors.response.use( async (response) => {
        let _error = _.get(response, "data.error", {});
        if (_error.code === 401) {
            let response = await Login.Token(userInfo.id);
            //update localstorage
            localStorage.setItem(AuthConfig.tokenKey, response.data.access_token);
            localStorage.setItem(AuthConfig.storageKey, response.data.id_token);
            localStorage.setItem(AuthConfig.adminKey, response.data.admin_token);
            return response;
        } else {
            return response;
        }
        }, (error) => {
        return Promise.reject(error)
    });  

    return instance;

  }


  extractUserInfo () {
    const Cipher = SimpleEncryptor(AuthConfig.decryptKey);
    return Cipher.decrypt( localStorage.getItem(AuthConfig.storageKey) );
  }


}

export default new Core();