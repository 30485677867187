const _  = require("lodash");

module.exports = {

  readableDateFormat: (date) => {
    let converted = new Date(date);
    return  converted.toLocaleString("en-US", {
      month: 'numeric', day: 'numeric', year: 'numeric',
      hour: 'numeric', minute: 'numeric', hour12: true
    });

  },

  convertCoordinates: (flatCoords) => {
    let coords = [];
    _.each(flatCoords, (position) => {
        let ltLng = _.split(position, ",");
        coords.push({
            lat: _.toNumber(ltLng[0]),
            lng: _.toNumber(ltLng[1])
        });
    });
    return coords;
  }


};
