import React, {useEffect, useState} from "react";

import Input from "../../../common/form/input";
import Loader from "../../../common/loader";
import Brand from "../../../../models/Brand";
import {includes, pull, uniq} from "lodash";
import {withRouter} from "react-router-dom";
import Tile from "../../../../models/Tile";

import Errors from "../../../common/notif/errors";
import Success from "../../../common/notif/success";

// import ButtonLoader from "../../layout/button-loader";

const Form = props => {

  const tileId = props.match.params.id;
  const [brandsSelection, setBrandsSelection] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [name, setName] = useState("");
  const [brands, setBrands] = useState([]);
  const [loadComplete, setLoadComplete] = useState(false);
  // const [tileInfo, setTileInfo] = useState({}); 

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect( () => {

    const GetBrandList = async () => {
      let {data} = await Brand.GetDropdownList();
      if (data) {
        setBrandsSelection(data);
        
      }
    };
    const GetTileDetails = async () => {
      let {data} = await Tile.Show( tileId );
      if (data) {
        // setTileInfo(data);
        setName(data.name);
        setLoadComplete(true);
        setBrands(data.brands);
      }
    }
    GetBrandList(); 
    GetTileDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 


  const onSubmit = async e => {
    setDisabled(true);
    e.preventDefault();
    const params = {
      name, brands
    };
    let {data, error} = await Tile.Update(tileId, params);
    if (data) {
      setSuccess("Tile Updated!");
    }
    if (error) {
      setError("Oops!, an error occured. Please try again later.");
    }
    setDisabled(false);
  };

  if (!loadComplete) {
    return <Loader />
  }

  return (

    <form onSubmit={onSubmit}>
        <Success success={success} /> 
        <Errors error={error} /> 
    
            <div className="row">
                <div className="col-md-12">
                    <Input 
                        label={"Name"} 
                        name={"name"} 
                        onChange={(e) => {setName(e.target.value)}}
                        value={name}
                    />                
                </div>

                <div className="col-md-12">
                  <label>Category Selection</label>

                  <div className="check-wrapper brand collapse in">
                    <ul>
                          {
                              brandsSelection.map( ( d ) => <CheckItem {...d} checkHandler={setBrands} checkedItems={brands}/>)
                          }
                    </ul>
                  </div>

                </div>

            </div>

            <input type="submit" className="btn btn-info btn-fill" value="Submit"  disabled={disabled}   />
            <br />
            <br />


        </form>     
  );
};

const CheckItem = props => {

  const {value, label, checkHandler, checkedItems} = props;


  const checkItem = (e) => {
    const {checked, value} = e.target;
    let _checkedItems = checkedItems;
    if (checked) {
        _checkedItems.push(value);
    } else {
        _checkedItems = pull(_checkedItems, value);
    }
    checkHandler( uniq(_checkedItems) );    
  }

    return (
      <li key={value} className="form-check">
          <label className="form-check-label">
            <input className="form-check-input" type="checkbox"  onChange={(e) => {checkItem(e)}} checked={( includes(checkedItems, value) )}   value={value}/> {label}
          </label>
      </li>      
    ); 
}

export default withRouter(Form);