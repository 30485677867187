import CoreService from "../services/Core";
import Util from "../services/Util";
import _ from "lodash";

class Brand {

    constructor () {
        this.path = "/brands";
        
        this.table = {
            headers: ["Code", "Name", "Date Created", "Last Updated"],
            listKeys: ["code", "name", "createdAt", "updatedAt", "id"],
            linkPath: "/admin/brands",
            linkField: "code"
        }
    }

    async UploadIcon (id, file) {
        let result = { data: null, error: null };
        let headers = {
            'Content-Type': 'multipart/form-data', 
            'Accept': 'application/json', 
            'Access-Control-Allow-Origin': '*'
        };
        try {
            let response = await CoreService.post(`${this.path}/${id}/icon`, file, { headers: headers});
            _.set(result, "data", response.data.data );
        } catch (e) {
            let _error = "Cannot Communicate to the API Server.";
            if (e.response) {
                _error = _.get(e, "response.data.meta.message");
            }
            _.set(result, "error",  _error);
        }
        return result;
    }

    async GetDropdownList () {
        let {data, error} = await this.Get({raw: true}); 

        // dito ka nag stop mak, display ng brands with configured filterEnabled
        let _data = [];
        _.each(data, (item) => {

            if (item.filterEnabled) {
                    _data.push({
                    key: item.id,
                    value: item.id,
                    label: `${item.code}`
                });
            }                

        } );
        return {data: _data, error};
    }

    async Show (id) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.get(`${this.path}/${id}`);
            _.set(result, "data", response.data.data );
        } catch (e) {
            _.set(result, "error",  ((e.response)) ? _.get(e, "response.data.error.message") : "Cannot Communicate to the API Server." );
        }
        return result;
    }


    async Get (params = {}) {
        const {raw = false} = params;
        let result = { data: null, error: null };
        try {
            let response = await CoreService.get(this.path);
            (raw) ? _.set(result, "data", response.data.data ) : _.set(result, "data", this.reduceData( response.data ) );
            // _.set(result, "data", this.reduceData( response.data ) );
        } catch (e) {
            _.set(result, "error",  ((e.response)) ? _.get(e, "response.data.error.message") : "Cannot Communicate to the API Server." );
        }
        return result;
    }

    async GetToggleList () {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.get(`${this.path}/toggle`);
            _.set(result, "data", response.data.data );
        } catch (e) {
            _.set(result, "error",  ((e.response)) ? _.get(e, "response.data.error.message") : "Cannot Communicate to the API Server." );
        }
        return result;
    }

    async Create (params) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.post(this.path, params);
            _.set(result, "data", response.data.data );
        } catch (e) {
            console.log(e);
            let _error = "Cannot Communicate to the API Server.";
            if (e.response) {
                _error = _.get(e, "response.data.meta.message");
            }
            _.set(result, "error",  _error);
        }
        return result;
    }

    async Update (id, params) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.patch(`${this.path}/${id}`, params);
            _.set(result, "data", response.data.data );
        } catch (e) {
            let _error = "Cannot Communicate to the API Server.";
            if (e.response) {
                _error = _.get(e, "response.data.meta.message");
            }
            _.set(result, "error",  _error);
        }
        return result;
    }


    async Delete (id) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.delete(`${this.path}/${id}`);
            _.set(result, "data", response.data.data );
        } catch (e) {
            let _error = "Cannot Communicate to the API Server.";
            if (e.response) {
                _error = _.get(e, "response.data.meta.message");
            }
            _.set(result, "error",  _error);
        }
        return result;
    }
    


   //------------------------------------------------------------------------ 
    /**helpers*/
    reduceData (result) {
        let newData = _.transform(result.data, (r, n, k) => { 
            
            _.set(n, "createdAt", Util.readableDateFormat(n.createdAt) );
            _.set(n, "updatedAt", Util.readableDateFormat(n.updatedAt) );
            r.push( _.pick(n, _.get(this, "table.listKeys")) );
        }, []);
        return newData;
    }

}

export default new Brand();