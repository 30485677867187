import {isEmpty} from "lodash";
import Util from "../../../../services/Util";

class Geofence {

  constructor (map, maps) {
    this._map = map;
    this._maps = maps;
  }

  Draw (geoData, infoWindow) {

    let polygonList = this._map.POLYGON_LIST || [];

    if (isEmpty(geoData)) {
      return false;
    }

    let coords = Util.convertCoordinates(geoData.polygonCoords);
    let polygon = new this._maps.Polygon({
        path: coords,
        strokeColor: geoData.strokeColor,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: geoData.fillColor,
        fillOpacity: 0.1,
        editable: false,
        draggable: false
    });
    polygon.setMap(this._map); 

    this._maps.event.addListener(polygon,"mouseover", function (e) {
      infoWindow.setPosition( e.latLng);
      infoWindow.open(this._map, polygon);
    }); 

    this._maps.event.addListener(polygon,"mouseout", function () {
      infoWindow.close(this._map, polygon);
    }); 

    polygonList.push(polygon);

    this._map.POLYGON_LIST = polygonList;
  }

}
export default Geofence;