import _ from "lodash";
import {Parser} from "json2csv";
import Util from "../../../../services/Util";

function ExportToCsv (data) {
    const fields = ['ID', 'NAME', 'ADDRESS', 'BRAND', 'DISTRICT', 'LANDLINE', 'LANDLINE2', 'LANDLINE3', 'LANDMARK', 'MOBILE', 'MOBILE2', 'MOBILE3', 'PROVINCE', 'MUNICIPALITY', 'OWNER', 'TYPE', 'LONGITUDE', 'LATITUDE', 'SUB_TYPE', 'DATE_CREATED', 'LAST_UPDATED'];

    let _data = [];

    _.each( data,  (poi, index) => {
        _data.push({
            'ID' : poi.id,
            'NAME' : poi.name,
            'ADDRESS' : poi.address,
            'BRAND / CATEGORY' : _.get(poi, "brand.code"),
            'DISTRICT' : _.get(poi, "district.code"),
            'LANDLINE' : poi.landLine,
            'LANDLINE2' : poi.landLine2,
            'LANDLINE3' : poi.landLine3,
            'LANDMARK' : poi.landMark,
            'MOBILE' : poi.mobile,
            'MOBILE2' : poi.mobile2,
            'MOBILE3' : poi.mobile3,
            'PROVINCE' : _.get(poi, "province.code"),
            'MUNICIPALITY' : _.get(poi, "municipality.code"),
            'OWNER' : poi.owner,
            'TYPE' : _.get(poi, "type.name"),
            'LONGITUDE' : _.get(poi, "geometry.coordinates[0]"),
            'LATITUDE' : _.get(poi, "geometry.coordinates[1]"),
            'SUB_TYPE' : poi.subType,
            'DATE_CREATED' : Util.readableDateFormat(poi.createdAt),
            'LAST_UPDATED' : Util.readableDateFormat(poi.updatedAt)
        });
    });

    const Json2csvParser = new Parser({fields});
    const csv = Json2csvParser.parse(_data);    

    const file = new Blob([csv],  { type: 'text/csv;charset=utf-8;' }); 
    const fileURL = URL.createObjectURL(file);

    let link = document.createElement("a");
    link.setAttribute("href", fileURL);
    link.style.visibility = 'hidden';    
    link.setAttribute("download", "business-mapper-export.csv");    
    document.body.appendChild(link);    
    link.click();
    document.body.removeChild(link);    
    // window.open(fileURL);
    
}

export default ExportToCsv;