import React, { Component } from "react";
//common
import BreadCrumbs from "../../layout/breadcrumbs";
// import Loader from "../../common/loader";
// import Errors from "../../common/notif/errors";
// import Success from "../../common/notif/success";

//model
// import Tile from "../../../models/Tile";

//view
import Form from  "./show/form";

class Show extends Component {

  
    render () {


        return (
            <>
                <BreadCrumbs />

                <div className="col-md-9">
                     <div className="card">
                         <div className="content">
                             <div className="row">
                                 <div className="col-md-12">
                                    <Form />
                                 </div>
                             </div>
                         </div>
                     </div>                    
                </div>

            </>
        );

         
        // let {data, error, success} = this.state;        
        // let {redirect = null} = this.state;
        // if (redirect) {
        //     return (<Redirect to = {{
        //         pathname: '/admin/districts',
        //         state: { deleteSuccess: this.state.success} 
        //     }}/>) 
        // }        
        // if (_.size(data) > 0 || error) {
        //     return (
        //         <React.Fragment>
        //             <BreadCrumbs />

        //                 <div className="col-md-12">
        //                     <Success success={success} />
        //                     <Errors error={error} /> 
        //                 </div>

        //                 <ShowView {...this} />


        //         </React.Fragment>
        //     );
        // } else {
        //     return <div className="loading-screen"><Loader /></div>
        // }    
    }

}


export default Show;