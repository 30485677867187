import React, { Component } from "react";
import _ from "lodash";

import EditFormContainer from "../../../layout/edit-form";
import Form from "./form";

class Show extends Component {

    constructor (props) {
        super(props);

        this.enableEditMode = this.enableEditMode.bind(this);
        this.disableEditMode = this.disableEditMode.bind(this);

        this.onChangeName = props.onChangeName;
        this.onChangePinColor = props.onChangePinColor;
        this.addSubSelection = props.addSubSelection;
        this.removeSubSelection = props.removeSubSelection;
        this.showColorPicker = props.showColorPicker;
        this.closeColorPicker = props.colorPickerChange;
        this.colorPickerChange = props.colorPickerChange;
        this.deleteAction = props.deleteAction;
        
        this.onSubmit = props.onSubmit;

        this.state = {

            //form control
            isEditMode: false,
            displayColorPicker: false,
            data: props.state.data,
            name: props.state.name,
            pinColor: props.state.pinColor,
            hasSubType: props.state.hasSubType,
            subType: props.state.subType,
            subTypeList: props.state.subTypeList,
            createdAt: props.state.createdAt,
            updatedAt: props.state.updatedAt,
            isSuccessful: props.isSuccessful
        }

    }

    onChangeName (e) {
        this.setState({
            name: e.target.value
        });
    }

    colorPickerChange (color) {
        this.setState({
            pinColor: color.hex
        });
    }

    closeColorPicker (color) {
         this.setState({ displayColorPicker: false })
    }

    showColorPicker () {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    }

    onChangePinColor (e) {
        this.setState({
            pinColor: e.target.value
        });
    } 

    addSubSelection (e) {
        if (e.key === "Enter") {
            let tmpSubtype = _.clone(this.state.subTypeList);
            tmpSubtype.push(e.target.value);
            e.target.value = "";
            e.preventDefault();
            this.setState({
                subType: "",
                subTypeList: tmpSubtype
            });
        }
    }

    removeSubSelection (e) {
        let tmpSubtype = _.clone(this.state.subTypeList);
        _.pullAt(tmpSubtype, e.currentTarget.value) 
        this.setState({
            subTypeList: tmpSubtype
        });
       
    }


    enableEditMode (e) {
        this.setState({
            isEditMode: true
        });
    }  

    disableEditMode (e) {
        this.setState({
            isEditMode: false,
            name: this.state.data.name,
            type: this.state.data.type,
            email: this.state.data.email,
            contactNumber: this.state.data.contactNumber,
            address: this.state.data.address            
        });
    }  


    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            data: nextProps.state.data,
            name: nextProps.state.name,
            pinColor: nextProps.state.pinColor,
            hasSubType: nextProps.state.hasSubType,
            subTypeList: nextProps.state.subTypeList,
            buttonDisabled: nextProps.state.buttonDisabled,
            showLoader: nextProps.state.showLoader,
            createdAt: nextProps.state.createdAt,
            updatedAt: nextProps.state.updatedAt,
            isSuccessful: nextProps.isSuccessful,
            displayColorPicker: nextProps.state.displayColorPicker
        });
    }

    render () {
        const { isEditMode } = this.state;
        const recordMetaData = {
            createdAt: this.state.createdAt,
            updatedAt: this.state.updatedAt
        };
        return (

            <EditFormContainer 
                listLink={"/admin/types"}
                isEditMode={isEditMode}
                enableEditMode={this.enableEditMode}
                form={<Form  {...this} disableEditMode={this.disableEditMode} />}
                recordMetaData={recordMetaData}
                button={{disableEditMode: this.disableEditMode, buttonDisabled: this.state.buttonDisabled, showLoader:this.state.showLoader, deleteAction: this.deleteAction}}
                onSubmit={this.onSubmit}
            />

        );
    }

};

export default Show;