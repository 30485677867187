import React from "react";
import GoogleMapReact from "google-map-react";
import MapConfig from "../../../config/map";

const MapContainer = props => {

    let bootstrapURLKeys = {
        key: MapConfig.apiKey,
        libraries: ['drawing', 'places', 'geometry'].join(',')
    }

    let createMapOptions = function (maps) {
        return {
            styles: [{
              "featureType": "all",
              "elementType": "labels",
              "stylers": [{
                "visibility": "#on"
              }]
            }]     
        }
    }

    return (
        <React.Fragment>
            <div id="map-container" className="map-container" style={{ height: window.innerHeight, width: '100%' }}>
                <GoogleMapReact 
                    bootstrapURLKeys={bootstrapURLKeys} 
                    defaultCenter={MapConfig.center} 
                    defaultZoom={MapConfig.geofenceZoom} 
                    options={createMapOptions}
                    onGoogleApiLoaded={({map, maps}) => props.handler(map, maps)}
                    yesIWantToUseGoogleMapApiInternals />
            </div>
        </React.Fragment>
    );
};

export default MapContainer;    