import React, { Component } from "react";
import _ from "lodash";
class Filter extends Component {

    //Reference:::   https://codepen.io/mtclmn/pen/QyPVJp
    constructor (props) {
        super(props);
        this.filterList = this.filterList.bind(this);
        this.state = {
            items: props.data,
            initialItems: [],
            setFilter: props.setFilter,
            message: ""
        }
    }


    componentWillMount () {
        this.setState({initialItems: this.state.items } )
    }

    filterList (e) {
        let updatedList = this.state.initialItems;
        const filtered =  _.filter(updatedList, (item) => {
            return _.find( _.values(item), (column) => {
                return _.includes( _.toLower(column), _.toLower( e.target.value) );
            });
        });
        this.state.setFilter(filtered);
    }

    render () {
        const { message } = this.state;
        return (
            <div className="col-md-3">
                <div className="input-group">
                    <input type="text" className=" form-control border-input" placeholder="type to search.." onChange={this.filterList} />
                    <span className="input-group-addon border-input"><i className="ti-search"></i></span>
                </div>
                <span>{message}</span>
            </div>         
        );
    }

}

export default Filter;