import React, { Fragment } from "react";
import { Route, NavLink } from "react-router-dom";
import _ from "lodash";


const Breadcrumbs = (props) => (
    <div className="col-md-12">

        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li><i className="ti-home"></i></li>
                <Route path='/:path' component={BreadcrumbsItem} />
            </ol>
        </nav>
    </div>    
)

const BreadcrumbsItem = ({ match }) => {
    const liClass = (match.isExact) ? 'active' : null;
    return (
        <Fragment>
            <li className={liClass}>
                <NavLink to={match.url || ''}>
                    {_.replace( _.trim(match.params.path, "/") , /-/g, ' ') }
                </NavLink>
            </li>
            <Route path={`${match.url}/:path`} component={BreadcrumbsItem} />
        </Fragment>
    )

};

export default Breadcrumbs;