let Exception = require("stderror").extend("Exception");

//-- http specific
Exception.define({code: 400, name: "BadRequest", message: "Bad request"});
Exception.define({code: 401, name: "Unauthorized", message: "Unauthorized"});
Exception.define({code: 403, name: "Forbidden", message: "Forbidden"});
Exception.define({code: 404, name: "NotFound", message: "Not found"});
Exception.define({code: 409, name: "RecordUnique", message: "Record Unique Constraint"});
Exception.define({code: 500, name: "ServerError", message: "Internal Server Error"});

Exception.authErrorFormat = (exceptionObj) => {
  return {
    error: exceptionObj.code,
    error_description: exceptionObj.message,
    error_uri: ""
  };

};

export default Exception;