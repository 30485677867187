import AuthConfig from "../config/auth";
import _ from "lodash";
// import NavConfig from "../config/navigation";
import AuthSDK from "../libs/identity-server/Auth";

class AclService {

  constructor () {
    this.roles = ["user", "admin"];
  }

  getRole () {
    let user = this.extractUserInfo();
    return user.role;
  }

  getBrandPermissions () {
    let user = this.extractUserInfo();
    return user.permissions.brands;
  }

  getModulePermission () {
    let user = this.extractUserInfo();
    return user.permissions.modules;
  }

  getTilePermission () {
    let user = this.extractUserInfo();
    return user.permissions.tiles || [];
  }

  checkModulePermission (module, manage = null) {
    const modulePermissions = this.getModulePermission();    
    let _module = _.find(modulePermissions, {id: module});
    if (manage === true) {
      return !!(_module.manage === manage)
    } else {
      return !!_module;
    }

  }

  checkRolePermission (allowedRoles) {
    return (allowedRoles === "*") ? true : _.includes(allowedRoles, this.getRole());
  }

  checkPermissionAccess (allowedRoles) {
    return (allowedRoles === "*") ? true : _.includes(allowedRoles, this.getRole());
  }

  extractUserInfo () {
    const AuthSDKObj = new AuthSDK(AuthConfig);
    let rbac =  AuthSDKObj.UserInfo( localStorage.getItem(AuthConfig.rbacKey ) );
    if (!rbac) {
      window.location = "/logout";
    }
    return rbac;
  }

  userModuleSelection () {
    return [
      {
        id: "geofence",
        name: "GeoFence"
      },
      {
        id: "refilling-plants",
        name:"Refilling Plants"
      },
      {
        id: "map-overview",
        name:"Map Overview"
      },
    ];
  }


}

export default new AclService();