import React from "react";
import AuditService from "../services/Audit";
import Util from "../services/Util";
import _ from "lodash";
import querystring from "querystring";

class AuditLog {

    constructor () {
        this.path = "/audit";
        this.table = {
            headers: ["Module", "Action", "User ID", "Date & Time", "Record ID", "User Full Name" ],
            listKeys: ["entity", "action", "actorId", "timestamp", "recordId", "userFullName",]
        }      
        this.moduleMap = {
            users: "/admin/users",
            types: "/admin/types",
            brands: "/admin/brands",
            provinces: "/admin/provinces",
            municipalities: "/admin/provinces/{province_id}/municipalities",
            point_of_interests: "/point-of-interests",
            poi_entities: "/refilling-plants",

        };
    }

    async Get (params) {
        let result = { data: null, error: null, meta: null };
        try {
            let response = await AuditService.get(`${this.path}?${querystring.stringify(params)}` );
            _.set(result, "data",this.reduceData( response.data ));
            _.set(result, "meta", response.data.meta || null);
        } catch (e) {
            _.set(result, "error",  ((e.response)) ? _.get(e, "response.data.error.message") : "Cannot Communicate to the API Server." );
        }

        return result;        
    }

    async GetRecent (params) {
        let result = { data: null, error: null };
        try {
            let response = await AuditService.get(`${this.path}?${querystring.stringify(params)}` );
            _.set(result, "data", response.data.data  );
        } catch (e) {
            _.set(result, "error",  ((e.response)) ? _.get(e, "response.data.error.message") : "Cannot Communicate to the API Server." );
        }
        return result;
    }


    /*helper functions*/
    reduceData (result) {
        let newData = _.transform(result.data, (r, n, k) => { 
            let tableName = n.table;
            let href = `${this.moduleMap[n.table]}/${n.recordId}`;
            if (tableName === "municipalities") {
                if (n.data) {
                    href = `/admin/provinces/${n.data.province}/municipalities/${n.recordId}`;
                }
            }
            if (n.entity === "Brand") {
                _.set(n, "entity", "Category");
            }
            _.set(n, "timestamp", Util.readableDateFormat(n.createdAt) );
            _.set(n, "recordId", <a target="_blank" rel="noopener noreferrer" href={href}>{n.recordId}</a> );
            r.push( _.pick(n, _.get(this, "table.listKeys")) );

        }, []);
        return newData;
    }    

}

export default new AuditLog();