import React from "react";
import {NavLink} from "react-router-dom";

const Wrapper = props => {
    const {listLink} = props;
    return (
        <div className="col-md-8">
             <div className="card">
                 <div className="header">
                     <div className="col-md-1 col-back">
                         <NavLink to={listLink} className="btn btn-xs btn-danger btn-simple" exact> &laquo; back to list </NavLink>
                     </div>
                 </div>
                 <div className="content">
                     <hr />
                     {props.children}
                 </div>
             </div>                     
         </div>     
  );
};

export default Wrapper;