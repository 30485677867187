import CoreService from "../services/Core";
import Util from "../services/Util";
import _ from "lodash";
import qs from "querystring";

class Brand {

    constructor () {
        this.path = "/geofences";
    }

    async Show (id) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.get(`${this.path}/${id}`);
            _.set(result, "data", response.data.data );
        } catch (e) {
            _.set(result, "error",  ((e.response)) ? _.get(e, "response.data.error.message") : "Cannot Communicate to the API Server." );
        }
        return result;
    }


    async Get (params) {
        let result = { data: null, error: null };
        let queryParams = qs.stringify(params);
        try {
            let response = await CoreService.get(`${this.path}?${queryParams}`);
            _.set(result, "data",  response.data.data );
        } catch (e) {
            _.set(result, "error",  ((e.response)) ? _.get(e, "response.data.error.message") : "Cannot Communicate to the API Server." );
        }
        return result;
    }

    async Create (params) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.post(this.path, params);
            _.set(result, "data", response.data );
        } catch (e) {
            let _error = "Cannot Communicate to the API Server.";
            if (e.response) {
                _error = _.get(e, "response.data.meta.message");
            }
            _.set(result, "error",  _error);
        }
        return result;
    }

    async Update (id, params) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.patch(`${this.path}/${id}`, params);
            _.set(result, "data", response.data.data );
        } catch (e) {
            let _error = "Cannot Communicate to the API Server.";
            if (e.response) {
                _error = _.get(e, "response.data.meta.message");
            }
            _.set(result, "error",  _error);
        }
        return result;
    }


    async Delete (id) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.delete(`${this.path}/${id}`);
            _.set(result, "data", response.data.data );
        } catch (e) {
            let _error = "Cannot Communicate to the API Server.";
            if (e.response) {
                _error = _.get(e, "response.data.meta.message");
            }
            _.set(result, "error",  _error);
        }
        return result;
    }
        


   //------------------------------------------------------------------------ 
    /**helpers*/
    reduceData (result) {
        let newData = _.transform(result.data, (r, n, k) => { 
            
            _.set(n, "createdAt", Util.readableDateFormat(n.createdAt) );
            _.set(n, "updatedAt", Util.readableDateFormat(n.updatedAt) );
            r.push( _.pick(n, _.get(this, "table.listKeys")) );
        }, []);
        return newData;
    }

}

export default new Brand();