import React, {Fragment} from "react";
import {NavLink} from "react-router-dom";
import Util from "../../services/Util";


const RecordMetaData = ({createdAt, updatedAt, updatedBy, createdBy}) => {
    return (
        <Fragment>
            <NavLink to="#meta-container" data-toggle="collapse" data-target="#meta-container" className="btn btn-xs btn-danger btn-simple" exact> Meta Data <b className="caret"></b> </NavLink>
            <div className="row collapse" id="meta-container">
                <div className="col-md-5 record-meta">
                    { createdBy && <h6>Created By: <small>{ createdBy }</small></h6>}
                    <h6>Date Created: <small>{ Util.readableDateFormat(createdAt) }</small></h6>
                    <h6>Last Updated: <small>{ Util.readableDateFormat(updatedAt) }</small></h6>
                    { updatedBy && <h6>Last Updated By: <small>{ updatedBy }</small></h6>}
{/*                    <h6>Updated By: <small>Juan Dela Cruz</small></h6>
                    <h6>Created By: <small>Makmak Garcia</small></h6>
*/}                    
                </div>
            </div>        
        </Fragment>

    );
}

export default RecordMetaData;
