import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";

const Loader = ({message}) => {
    return (
        <div className="row">
            <div className="col-md-12 text-center">
                <ScaleLoader
                    color={'#7CE4FE'}
                    height={25}
                    width={7}
                    radius={5}
                    loading={true}
                />            
                {message}
            </div>
        </div>
    );
}
export default Loader;