import React, { Component } from "react";
import {NavLink} from "react-router-dom";
import AuthConfig from "../../config/auth";
import AuthSDKUtil from "../../libs/identity-server/Util";

class TopNav extends Component {


  constructor (props) {
    super(props);
    this.state = {
      user: this.extractUserInfo()
    }

  }


  extractUserInfo () {
    const AuthSDKUtilObj = new AuthSDKUtil( AuthConfig );
    return AuthSDKUtilObj.decode( localStorage.getItem(AuthConfig.storageKey) );
  }

  responsiveToggle () {
    return (
      <div className="navbar-header">
        <button type="button" className="navbar-toggle">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar bar1"></span>
            <span className="icon-bar bar2"></span>
            <span className="icon-bar bar3"></span>
        </button>
        <p className="navbar-brand"></p>
     </div>      
    );
  }

  profileBar () {
    const user = this.state.user;
    return (
      <div className="collapse navbar-collapse">
        <ul className="nav navbar-nav navbar-right">
          <li className="dropdown">
            <a href="/#" className="dropdown-toggle" data-toggle="dropdown">
              <i className="ti-user"></i>
              <p> &nbsp; {user.firstname} </p>
              <b className="caret"></b>
            </a>
            <ul className="dropdown-menu">
              {/*<li><a href="/#"><i className="ti-pencil"></i> &nbsp; Edit Profile</a></li>*/}
              <li>
                <NavLink to="/logout"><i className="ti-power-off"></i> &nbsp; Logout</NavLink>
              </li>
            </ul>
          </li>
        </ul>
      </div>      
    )
  }


  
  render () {

    return (
      <nav className="navbar navbar-default" id="top-bar">
        <div className="container-fluid">
          <React.Fragment>
          {this.responsiveToggle()}
          {this.profileBar()}
          </React.Fragment>        
        </div>
      </nav>
    );

  }

}

export default TopNav;