import React, {useEffect, useState} from "react";
import MapConfig from "../../../../config/map";
import { TwitterPicker } from 'react-color';
import _ from "lodash";
import Util from "../../../../services/Util";

let _drawingManager,_currentPolygon = null;
const BoundarySettings = props => {

  const {map, maps, geofenceHandler, data, isEditMode} = props;

  const [buildMode, setBuildMode] = useState(false); 

  // console.log("BoundarySettings->props:", props );
  // console.log("BoundarySettings->data:", data );

  //polygon
  const [hasPolygon, setHasPolygon] = useState(false); 
  const [polygonCoords, setPolygonCoords] = useState( [] ); 
  const [selectedShape, setSelectedShape] = useState(null); 

  //colors (toggle & values)
  const [fillColor, setFillColor] = useState( MapConfig.polygon.fillColor  ); 
  const [fillColorToggle, setFillColorToggle] = useState( false ); 

  const [strokeColor, setStrokeColor] = useState( MapConfig.polygon.strokeColor ); 
  const [strokeColorToggle, setStrokeColorToggle] = useState( false ); 

  useEffect(() => {
    if (data && data.id) {
      setFillColor( data.fillColor );
      setStrokeColor( data.strokeColor );
      setPolygonCoords( data.polygonCoords );
    }
  }, [data]);

  useEffect(() => {

    geofenceHandler({
      polygonCoords: polygonCoords,
      fillColor: fillColor,
      strokeColor: strokeColor
    });

  }, [fillColor, strokeColor, polygonCoords, buildMode, data, geofenceHandler]);


  if (maps.drawing && !_.isEmpty(polygonCoords) ) { //draw initial polygon
    if (!_currentPolygon) {
      let coords = Util.convertCoordinates(polygonCoords);
      let polygon = new maps.Polygon({
          path: coords,
          strokeColor: strokeColor,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: fillColor,
          fillOpacity: 0.3,
          editable: false,
          draggable: false
      });
      polygon.setMap(map); 

      setSelectedShape(polygon);
      setHasPolygon( true );

      _currentPolygon = polygon;

      // polygon update point events
      _currentPolygon.getPaths().forEach(function(path, index){

          maps.event.addListener(path, 'insert_at', function(e) {
            let len = _currentPolygon.getPath().getLength();
            let coords = [];
            for (let i = 0; i < len; i++) {
                coords.push(_currentPolygon.getPath().getAt(i).toUrlValue(5));
                setHasPolygon(true);
                setPolygonCoords(coords);
            }     

          });

          maps.event.addListener(path, 'remove_at', function(e) {
            let len = _currentPolygon.getPath().getLength();
            let coords = [];
            for (let i = 0; i < len; i++) {
                coords.push(_currentPolygon.getPath().getAt(i).toUrlValue(5));
                setHasPolygon(true);
                setPolygonCoords(coords);
            }     

          });

          maps.event.addListener(path, 'set_at', function(e) {
            let len = _currentPolygon.getPath().getLength();
            let coords = [];
            for (let i = 0; i < len; i++) {
                coords.push(_currentPolygon.getPath().getAt(i).toUrlValue(5));
                setHasPolygon(true);
                setPolygonCoords(coords);
            }     

          });

      });      
      // Polygon drag events
      maps.event.addListener(_currentPolygon, 'dragend', function(event){
        let len = _currentPolygon.getPath().getLength();
        let coords = [];
        for (let i = 0; i < len; i++) {
            coords.push(_currentPolygon.getPath().getAt(i).toUrlValue(5));
            setHasPolygon(true);
            setPolygonCoords(coords);
        }     
      });      

    }
  }

  //color pickers
  const colorPickerFillColorChange = color => {
    setFillColor(color.hex);
    updatePolygonProps({fillColor: color.hex});
  }  
  const colorPickerStrokeColorChange = color => {
    setStrokeColor(color.hex);
    updatePolygonProps({strokeColor: color.hex});
  }  

  //polygon settings
  const updatePolygonProps = params => {

    if (_currentPolygon) {
      _currentPolygon.setOptions(params);
    } else {
      let polygonOptions = _drawingManager.polygonOptions;
      _drawingManager.setOptions({polygonOptions: _.merge(polygonOptions, params)});
    }
    // if (!_.isEmpty(selectedShape)) {
    //   selectedShape.setOptions(params);
    // } else {
    // let polygonOptions = _drawingManager.polygonOptions;
      // _drawingManager.setOptions({polygonOptions: _.merge(polygonOptions, params)});
    // }
  }

  //------------ main drawing manager --------//
  let drawingManager = props => {

    let {mode, map, maps, hasPolygon, polygonCoordsHandler, hasPolygonHandler, polygonSelector, shape} = props;

    if (!mode) {
      return false;
    }
    map.mapTypeControl = true;

    if (map) {
      map.POI_MODE = true;
    }

    if (maps.drawing && mode) {

        drawingManager = new maps.drawing.DrawingManager({
            drawingMode: maps.drawing.OverlayType.POLYGON,
            drawingControl: false,
            drawingControlOptions: {
                position: maps.ControlPosition.TOP_CENTER,
                drawingModes: [
                    maps.drawing.OverlayType.POLYGON,
                ]
            },
            polygonOptions: {
                clickable: true,
                draggable: true,
                editable: true,
                fillColor: MapConfig.polygon.fillColor,
                fillOpacity: 0.5,
                strokeWeight: MapConfig.polygon.strokeWeight,
                strokeColor: MapConfig.polygon.strokeColor
            }
        }); 


        drawingManager.setMap(map);

        //select currently drawn polygon
        if (hasPolygon) {
          drawingManager.setDrawingMode(null);
          setSelection( shape );
          // polygonSelector(shape);
        }      



        maps.event.addListener(drawingManager, 'polygoncomplete', function(event) {
            let len = event.getPath().getLength();
            let coords = [];
            for (let i = 0; i < len; i++) {
                coords.push(event.getPath().getAt(i).toUrlValue(5));
                hasPolygonHandler(true);
                polygonCoordsHandler(coords);
            }
        });

        maps.event.addListener(drawingManager, 'overlaycomplete', function(event) {
            let  all_overlays = [];
            all_overlays.push(event);
            if (event.type !== maps.drawing.OverlayType.MARKER) {
                drawingManager.setDrawingMode(null);
                //Write code to select the newly selected object.

                var newShape = event.overlay;
                newShape.type = event.type;
                maps.event.addListener(newShape, 'click', function(event) {
                  return true;
                });
                _currentPolygon = newShape;
                setSelection(newShape);
            }
        });

        function setSelection(_shape) {
            clearSelection();
            polygonSelector(_shape);
            // selectedShape = shape;
            _shape.setEditable(true);
            _shape.setDraggable(true);
        }
        function clearSelection() {
            if (shape) {
                shape.setEditable(false);
                shape = null;
            }
        }

    }

    _drawingManager = drawingManager;
  
  };


  const clearGeofence = shape => {
    const answer = window.confirm("Are you sure you want to delete the current fence and start all over?");
    if (answer) {
        if (shape) {    
          shape.setMap(null);
          _drawingManager.setDrawingMode(maps.drawing.OverlayType.POLYGON);
          setHasPolygon(false);
          geofenceHandler({});
        }    
    }
    return false;
  }

  const exitGeofenceMode = shape => {
    if (shape) {
      shape.setEditable(false);
      shape.setDraggable(false);
      shape = null;
    }

    map.POI_MODE = false;
    _drawingManager.setDrawingMode(null);

    return false;
  }

  if (!maps.drawing) {
    return null;
  }


  if (!isEditMode) {
    return null;
  }


  if (buildMode) {
    return (
      <section className="boundary-settings-wrapper">
        <h6>Geofence Settings</h6>

        <div className="row">

          <div className="col-md-3">
            <div className="form-group">
                <label>Fill Color</label>
                <div className="color-picker" onClick={() => setFillColorToggle(!fillColorToggle)} >
                    <div className="color" style={{backgroundColor: fillColor}}></div>
                    <div className="color-label">{fillColor}</div>
                    { fillColorToggle ? <div className="color-label close-btn">[close picker]</div> : null}
                    
                </div>
                { 
                    fillColorToggle ?  
                        <div className="colorpicker-cover">
                            <TwitterPicker color={fillColor} disableAlpha={ true } onChange={colorPickerFillColorChange}/>
                        </div>
                    : null 
                }                    
            </div>
          </div>        

          <div className="col-md-3">
              <div className="form-group">
                  <label>Border Color</label>
                  <div className="color-picker" onClick={ () => setStrokeColorToggle(!strokeColorToggle) } >
                      <div className="color" style={{backgroundColor: strokeColor}}></div>
                      <div className="color-label">{strokeColor}</div>
                      {strokeColorToggle ? <div className="color-label close-btn">[close picker]</div> : null}
                      
                  </div>
                  { 
                      strokeColorToggle ?  
                          <div className="colorpicker-cover">
                              <TwitterPicker color={strokeColor} disableAlpha={ true } onChange={colorPickerStrokeColorChange}/>
                          </div>
                      : null 
                  }
              </div>
          </div>

          <div className="col-md-6">
              <div className="form-group">
                  <label>&nbsp;</label><br/>
                  <button type="button" className="btn btn-danger btn-sm" onClick={() => { clearGeofence(selectedShape) }}>Clear Geofence</button>
                   &nbsp;&nbsp;
                  <button type="button" className="btn btn-danger btn-sm" onClick={() => {
                    setBuildMode(false);
                    exitGeofenceMode( selectedShape );
                    drawingManager({mode: false});
                  }}>Exit Geofence Mode</button>

              </div>
          </div>

        </div> 

      </section>
    );
  }
  return (
    <section className="boundary-settings-wrapper">
      <button type="button" className="btn btn-danger btn-sm" onClick={() => {
        setBuildMode(true); 
        drawingManager({mode: true, map, maps, hasPolygon: hasPolygon, hasPolygonHandler: setHasPolygon, polygonCoordsHandler: setPolygonCoords, polygonSelector: setSelectedShape, shape: selectedShape})}
      }>Geofence Mode</button>
    </section>
  );



};

export default BoundarySettings;