import React, {Fragment} from "react";

//common
import Input from "../../../common/form/input";
import Select from "../../../common/form/select";



const Form = props => {
    const {isEditMode} = props.state;     


    const DeleteRefillingPlant = () => {
        const ans = window.confirm("Are you sure you want to delete this Refilling Plant");
        if (ans) {
            props.deleteAction();
        }
    }    
    return (
        <Fragment>
            <div className="poi-map-container">
                <div className="row">
                    <div className="col-md-12"><h5><span className="ti-info-alt"></span> Plant Info & Location Details</h5></div>
                </div>

                <div className="row">

                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-12"><Input label={"Name"} onChange={props.onChangeName} value={props.state.name} name={"name"} disabled={!isEditMode} /></div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Remarks</label>
                                    <textarea value={props.state.remarks} onChange={props.onChangeRemarks}className="form-control  border-input" name="name" rows="2" disabled={!isEditMode} ></textarea>
                                </div>
                            </div>
                        </div>

                    </div>


                    {/* OTHER HALF OF THE FORM */}
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-4"><Select label={"District"} name={"district"} value={props.state.district} onChange={props.onChangeDistrict} options={props.state.districts} disabled={!isEditMode} /></div>
                            <div className="col-md-4"><Select label={"Province"} name={"province"} value={props.state.province} onChange={props.onChangeProvince} options={props.state.provinces} disabled={!isEditMode} /></div>
                            <div className="col-md-4"><Select label={"Municipality"} name={"municipality"} value={props.state.municipality} onChange={props.onChangeMunicipality} options={props.state.municipalities} disabled={!isEditMode} /></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Address</label>
                                    <textarea value={props.state.address} onChange={props.onChangeAddress}className="form-control  border-input" name="address" rows="2" disabled={!isEditMode} ></textarea>
                                </div>
                            </div>
                        </div>
                       {/* <div className="row">
                            <div className="col-md-6">
                                <Input 
                                    type="number"
                                    label={"Width (px)"}
                                    onChange={props.onChangeWidth} 
                                    name={"width"} 
                                    disabled={!isEditMode}   
                                    required={false}         
                                    value={props.state.width}
                                />
                            </div>
                            <div className="col-md-6">
                                <Input 
                                    type={"number"}                            
                                    label={"Height (px)"} 
                                    onChange={props.onChangeHeight} 
                                    name={"icon"} 
                                    disabled={!isEditMode}   
                                    required={false}         
                                    value={props.state.height}                                         
                                />
                            </div>
                        </div>  */}                      

                    </div>

                </div>    
                    <hr style={{margin: "0 0 5px 0"}}/>

                    <button type="submit" className="btn btn-primary btn-fill btn-info">Submit</button>


                    { props.poiEntityId && ( <>
                        &nbsp;
                        <div className="float-right">
                        <button type="button" className="btn btn-danger btn-fill" onClick={DeleteRefillingPlant} >
                            <i className="ti-trash"></i> Delete
                        </button>
                        </div>
                        </>

                    )}




            </div>
        </Fragment>
    );
}


// const MapContainer = props => {

//     let createMapOptions = function (maps) {
//         return {
//             styles: [{
//               "featureType": "all",
//               "elementType": "labels",
//               "stylers": [{
//                 "visibility": "#on"
//               }]
//             }],
//             streetViewControl: true     
//         }
//     }  
//     let bootstrapURLKeys = {
//         key: MapConfig.apiKey,
//         libraries: ['drawing', 'places', 'geometry'].join(',')
//     }   
    
//     if (!_.isEmpty(props.geometry)) {
//         return (
//             <div className="register-map-container">
//                 <p className="text-center">Click anywhere on the map to pin location..</p>
//                 <GoogleMapReact 
//                     bootstrapURLKeys={bootstrapURLKeys} 
//                     defaultCenter={MapConfig.center} 
//                     center={props.geometry.coordinates}
//                     options={createMapOptions}                                        
//                     defaultZoom={MapConfig.zoom} 
//                     onGoogleApiLoaded={({map, maps, geometryHandler, geometry}) => pinLocation(map, maps, props.geometryHandler, props.geometry)}
//                     yesIWantToUseGoogleMapApiInternals >
//                 </GoogleMapReact>

//             </div>        
//         );        
//     }
//     if (props.isRegister) {
//         return (
//             <div className="register-map-container">
//                 <p className="text-center">Click anywhere on the map to pin location..</p>
//                 <GoogleMapReact 
//                     bootstrapURLKeys={bootstrapURLKeys} 
//                     defaultCenter={MapConfig.center} 
//                     defaultZoom={MapConfig.zoom} 
//                     options={createMapOptions}                                        
//                     onGoogleApiLoaded={({map, maps, geometryHandler, geometry}) => pinLocation(map, maps, props.geometryHandler, {})}
//                     yesIWantToUseGoogleMapApiInternals >
//                 </GoogleMapReact>
//             </div>
//         );
//     }
//     return null;      

// }


// const pinLocation = (map, maps, geometryHandler, geometry) => {
//     if (searchPlace) {
//         searchPlace(map, maps);
//     }
    
//     let _marker = null;
//     map.mapTypeControl = true;
//     let icon = {
//         url: iconUrl || refillingPlantIcon,
//         scaledSize: new maps.Size(iconWidth || 50, iconHeight || 50)
//     }
//     if (!_.isEmpty(geometry)) {
//         const coords = {
//             lat: _.get(geometry, "coordinates[0]"),
//             lng: _.get(geometry, "coordinates[1]"),
//         };

//         let markerParams = {
//             position:coords,
//             map:map,
//             draggable: (PageisEditMode) ? true : false,
//             icon:icon
//         }
//         _marker = new maps.Marker(markerParams);
//     }

//     maps.event.addListener(map, "click", function(event) {
//         if (!PageisEditMode) {
//             return false;
//         }
//       if (_marker) {
//         _marker.setPosition(event.latLng);
//       } else {

//         _marker = new maps.Marker({
//           position: event.latLng,
//           map: map,
//           draggable:true,
//           icon: icon
//         });
//       }
//       geometryHandler(event.latLng);
//     });
// };

export default Form;