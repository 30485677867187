import React from "react";

/**
*
* @label of the select field
* @name of the input field
* @required | bool  |   defaults to true
* @onChange | fn
* @value    | fn
**/
const Select = ({type="text", disabled="", label, name, required=true, onChange=()=>{}, value, options, multiple = false}) => {
    options = options || [];
    const opts = options.map( (item, index) => {
        return (
            <option key={item.key} value={item.value}>{item.label}</option>
        );
    });    
    let SelectClass = `form-control border-input`;
    if (multiple) {
        SelectClass = `${SelectClass} multiple`;
    }
    return (
        <div className="form-group">
            <label>{label} {(required) ? "*" : null}</label>
            <select 
                className={SelectClass} 
                type={type} 
                name={name} 
                required={ (required) ? "required" : null }
                onChange={ onChange } 
                value={ value } 
                disabled={ disabled }
                multiple={multiple}
                size={ (multiple) ?  3 : 1 }
            >
            { 
                (!multiple) ? <option></option> :  null
            } 
            
            {opts}
            </select>
        </div>

    );
};

export default Select;