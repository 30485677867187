import React, { Component } from "react";
import _ from "lodash";
import {Redirect} from "react-router-dom";
//common
import BreadCrumbs from "../../layout/breadcrumbs";
import Loader from "../../common/loader";
import Errors from "../../common/notif/errors";
import Success from "../../common/notif/success";

//model
import Type from "../../../models/Type";

//view
import ShowView from  "../../views/admin/type/show";


class Show extends Component {

    constructor (props) {

        super(props);
        this.typeId =  _.get(this.props, "match.params.id");
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangePinColor = this.onChangePinColor.bind(this);
        this.addSubSelection = this.addSubSelection.bind(this);
        this.removeSubSelection = this.removeSubSelection.bind(this);
        this.showColorPicker = this.showColorPicker.bind(this);
        this.closeColorPicker = this.closeColorPicker.bind(this);
        this.colorPickerChange = this.colorPickerChange.bind(this);
        this.deleteAction = this.deleteAction.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {

            //ownerfields
            name: "",
            pinColor: "",
            email: "",
            hasSubType: "",
            subType: "",
            subTypeList: [],
            createdAt: "",
            updatedAt: "",

            //onpage states
            data: props.datam,
            error: null,
            success: null,
            isEditMode: false,
            buttonDisabled: false,
            showLoader: false,
            displayColorPicker: false

        };
    }

    async deleteAction () {
        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null,
            isEditMode: false
        });

        let {data, error} = await Type.Delete(this.typeId);
        if (data) {
            this.setState({
                redirect: true,
                success: "Type Deleted",
                error: null,
                showLoader: false,
                buttonDisabled: false,
            });
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
        }        
    }    

    onChangeName (e) {
        this.setState({
            name:
             e.target.value
        });
    }

    colorPickerChange (color) {
        this.setState({
            pinColor: color.hex
        });
    }

    closeColorPicker (color) {
         this.setState({ displayColorPicker: false })
    }

    showColorPicker () {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    }

    onChangePinColor (e) {
        this.setState({
            pinColor: e.target.value
        });
    } 


    addSubSelection (e) {
        if (e.key === "Enter") {
            let tmpSubtype = _.clone(this.state.subTypeList);
            if ( !_.isEmpty(e.target.value) ) {
                tmpSubtype.push(e.target.value);
            }
            e.target.value = "";
            e.preventDefault();
            this.setState({
                subType: "",
                subTypeList: tmpSubtype
            });
        }
    }

    removeSubSelection (e) {
        let tmpSubtype = _.clone(this.state.subTypeList);
        _.pullAt(tmpSubtype, e.currentTarget.value) 
        this.setState({
            subTypeList: tmpSubtype
        });
       
    }

    async onSubmit (e) {
        e.preventDefault();
        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null
        });
        let {name, pinColor, subTypeList} = this.state; 
        const params = { name, pinColor, subTypeList}
        _.set(params, "hasSubType", (_.size(subTypeList) > 0) );
        let {data, error} = await Type.Update(this.typeId, params);

        if (data) {
            this.setState({
                data: data,
                success: "Type Details Updated",
                error: null,
                showLoader: false,
                buttonDisabled: false,
                updatedAt: data.updatedAt
            });
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
        }
    }    


    async componentDidMount () {
        let {data} = this.state;
        if (_.isEmpty(data) || _.isNil(data) ) {
            await this.getData(this.typeId);
        }
    }

    async getData ( id ) {
        let {data, error} = await Type.Show( id );
        if (error) {
            return this.setState({error: error});
        }
        if (data) {
            this.setState({
                data: data,
                name: data.name,
                pinColor: data.pinColor,
                hasSubType: data.hasSubType,
                subTypeList: data.subTypeList,
                createdAt: data.createdAt,
                updatedAt: data.updatedAt
            });
        } else {
            this.setState({error: "No Record"});
        }
    }

    render () {
        let {data, error, success} = this.state;
        let {redirect = null} = this.state;
        if (redirect) {
            return (<Redirect to = {{
                pathname: '/admin/types',
                state: { deleteSuccess: this.state.success} 
            }}/>) 
        }        
        if (_.size(data) > 0 || error) {

            return (
                <React.Fragment>

                    <BreadCrumbs /> 
                    
                    <div className="col-md-12">
                        <Success success={success} />
                        <Errors error={error} /> 
                    </div>

                    <ShowView {...this}/>
                                    
                </React.Fragment>
            );            

        } else {
            return <div className="loading-screen"><Loader /></div>
        }


    }

}


export default Show;