import React, { Component, Fragment } from "react";
import {Redirect} from "react-router-dom";
import _ from "lodash";
import BreadCrumbs from "../../layout/breadcrumbs";
import ReactDOM from "react-dom";
import Errors from "../../common/notif/errors";
import Loader from "../../common/loader";

/*Load Models*/
import _PoiEntity from "../../../models/PoiEntity";
import District from "../../../models/District";
import Brand from "../../../models/Brand";
import Province from "../../../models/Province";

//config
import MapConfig from "../../../config/map";



/**/
import Form from "../../views/point-of-interest/refilling-plant/form";
const PoiEntity = new _PoiEntity("refilling plant");
class RegisterRefillingPlant extends Component {
    
    constructor (props) {
        super(props);

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeDistrict = this.onChangeDistrict.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeProvince = this.onChangeProvince.bind(this);
        this.onChangeMunicipality = this.onChangeMunicipality.bind(this);
        this.onChangeRemarks = this.onChangeRemarks.bind(this);
        this.geometryHandler = this.geometryHandler.bind(this);
        this.onChangeWidth = this.onChangeWidth.bind(this);
        this.onChangeHeight = this.onChangeHeight.bind(this);
        this.onChangeIcon = this.onChangeIcon.bind(this);

        this.searchPlace = this.searchPlace.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {

            //on page states
            buttonDisabled: false,
            showLoader: false,
            error: null,
            success: null,
            dependencyComplete: false,
            typeList: [],
            provinceList: [],
            hasSubType: false,
            subTypeList: [],
            isEditMode: true,
            isRegister: true,

            //dropdown states
            districts: [],
            provinces: [],
            municipalities: [],

            //field states
            name: "",
            district: "",
            address: "",
            province: "",
            municipality: "",
            remarks: "",
            geometry: {
                "type": "Point",
                "coordinates": [0, 0]
            },
            width: MapConfig.pin.width,
            height: MapConfig.pin.width,
            icon: "",
            iconUrl: "",

            rpId: ""


            
        };

    }

    onChangeIcon (e) {
        let _file = _.first(e.target.files);
        if (_file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({
                    iconUrl: e.target.result,
                    icon: _file
                });
            };
            reader.readAsDataURL(_file);
        }

    }    

    onChangeName (e) {
        this.setState({
            name: e.target.value
        });
    }

    geometryHandler (coords) {
        let geometry = {
            "type": "Point",
            "coordinates": [coords.lat(), coords.lng()]
        };

        this.setState({
            geometry: geometry      
        });
    }

    onChangeDistrict (e) {
        this.setState({
            district: e.target.value
        });
    }

    onChangeAddress (e) {
        this.setState({
            address: e.target.value
        });
    }

    onChangeRemarks (e) {
        this.setState({
            remarks: e.target.value
        });
    }


    onChangeWidth (e) {
        this.setState({
            width: e.target.value
        });
    }

    onChangeHeight (e) {
        this.setState({
            height: e.target.value
        });
    }        

    onChangeProvince (e) {
        let provinceId = e.target.value;
        let provinceList = this.state.provinceList;
        let selectedProvince = _.find(provinceList, {id: provinceId}) || {};
        let municipalities = [];
        if (_.size(selectedProvince.municipalities) > 0) {
            _.each(selectedProvince.municipalities, (item) => {
                municipalities.push({
                    key: item.id,
                    value: item.id,
                    label: item.name
                });
            } );
        }
        this.setState({
            province: e.target.value,
            municipalities: municipalities
        });
    }

    onChangeMunicipality (e) {
        this.setState({
            municipality: e.target.value
        });
    }

    async onSubmit (e) {
        e.preventDefault();
        let {geometry} = this.state;
        if (_.isEmpty(geometry)) {
            this.setState({
                error: "Please pin a location on the map."
            });
            window.scrollTo(0, 0);            
            return false;
        } 
        
        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null
        });

        let params = {
            name: this.state.name,
            address: this.state.address,
            district: this.state.district,
            municipality: this.state.municipality,
            province: this.state.province,
            remarks: this.state.remarks,
            geometry: geometry,
            height: this.state.height,
            width: this.state.height
        };

        let {data, error} = await PoiEntity.Create(params);
        if (data) {
            let iconResult = null;
            if (this.state.icon) {
                let formData = new FormData();
                formData.append("icon", this.state.icon);
                iconResult = await PoiEntity.UploadIcon(data.id, formData);
                if (iconResult.error) {
                    this.setState({
                        error: error,
                        buttonDisabled: false,
                        showLoader: false
                    });
                    return false;
                }
            }            
            this.setState({success: true, rpId: data.id});
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
        }      

        window.scrollTo(0, 0);
        return false;      

    }

    searchPlace (map, maps) {
        let input = ReactDOM.findDOMNode(this.refs.input);
        if (maps.places) {
            this.searchBox = new maps.places.SearchBox(input);
            this.searchBox.addListener('places_changed', () => {
                let places = this.searchBox.getPlaces();

                let bounds = new maps.LatLngBounds();
                places.forEach(function(place) {
                    if (!place.geometry) {
                      console.log("Returned place contains no geometry");
                      return;
                    }
                    bounds.extend(place.geometry.location);
                });   
                map.fitBounds(bounds);
                map.setZoom(17.4);                
            });
        }
    }



    async getDropdownValues () {
        //district
        let districtResult = await District.GetDropdownList();
        if (districtResult.error) { 
            this.setState({ dependencyError: districtResult.error});
        } else {
            this.setState({districts: districtResult.data});
        }

        //brand
        let brandResult = await Brand.GetDropdownList();
        if (brandResult.error) { 
            this.setState({ dependencyError: brandResult.error});
        } else {
            this.setState({brands: brandResult.data});
        }

        //provinces
        let provinceResult = await Province.GetDropdown();
        if (provinceResult.error) { 
            this.setState({ dependencyError: provinceResult.error});
        } else {
            this.setState({provinces: provinceResult.data, provinceList: provinceResult.list});
        }

        this.setState({
            dependencyComplete: true
        });

    }

    async componentDidMount () {
        await this.getDropdownValues();
    }

    render () {
        let {dependencyComplete, dependencyError, error, success, rpId} = this.state;
        if (dependencyError) {
            return (
                <Fragment>
                    <BreadCrumbs />
                    <div className="col-md-12">
                        <Errors error={dependencyError} /> 
                        <p><a href="/refilling-plants/register">Click here</a> to try again.</p>
                    </div>
                </Fragment>
            );
        }
        if (success) {

            return (
                <Redirect to = {{
                    pathname: `/admin/refilling-plants/${rpId}`,
                    state: { success: "New Refilling Plant Added"} 
                }} />
            )            

        }
        if (dependencyComplete || error) {
            return (
                <Fragment>
                    <BreadCrumbs />
                    <div className="col-md-12">
                        <Errors error={error} /> 
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="content">
                            
                               {/* <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <input ref="input" type="text" placeholder="type a place to center the map..."  className="form-control border-input" />
                                            </div>
                                        </div>                                
                                    </div>
                                </div>       */}                         

                                <form onSubmit={this.onSubmit}>
                                    <Form {...this}/>
                                </form>                        
                            </div>
                        </div>
                    </div>
              </Fragment>
            );
        } else {
            return <div className="loading-screen"><Loader message={"Loading dependencies..."}/></div>
        }
    }
}

export default RegisterRefillingPlant;