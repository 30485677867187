import React, { PureComponent } from "react";
import _ from "lodash";
import Loader from "../../common/loader";

import FilterBox from "./filter";
import MapContainer from "./map/container";
import AclService from "../../../services/AclService";

/*Models*/
import EsModel from "../../../models/ElasticSearch";
import Brand from "../../../models/Brand";
import PoiEntity from "../../../models/PoiEntity";

import Errors from "../../common/notif/errors";
// import Success from "../../common/notif/success";


const FILTER_STORAGE_KEY = "poi-filters";
let RefillingPlant = new PoiEntity();


class PointOfInterest extends PureComponent {

    _currentUserRole = null;
    _permittedBrands = null;


    constructor (props) {
        super(props);
        this.ToggleFilter = this.ToggleFilter.bind(this);
        this.ToggleGeofenceView = this.ToggleGeofenceView.bind(this);
        this.ToggleRefillingPlantsView = this.ToggleRefillingPlantsView.bind(this);
        this.ToggleRadiusView = this.ToggleRadiusView.bind(this);
        this.ToggleRadiusValue = this.ToggleRadiusValue.bind(this);
        this.ToggleBrandView = this.ToggleBrandView.bind(this);

        this._currentUserRole = AclService.getRole();
        if (this._currentUserRole === "user") {
            this._permittedBrands = _.map(AclService.getBrandPermissions(), "id");
        }
        this.state = {
            loadComplete: false,
            isFiltered: false,
            showGeofence: true,
            showRadius: false,
            radius: 5,
            showRefillingPlants: false,
            toggleBrands: [],
            brandTogglePins: [],
            refillingPlants: [],
            toggleVisibleBrands: []
        }
    }

    async componentDidMount () {

        let params = {};
        if (this._currentUserRole === "user") {
            _.set(params, "brand", this._permittedBrands);
        }

        await this.getData(params);

    }

    async componentDidUpdate () {
        const {isFiltered, loadComplete} = this.state;
        if (isFiltered && !loadComplete) {
            await this.getData();
        }
    }

    async getData(params) {

        // check if role === user and if permittedBrands is empty, throw error
        if (this._currentUserRole === "user" && _.isEmpty(this._permittedBrands)) {
            this.setState({
                permissionError: "No Brand is associated with yout account, contact your administrator.",
                dependencyComplete: true
            });
            return false;
        }
        let brandResult = {};
        let excludeBrands = [];
        brandResult = await Brand.GetToggleList();
        if (brandResult.data) {
            excludeBrands = _.map(brandResult.data, "id");
        }
        //check for type param
        // const uriParams = new URLSearchParams( this.props.location.search ); 
        // const _type = uriParams.get('type');
        // _.set(params, "type", _type || []);

        //check local storage for filters
        let filterParams = JSON.parse( localStorage.getItem( FILTER_STORAGE_KEY ) ) || {};
        if (filterParams) {
            filterParams.isFiltered = true;
            filterParams.excludeBrands = excludeBrands;
        }

        if (this._currentUserRole === "user") {
            if (filterParams) {
                if (_.isEmpty(filterParams.brand)) {
                    filterParams.brand = this._permittedBrands;
                }
            } else {
                filterParams.brand = this._permittedBrands;
            }
        }
        let refillingPlantResult = await RefillingPlant.Get();

        let brandTogglePINS = await EsModel.GetPOIs( {brand: excludeBrands});

        let {data, error} = await EsModel.GetPOIs( filterParams );

        this.setState({
            data: data,
            error: error,
            loadComplete: true,
            refillingPlants: refillingPlantResult.data,
            brandTogglePins: brandTogglePINS.data,
            toggleBrands: brandResult.data || []
        });            
    }

    ToggleFilter () {
        this.setState({
            isFiltered: true,
            loadComplete: false
        });
    }

    ToggleBrandView (toggle, id) {
        if (toggle) {
            this.setState({ 
                toggleVisibleBrands: [...this.state.toggleVisibleBrands, id] 
            });
        } else {
            let {toggleVisibleBrands} = this.state;
            toggleVisibleBrands = toggleVisibleBrands.filter( (item) => {
                return item !== id;
            });
            this.setState({
                toggleVisibleBrands: toggleVisibleBrands
            });
        }
    }

    ToggleGeofenceView (toggle) {
        this.setState({
            showGeofence: toggle
        });
    }

    ToggleRadiusView (toggle) {
        this.setState({
            showRadius: toggle
        });
    }

    ToggleRadiusValue (e) {
        const {value} = e.target;
        if (value.length <= 3) {
            this.setState({
                radius: value
            });
        }
        // if (/^\d+$/.test(value)) {
        // }        
        
    }

    ToggleRefillingPlantsView (toggle) {
        this.setState({
            showRefillingPlants: toggle
        });
    }

    render () {

        const {loadComplete, data, error, showGeofence, refillingPlants, showRefillingPlants, showRadius, radius, toggleBrands, brandTogglePins, toggleVisibleBrands} = this.state;
        if (!loadComplete) {
            return <div className="loading-screen"><Loader message={"Loading Point of Interests..."}/></div>
        }
        return (
            <div className="col-md-12">
                {
                    error && (<Errors error={error} /> )
                }

                <div id="poi-container">
                    <div className="poi-map-container">
                        <FilterBox {...this}/>
                        <MapContainer data={data} brandTogglePins={brandTogglePins} toggleBrands={toggleBrands} showGeofence={showGeofence} refillingPlants={refillingPlants} showRefillingPlant={showRefillingPlants} showRadius={showRadius} radius={radius} toggleVisibleBrands={toggleVisibleBrands} />
                    </div>
                </div>
            </div>                
        );
        
    }
}
export default PointOfInterest;