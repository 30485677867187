// import React, { Component, Fragment } from "react";
// import _ from "lodash";

// //common
// import Input from "../../../common/form/input";
// import Select from "../../../common/form/select";

// //Model
// import Province from "../../../../models/Province";

// class Form extends Component {
//     constructor (props) {
//         super(props);

//         this.onChangeCode = props.onChangeCode;
//         this.onChangeName = props.onChangeName;

//         this.state = {
//             province: props.state.province,
//             code: props.state.code,
//             name: props.state.name,
//             isEditMode: props.state.isEditMode,
//             provinces: []
//         }
//     }

//     async componentDidMount () {
//         let {provinces} = this.state;
//         if ( _.isEmpty(provinces) ) {
//             await this.getProvinces();
//             this.forceUpdate();
//         }
//     }

//     async getProvinces () {
//         let {data} = await Province.GetDropdownList();
//         if (data && _.size(data) > 0) {
//             this.setState({provinces: data});
//         } else {
//             this.setState({provinces: []});
//         }
//     }

//     loadSelection () {
        
//     }

//     render () {
//         const {isEditMode} = this.state;
//         return (
//             <Fragment>
//                 <div className="row">
//                     <div className="col-md-12">
//                         <Select 
//                             label={"Province"}
//                             name={"province"}
//                             value={this.state.province}
//                             onChange={this.onChangeType}
//                             options={this.state.provinces}
//                             disabled={!isEditMode}
//                         />                      
                                       
//                     </div>
//                     <div className="col-md-12">
//                         <Input 
//                             label={"Code"} 
//                             onChange={this.onChangeCode} 
//                             value={this.state.code} 
//                             name={"code"} 
//                             disabled={!isEditMode}                        
//                         />                
//                     </div>
//                     <div className="col-md-12">
//                         <Input 
//                             label={"Name"} 
//                             onChange={this.onChangeName} 
//                             value={this.state.name} 
//                             name={"name"} 
//                             disabled={!isEditMode}                        
//                         />
//                     </div>
//                 </div>
//             </Fragment>            
//         );
//     }
// }

// export default Form;


import React, { Fragment } from "react";

//common
import Input from "../../../common/form/input";

const Form = props => {
    const {isEditMode} = props.state; 
    return (

        <Fragment>
            <div className="row">
                <div className="col-md-6">
                    <Input 
                        label={"Code"} 
                        onChange={props.onChangeCode} 
                        value={props.state.code} 
                        name={"code"} 
                        disabled={!isEditMode}                        
                    />                
                </div>
                <div className="col-md-6">
                    <Input 
                        label={"Name"} 
                        onChange={props.onChangeName} 
                        value={props.state.name} 
                        name={"name"} 
                        disabled={!isEditMode}                        
                    />
                </div>
            </div>
        </Fragment>            

    );
}


export default Form;
