const _ = require("lodash");
module.exports = {
  apiKey: process.env.REACT_APP_MAP_API_KEY,
  center: { 
    lat: _.toNumber( process.env.REACT_APP_MAP_CENTER_LAT ),
    lng: _.toNumber( process.env.REACT_APP_MAP_CENTER_LNG )
  },
  zoom: _.toNumber( process.env.REACT_APP_MAP_ZOOM ),
  geofenceZoom: _.toNumber( process.env.REACT_APP_GEOFENCE_MAP_ZOOM ),
  polygon: {
    fillColor: "#f79671",
    strokeColor: "#bb3704",
    strokeWeight: 2
  },
  pin: {
    width: 50,
    height: 65
  }

};