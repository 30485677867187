import React, { Component, Fragment } from "react";
import Error from "../../common/notif/errors";

//model
import Municipality from "../../../models/Municipality";

//view
import View from  "../../views/admin/municipality/create";

class Create extends Component {
    _isMounted = false;
    constructor (props) {

        super(props);

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeCode = this.onChangeCode.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.isSuccess = props.isSuccess;

        this.state = {
            error: null,
            buttonDisabled: false,
            showLoader: false,
            name: "",
            code: "",
            province: props.provinceId,
            updatedAt: "",
            createdAt: "",
            isSuccessful: ""
        };
    }

    onChangeName (e) {
        this.setState({
            name: e.target.value
        });
    }

    onChangeCode (e) {
        this.setState({
            code: e.target.value
        });
    }

    closeModal () {
        window.$("#municipality-modal").modal("hide").on("hidden.bs.modal", (e) => {
            if (this._isMounted) {
                this.setState({
                    buttonDisabled: false
                });
            }
        });
    }

    componentWillUnmount () {
        this._isMounted = false;
    }

    componentDidMount () {
        this._isMounted = true;
    }

    async onSubmit (e) {
        e.preventDefault();

        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null
        });

        let  { name, code, province } = this.state; 

        let {data, error} = await Municipality.Create({name, code, province});
        if (data) {
            this.reset();
            this.closeModal();
            this.isSuccess("New Municipality Added");
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
        }
    }

    reset () {
        this.setState({
            error: null,
            buttonDisabled: false,
            showLoader: false,
            name: "",
            code: "",
            updatedAt: "",
            createdAt: ""
        });
    }

    render () {
        const {error, showLoader} = this.state;
        let hasError = (error) ? <Error error={error} /> : null;
        
        return (
            <Fragment>
                <div>{hasError}</div>
                <View {...this} showLoader={showLoader} />
            </Fragment>
        );
    }


}

export default Create;