import React from "react";
import logo from "../../assets/logo.png";
import Errors from "../common/notif/errors";
import AuthConfig from "../../config/auth";
import AuthSDK from "../../libs/identity-server/Auth";

const Login = props => {
    const AuthSdk = new AuthSDK(AuthConfig);
    return (
        <div id="login-container">

            <div className="card">
                <div className="header">
                    <div className="logo">
                        <a href="/" className="simple-text">
                            <img src={logo} alt="BIVMC" />
                        </a><br/>
                        <span className="logo-title">Business Mapper & GEO Fencing App</span>
                    </div>                
                </div>
                <div className="content">
                <h6 className="text-center">Please login to use this application.</h6>
                <Errors error={props.state.error}/>
                    <hr />
                    
                    <a href={ AuthSdk.BuildLoginUrl() } type="submit" className="btn btn-fill btn-info btn-block">
                        <span className="ti-unlock"></span> Login
                    </a>

                    <br />
                    <br />
                </div>
            </div>        
        </div>
    );
};

export default Login;