import React, { Component } from "react";
import MapContainer from  "./point-of-interest/pin/list/map";
import _ from "lodash";
import {Redirect, NavLink} from "react-router-dom";
import Loader from "./common/loader"
import MeasureTool from "measuretool-googlemaps-v3";

/*Services*/
import Image from "../services/Image";
import Util from "../services/Util";
/*Load Models*/
import EsModel from "../models/ElasticSearch";
import PoiEntity from "../models/PoiEntity";
import Geofence from "../models/Geofence";

/*assets*/
import refillingPlantIcon from "../assets/icon-google-map-10.jpg";

/*config*/
import MapConfig from "../config/map";


let RefillingPlant = new PoiEntity();
let polygonList = [];
class MapOverview extends Component {
    _map = null;
    _maps = null;
    

    constructor (props) {
        super(props);
        this.loadMapElements = this.loadMapElements.bind(this);
        this.clickPoi = this.clickPoi.bind(this);
        this.clickRefillingPlant = this.clickRefillingPlant.bind(this);
        this.togglePoi = this.togglePoi.bind(this);
        this.toggleRefillingPlants = this.toggleRefillingPlants.bind(this);
        this.toggleGeofences = this.toggleGeofences.bind(this);
        this.state = {
            dependencyComplete: false,
            dependencyError: false,

            pois: [],
            refillingPlants: [],
            geofences: [],

            poiRedirectId: null,

            togglePoi: true,
            toggleRefillingPlants: true,
            toggleGeofences: true
        }
    }

    togglePoi (e) {
        this.setState({
            togglePoi: !this.state.togglePoi,
            data: [],
            dependencyComplete: false
        });
    }

    toggleRefillingPlants (e) {
        this.setState({
            toggleRefillingPlants: !this.state.toggleRefillingPlants,
            data: [],
            dependencyComplete: false            
        })
    }

    toggleGeofences (e) {
        this.setState({
            toggleGeofences: !this.state.toggleRefillingPlants,
            data: [],          
            dependencyComplete: false            
        })
    }

    clickPoi (id) {
        this.setState({
            poiRedirectId: id
        });
    }

    clickRefillingPlant (id) {
        this.setState({
            rfRedirectId: id
        });
    }

    loadMapElements (map, maps) {

        new MeasureTool(map, {
          contextMenu: true,
          showSegmentLength: true,
          tooltip: true,
          unit: MeasureTool.UnitTypeId.METRIC // metric, imperial, or nautical
        });  

        if (!map || !maps) {
            return false;
        }

        let {togglePoi, toggleGeofences, toggleRefillingPlants}  = this.state;        

        //load point of interest
        if (togglePoi) {
            this.loadPoiPins(this.state.pois, map, maps);    
        }
        
        if (toggleRefillingPlants) {
            this.loadRefillingPlants(this.state.refillingPlants, map, maps);    
        }
        //load refilling plants

        //load geofences
        if (toggleGeofences) {
            this.loadGeofences(this.state.geofences, map, maps);    
        }
        

        this._map = map;
        this._maps = maps;

    }

    loadGeofences (data, map, maps) {
        map.mapTypeControl = true;
        maps.event.addListener(map, "maptypeid_changed", function (e) {
            const mapType = map.getMapTypeId();
            if (mapType === "satellite" || mapType === "hybrid") {
                _.each(polygonList, (i) => {
                    i.setOptions({ fillOpacity: 0.7});
                });
            } else {
                _.each(polygonList, (i) => {
                    i.setOptions({ fillOpacity: 0.3});
                });
            }
        });    
        _.each(data, (geofence, k) => {
            let coords = Util.convertCoordinates(geofence.polygonCoords);
            let polygon = new maps.Polygon({
                path: coords,
                strokeColor: geofence.strokeColor,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: geofence.fillColor,
                fillOpacity: 0.3,
                editable: false
            });

            if (maps.geometry) {
                let area = maps.geometry.spherical.computeArea(polygon.getPath());
                _.set(geofence, "area", `${(area / 1e+6).toFixed(2)} sqkm`);
            }

            polygon.setMap(map)

            polygonList.push(polygon);
            _.set(geofence, "polygon", polygon);
            
            let infowindow = new maps.InfoWindow({
                content: PolygonInfoTemplate({name: geofence.name, owner: _.get(geofence, "owner.name", "Unassigned.") })
            });

            polygon.addListener("mouseover", function(event) {
                infowindow.setPosition(event.latLng);                
                infowindow.open(map);
            });

            polygon.addListener("mouseout", function () {
              infowindow.close(map);
            });

        });


    }

    loadRefillingPlants (data, map, maps) {
        map.mapTypeControl = true;
        let self = this;
        _.each(data, (item, index) => {
            let infowindow = new maps.InfoWindow({
                content: RefillingPlantInfoBoxTemplate({name: item.name, address: item.address, landLine: item.landLine})
            });

            let icon = {
                url: item.iconUrl || refillingPlantIcon,
                scaledSize: new maps.Size(item.width || MapConfig.pin.width, item.height || MapConfig.pin.height)
            }
            let coords = item.geometry.coordinates;
            let marker = new maps.Marker({
                position: new maps.LatLng( _.get(coords,"[0]", null), _.get(coords,"[1]", null)),
                map,
                title: item.name,
                icon: icon
            });

            marker.set("id", item.id);
            marker.addListener("click", function() {
                self.clickRefillingPlant(this.get("id"));
            });

            marker.addListener("mouseover", function() {
              infowindow.open(map, marker);
            });

            marker.addListener("mouseout", function () {
              infowindow.close(map, marker);
            });

        });        
    }

    loadPoiPins (data, map, maps) {
        let self = this;
        _.each(data, (item, index) => {

            let infowindow = new maps.InfoWindow({
                content: PoiInfoBoxTemplate({name: item.name, address: item.address, landLine: item.landLine, brand: _.get(item, "brand.name", "")})
            });

            let brandIcon = _.get(item, "brand.icon");
            let _icon = null;
            let pinConfig = _.get(MapConfig, "pin", {});
            if (brandIcon) {
                _icon = {
                    url: Image.toUrlPath(brandIcon.filename),
                    scaledSize: new maps.Size(_.get(item, "brand.width", pinConfig.width), _.get(item, "brand.height", pinConfig.height) ),
                };
            } else {
                _icon = {
                    path: `M32 2a20 20 0 0 0-20 20c0 18 20 40 20 40s20-22 20-40A20 20 0 0 0 32 2zm0 28a8 8 0 1 1 8-8 8 8 0 0 1-8 8z`,
                    strokeColor: "#adadad",
                    strokeWeight: 1,
                    fillColor: "#CC0000",
                    fillOpacity: 1.0,
                    scale: 0.6
                };
            }

            let coords = item.geometry.coordinates;
            let marker = new maps.Marker({
                position: new maps.LatLng( _.get(coords,"[0]", null), _.get(coords,"[1]", null)),
                map,
                title: item.name,
                icon: _icon
            });
            marker.set("id", item.id);
            marker.addListener("click", function() {
                self.clickPoi(this.get("id"));
            });

            marker.addListener("mouseover", function() {
              infowindow.open(map, marker);
            });

            marker.addListener("mouseout", function () {
              infowindow.close(map, marker);
            });

        });
    }

    async getData() {
        
        
        let poiResult = await EsModel.GetPOIs();
        if (poiResult.error) this.setState({dependencyError: poiResult.error});

        let refillingPlantResult = await RefillingPlant.Get();
        if (refillingPlantResult.error) this.setState({dependencyError: refillingPlantResult.error});

        let geofenceResult = await Geofence.Get();
        if (geofenceResult.error) this.setState({dependencyError: geofenceResult.error});

        this.setState({
            dependencyComplete: true,
            pois: poiResult.data, 
            refillingPlants: refillingPlantResult.data, 
            geofences: geofenceResult.data, 
            data: [poiResult.data, refillingPlantResult.data, geofenceResult.data]
        });
    }    


    async componentDidMount () {
        let {dependencyComplete} = this.state;
        if (!dependencyComplete > 0) {
            await this.getData();
        }
    }

    async componentDidUpdate () {
        let {dependencyComplete} = this.state;
        if (!dependencyComplete > 0) {
            await this.getData();
        }
    }

    render () {
        let {error, data, poiRedirectId, rfRedirectId} = this.state;
        if (poiRedirectId) {
            return <Redirect to={`/point-of-interests/${poiRedirectId}`} />
        }
        if (rfRedirectId) {
            return <Redirect to={`/refilling-plants/${rfRedirectId}`} />
        }
        if (_.size(data) > 0) {
            return (
                <div className="col-md-12">
                    <div id="map-overview">
                        <div className="poi-filter-container">
                            <NavLink to={"#"} data-toggle="collapse" data-target="#filter-container" className="btn-block" exact><small><i className="ti-filter"></i> Filter</small></NavLink>
                            <div className="row collapse" id="filter-container">

                                <br />
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-1">&nbsp;</div>
                                        <div className="col-md-2">
                                            <div className="form-check">
                                                <input type="checkbox" id="toggle-poi" onChange={this.togglePoi} checked={this.state.togglePoi} className="form-check-input form-input" />
                                                <label className="form-check-label" htmlFor="toggle-poi">
                                                    &nbsp; Point of Interest
                                                </label>                                                
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-check">
                                                <input type="checkbox" id="toggle-rf"  onChange={this.toggleRefillingPlants}  checked={this.state.toggleRefillingPlants} className="form-check-input form-input" />
                                                <label className="form-check-label" htmlFor="toggle-rf">
                                                    &nbsp; Refilling Plants
                                                </label>                                                
                                            </div>
                                        </div>                                             
                                        <div className="col-md-2">
                                            <div className="form-check">
                                                <input type="checkbox" id="toggle-geofence" onChange={this.toggleGeofences}  checked={this.state.toggleGeofences}  className="form-check-input form-input" />
                                                <label className="form-check-label" htmlFor="toggle-geofence">
                                                    &nbsp; GeoFences
                                                </label>                                                    
                                            </div>
                                        </div>                                             
                                    </div>
                                </div>
                            </div>
                             
                        </div>                   
                        <MapContainer handler={this.loadMapElements} data={this.state.data} error={error}/>                
                    </div>
                </div>            
            );

        } else {
            return <div className="loading-screen"><Loader message={"Loading Map elements..."}/></div>
        }
    } 

};

const PoiInfoBoxTemplate = _.template(`<div class="map-info-container">
        <h6 class="heading">Point of Interest</h6>
        <h5 class="title"><%- name %></h5>
        <p class="address"><%- address %></p>
        <p class="phone"><%- landLine %></p>
        <hr>
        <p class="meta">Brand: <%- brand %></p>
        <p class="meta">Type: <%- brand %></p>
    </div>`);


const RefillingPlantInfoBoxTemplate = _.template(`<div class="map-info-container">
        <h6 class="heading">Refilling Plant</h6>    
        <h5 class="title"><%- name %></h5>
        <p class="address"><%- address %></p>
        <p class="phone"><%- landLine %></p>
    </div>`);

const PolygonInfoTemplate = _.template(`<div class="map-info-container">
        <h6 class="heading">Geofence</h6>    
        <h5 class="title"><%- name %></h5>
        <p class="address"><b>Owner: </b><%- owner %></p>
    </div>`);


export default MapOverview;
