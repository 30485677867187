import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import Modal from "./modal";
import Form from "./form";
import Csv from "./csv";
import ToggleCategories from "./toggle-categories";

const FILTER_STORAGE_KEY = "poi-filters";

const Filter = props => {
    const {ToggleGeofenceView, ToggleRefillingPlantsView, ToggleRadiusView, ToggleRadiusValue, ToggleBrandView} = props;
    const {isFiltered, data, showGeofence, showRefillingPlants, showRadius, radius, toggleBrands} = props.state;

    const [resetToggle, setResetToggle] = useState(false);

    const ToggleGeofence = (e) => {
          const {checked} = e.target;
          ToggleGeofenceView( checked );
    }

    const ToogleRadius = (e) => {
          const {checked} = e.target;
          ToggleRadiusView( checked );
    }

    const ToggleRefillingPlants = (e) => {
          const {checked} = e.target;
          ToggleRefillingPlantsView( checked );
    }

    const ToggleFilterModal = e => {
        e.preventDefault();
        localStorage.removeItem( FILTER_STORAGE_KEY );     
        setResetToggle( !resetToggle );
        // console.log("ToggleFilterModal:", e);
        // // data-toggle="modal" 
        return true;
    }


    let options = [];
    for (var ctr = 1; ctr <=10; ctr++) {
        options.push(<option value={ctr} key={ctr}>{ctr}</option>)
    }
    return (
        <>
        <div className="poi-filter-container">
            <button className="float-left btn btn-sm btn-danger" data-toggle="modal" data-target="#filter-modal" onClick={ e => ToggleFilterModal(e) }><small><i className="ti-filter"></i> Filter</small></button>
            <div className="system-toggle">
                <div>
                    <input className="form-check-input" id="radius-view" type="checkbox" onClick={ToogleRadius} defaultChecked={showRadius}/> 
                    <label className="form-check-label" htmlFor="radius-view">&nbsp; Radius</label>
                    &nbsp;
                    <input type="number" className="toggle-unit" maxLength="3"  min="1" max="999" onChange={ToggleRadiusValue} value={radius} /> km
{/*                    <select onChange={ToggleRadiusValue} defaultValue={radius}>
                        {options}
                    </select>
*/}
                    &nbsp;&nbsp;&nbsp;                    
                    <input className="form-check-input" id="geofence-view" type="checkbox" onClick={ToggleGeofence} defaultChecked={showGeofence}/> 
                    <label className="form-check-label" htmlFor="geofence-view">&nbsp; Geofence</label>

                    &nbsp;&nbsp;&nbsp;
                    <input className="form-check-input" id="rf-view" type="checkbox" onClick={ToggleRefillingPlants} defaultChecked={showRefillingPlants}/>
                    <label className="form-check-label" htmlFor="rf-view">&nbsp; Refilling Plants</label>
                </div>
                
            </div>

            <div className="category-toggle">
                <ToggleCategories toggleBrands={toggleBrands} toggleHandler={ToggleBrandView}/>
            </div>

            
            <Modal>
              <Form onSubmit={props.ToggleFilter} resetToggle={resetToggle}/>
            </Modal>
        </div>  

            {
              isFiltered && <ResultNotif data={data}/>
            }
          
          </>  

    );
};


const ResultNotif = props => {
  const {data} = props;
    return (

        <div className="card">
            <div className="alert alert-info">
                <div className="message"><b>Your filter returned {data.length} results. <NavLink to={"#"} onClick={() => {Csv( data )}} download="test.csv">Click here</NavLink> to export / download the result.</b> </div>
            </div>
        </div>               
    );
}

export default Filter;