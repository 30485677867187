
class Radius {

  constructor (map, maps) {
    this._map = map;
    this._maps = maps;
  }

  Draw (geometry, visible, radius = 5) {

    const coords = geometry.coordinates;
    let radiusList = this._map.RADIUS_LIST || [];    

    let pointOfInterest = new this._maps.Circle({
      strokeColor: '#fc8403',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#fcba03',
      fillOpacity: 0.35,
      map: this._map,
      center: new this._maps.LatLng( coords[0], coords[1] ),
      radius: (radius * 1000) // * 1000 to convert to km
    });
    pointOfInterest.setVisible(visible);

    radiusList.push(pointOfInterest);
    this._map.RADIUS_LIST = radiusList;  
  }

}
export default Radius;