import React from "react";
import Filter from "./filter";

const ControlBox = ({element, setFilter, data, children}) => {
    return (
        <div className="col-md-12">
            <div className="card">                
                <div className="content">

                        <Filter data={data} setFilter={setFilter}/>

                        { element &&  (
                            <div className="col-md-3 col-md-offset-6">
                                <button type="button" className="float-right btn btn-info btn-fill" data-toggle="modal" data-target={ element } >
                                    <i className="ti-plus"></i> Add New
                                </button>
                            </div>
                            )
                        }

                        { children && children}
                        <div className="clear"></div>

                </div>
            </div>                          
        </div>        
    );
}

export default ControlBox;