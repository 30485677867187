import React, {useEffect, useState} from "react";

// import District from "./fields/district";
import Province from "./fields/province";
import Brand from "./fields/brand";
import RefillingPlants from "./fields/refilling-plants";
import Name from "./fields/name";


const FILTER_STORAGE_KEY = "poi-filters";
const Form = props => {

    const {onSubmit, resetToggle} = props;

    const [brandFilter, setBrandFilter] = useState([]);
    const [poientityFilter, setPoiEntityFilter] = useState([]);
    const [districtFilter, setDistrictFilter] = useState([]);
    const [provinceFilter, setProvinceFilter] = useState([]);
    const [typeFilter, setTypeFilter] = useState("");
    const [subTypeFilter, setSubtypeFilter] = useState([]);
    const [nameFilter, setNameFilter] = useState("");

    useEffect(() => {
        let filters = JSON.parse( localStorage.getItem( FILTER_STORAGE_KEY ) ) || {};
        let {brand = [], poientity = [], district = [], province = [], type = "", subType = [], name=""} = filters;
        setBrandFilter( brand );
        setPoiEntityFilter( poientity );
        setDistrictFilter( district );
        setProvinceFilter( province );
        setTypeFilter( type );
        setSubtypeFilter( subType );
        setNameFilter(name);
        // setComplete(true);
    },[]);

    useEffect(() => {
        if (resetToggle) {
            localStorage.removeItem( FILTER_STORAGE_KEY );     
            setBrandFilter( [] );
            setPoiEntityFilter( [] );
            setDistrictFilter( [] );
            setProvinceFilter( [] );
            setTypeFilter( "" );
            setSubtypeFilter( [] );    
            setNameFilter("");          
        }
      
    }, [resetToggle]);

    const submitFilters = () => {

        let params = {
            subType: subTypeFilter,
            type: typeFilter,
            province: provinceFilter,
            district: districtFilter,
            poientity: poientityFilter,
            brand: brandFilter,
            name: nameFilter
        };

        //store filters to local storage
        localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(params) );        
        onSubmit();
    }

    const resetFilter = () => {

        let ans = window.confirm("Are you sure you want to reset all filters?");
        if (ans) {
            localStorage.removeItem( FILTER_STORAGE_KEY );     
            setBrandFilter( [] );
            setPoiEntityFilter( [] );
            setDistrictFilter( [] );
            setProvinceFilter( [] );
            setTypeFilter( "" );
            setSubtypeFilter( [] );
            setNameFilter("");  
            window.location = "/point-of-interests";
        }
        return false;
    }

    return (
        <>
            <div className="row filter-wrapper">

                <Name value={nameFilter} handler={setNameFilter}/>

                {/*<Type checkHandler={setTypeFilter} checkedItems={typeFilter} subCheckHandler={setSubtypeFilter} subCheckItems={subTypeFilter} />*/}

                <Brand checkHandler={setBrandFilter} checkedItems={brandFilter}/>

                <RefillingPlants checkHandler={setPoiEntityFilter} checkedItems={poientityFilter}/>

                {/*<District checkHandler={setDistrictFilter} checkedItems={districtFilter} />*/}

                <Province checkHandler={setProvinceFilter} checkedItems={provinceFilter} />
                
                <div className="col-md-12"><hr /></div>
                <div className="col-md-3  float-right">

                    <div className="form-group float-right">
                        <label>&nbsp;</label>
                        <br/>
                        <button type="button" className="btn btn-info btn-sm btn-fill" data-dismiss="modal"  onClick={submitFilters}>
                            <i className="ti-search"></i> Search
                        </button>
                        &nbsp;
                        <button type="button" className="btn btn-info btn-sm" onClick={resetFilter}>
                            Reset
                        </button>

                    </div>
                </div>                  
            </div>


        </>
    );
}

export default Form;