import React, { Component } from "react";


import EditFormContainer from "../../../layout/edit-form";
import Form from "./form";

class Show extends Component {

    constructor (props) {
        super(props);
        this.enableEditMode = this.enableEditMode.bind(this);
        this.disableEditMode = this.disableEditMode.bind(this);

        this.onChangeName = props.onChangeName;
        this.onChangeCode = props.onChangeCode;
        this.deleteAction = props.deleteAction;
        this.onSubmit = props.onSubmit;

        this.state = {

            //form control
            isEditMode: props.isEditMode,

            //model fields
            data: props.state.data,
            name: props.state.name,
            code: props.state.code,
            createdAt: props.state.createdAt,
            updatedAt: props.state.updatedAt,

        }

    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            code: nextProps.state.code,
            name: nextProps.state.name,
            data: nextProps.state.data, 
            buttonDisabled: nextProps.state.buttonDisabled,
            showLoader: nextProps.state.showLoader,
            createdAt: nextProps.state.createdAt,
            updatedAt: nextProps.state.updatedAt
        });
    }


    enableEditMode (e) {
        this.setState({
            isEditMode: true
        });
    }

    disableEditMode (e) {
        this.setState({
            isEditMode: false,
            code: this.state.data.code,
            name: this.state.data.name
        });
    }  

    render () {
        const { isEditMode } = this.state;
        const recordMetaData = {
            createdAt: this.state.createdAt,
            updatedAt: this.state.updatedAt
        };
        return (

            <EditFormContainer 
                listLink={`/admin/provinces/${this.props.provinceId}`}
                isEditMode={isEditMode}
                enableEditMode={this.enableEditMode}
                form={<Form  {...this} disableEditMode={this.disableEditMode} />}
                recordMetaData={recordMetaData}
                button={{disableEditMode: this.disableEditMode, buttonDisabled: this.state.buttonDisabled, showLoader:this.state.showLoader, isEditMode: isEditMode, deleteAction: this.deleteAction }}
                onSubmit={this.onSubmit}
            />

        );
    }

};
export default Show;