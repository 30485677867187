import CoreService from "../services/Core";
import _ from "lodash";

class PoiGeofence {

    constructor () {
        this.path = "/poi-geofence";
    }

    async Show (poi_id) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.get(`/point-of-interests/${poi_id}/geofence`);
            _.set(result, "data", response.data.data );
        } catch (e) {
            _.set(result, "error",  ((e.response)) ? _.get(e, "response.data.error.message") : "Cannot Communicate to the API Server." );
        }
        return result;
    }


    async Create (params) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.post(this.path, params);
            _.set(result, "data", response.data );
        } catch (e) {
            let _error = "Cannot Communicate to the API Server.";
            if (e.response) {
                _error = _.get(e, "response.data.meta.message");
            }
            _.set(result, "error",  _error);
        }
        return result;
    }


}

export default new PoiGeofence();