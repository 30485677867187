module.exports = {

  core: {
    url: process.env.REACT_APP_CORE_API_URL
    // url: "http://localhost:8000/v1"
  },
  auth: {
    url: process.env.REACT_APP_AUTH_API_URL
  },
  audit: {
    url:  process.env.REACT_APP_AUDIT_API_URL
  },
  images: {
    url:  process.env.REACT_APP_IMAGE_BASE_URL
  }

};
