import React, { Component } from "react";
import _ from "lodash";


//common
import Table from "../../common/table/table";
import Modal from "../../common/modal";
import ControlBox from "../../common/table/control-box";
import Errors from "../../common/notif/errors";
import Success from "../../common/notif/success";
import Loader from "../../common/loader";
import BreadCrumbs from "../../layout/breadcrumbs";

//model
import Province from "../../../models/Province";

//misc
import Create from "./create";

class List extends Component {

    constructor (props) {
        super(props);

        this.isSuccessful = this.isSuccessful.bind( this );
        this.setFilter = this.setFilter.bind( this );

        this.modalElement = "#province-modal";

        this.state = {
            data: [],
            error: null,
            success: null,
            dependencyComplete: false            
        }
    }

    isSuccessful (message, e) {
        this.setState({
            success: message,
            dependencyComplete: false,
            data: []
        });
    }

    setFilter (data, e) {
        this.setState({ 
            data: data
        });
    }

    async componentDidUpdate(prevProps) {
        let {dependencyComplete} = this.state;
        if (!dependencyComplete) {
            await this.getData();
        }
    }    


    async componentDidMount () {
        let {dependencyComplete} = this.state;
        if (!dependencyComplete) {
            await this.getData();
        }
        let _deleteNotif = _.get(this, "props.location.state.deleteSuccess", null);
        this.setState({
            success: _deleteNotif
        });
        
    }

    async getData() {
        let {data, error} = await Province.Get();
        if (error) this.setState({error: error});
        if (data && _.size(data) > 0) {
            this.setState({data: data, error: null});
        } else {
            this.setState({error: "No Record"});
        }
        this.setState({
            dependencyComplete: true
        });
        
    }

    render () {
        return (
            <React.Fragment>
                <BreadCrumbs />
                <Modal 
                    element={this.modalElement} 
                    title={"Create new Province"} 
                    FormBody={ Create }
                    isSuccessful={this.isSuccessful} 
                    size={""} />
                {this.DisplayTable()}
            </React.Fragment>
        );
    }

    DisplayTable () {

        let {data, error, success} = this.state;

        if (_.size(data) > 0 || error) {
            return (
                <React.Fragment>
                    <div className="col-md-12">
                        <Success success={success} /> 
                        <Errors error={error} /> 
                    </div>
                    <ControlBox element={this.modalElement} setFilter={this.setFilter} data={data} />
                    <Table data={data} {...Province.table} />        
                </React.Fragment>
            );
        } else {
            return <div className="loading-screen"><Loader message={"Loading list of provinces..."}/></div>
        }
    }

}

export default List;