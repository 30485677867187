import CoreService from "../services/Core";
import Util from "../services/Util";
import _ from "lodash";

class User {

    constructor () {
        this.path = "/users";
        
        this.table = {
            headers: ["Employee No", "User ID", "Email", "First Name",  "Last Name", "Role"],
            listKeys: ["employeeNo", "userId", "email", "firstName", "lastName", "role", "id"],
            linkPath: "/admin/users",
            linkField: "employeeNo"
        }
    }

    async Show (id) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.get(`${this.path}/${id}`);
            _.set(result, "data", response.data.data );
        } catch (e) {
            _.set(result, "error",  ((e.response)) ? _.get(e, "response.data.error.message") : "Cannot Communicate to the API Server." );
        }
        return result;
    }

    async ValidateUserLogin (user_id, employee_no) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.post(`${this.path}/validate-login`, {userId: user_id, employeeNo: employee_no});
            _.set(result, "data", response.data.data );
        } catch (e) {
            _.set(result, "error",  ((e.response)) ? _.get(e, "response.data.error") : {mesage: "Cannot Communicate to the API Server."} );
        }
        return result;
    }

    // async GetByUserId (user_id) {
    //     let result = { data: null, error: null };
    //     try {
    //         let response = await CoreService.get(`${this.path}/user_id/${user_id}`);
    //         _.set(result, "data", response.data.data );
    //     } catch (e) {
    //         _.set(result, "error",  ((e.response)) ? _.get(e, "response.data.error") : {mesage: "Cannot Communicate to the API Server."} );
    //     }
    //     return result;
    // }

    async Get (raw = false) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.get(this.path);
            _.set(result, "data", (!raw) ? this.reduceData( response.data ) : response.data.data );
        } catch (e) {
            _.set(result, "error",  ((e.response)) ? _.get(e, "response.data.error.message") : "Cannot Communicate to the API Server." );
        }
        return result;
    }

    async Create (params) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.post(this.path, params);
            _.set(result, "data", response.data );
        } catch (e) {
            console.log("Create-> e", e);
            let _error = "Cannot Communicate to the API Server.";
            if (e.response) {
                _error = _.get(e, "response.data.meta.message");
            }
            _.set(result, "error",  _error);
        }
        return result;
    }

    async Update (id, params) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.patch(`${this.path}/${id}`, params);
            _.set(result, "data", response.data.data );
        } catch (e) {
            let _error = "Cannot Communicate to the API Server.";
            if (e.response) {
                _error = _.get(e, "response.data.meta.message");
            }
            _.set(result, "error",  _error);
        }
        return result;
    }

    async ChangePassword (id, newpassword) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.patch(`${this.path}/${id}/password`, {password: newpassword});
            _.set(result, "data", response.data.data );
        } catch (e) {
            let _error = "Cannot Communicate to the API Server.";
            if (e.response) {
                _error = _.get(e, "response.data.meta.message");
            }
            _.set(result, "error",  _error);
        }
        return result;
    }

    async ChangeUserPassword (id, oldpassword, newpassword) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.patch(`${this.path}/${id}/password`, {password: newpassword});
            _.set(result, "data", response.data.data );
        } catch (e) {
            let _error = "Cannot Communicate to the API Server.";
            if (e.response) {
                _error = _.get(e, "response.data.meta.message");
            }
            _.set(result, "error",  _error);
        }
        return result;
    }

    async DeleteUser (id) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.delete(`${this.path}/${id}`);
            _.set(result, "data", response.data.data );
        } catch (e) {
            let _error = "Cannot Communicate to the API Server.";
            if (e.response) {
                _error = _.get(e, "response.data.meta.message");
            }
            _.set(result, "error",  _error);
        }
        return result;
    }




   //------------------------------------------------------------------------ 
    /**helpers*/
    reduceData (result) {
        let newData = _.transform(result.data, (r, n, k) => { 
            _.set(n, "updatedAt", Util.readableDateFormat(n.updatedAt) );            
            _.set(n, "role", n.role );            
            r.push( _.pick(n, _.get(this, "table.listKeys")) );
        }, []);
        return newData;
    }

}

export default new User();