import React, {useState, useEffect} from "react";

import DataModel from "../../../../../models/Province";
import CheckItem from "../check-item";
import {orderBy} from "lodash";
const Province = props => {

    const {checkHandler, checkedItems} = props;        

    const [data, setData] = useState([]); 
    const [error, setError] = useState([]); 

    useEffect(() => {

        async function GetData () {
            let {data = [], error} = await DataModel.GetDropdownList();
            if (data) {
                data.map( i => i.value = i.id);
            }
            setData( orderBy(data, 'label', 'asc') ) ;
            setError(error);
        }
        GetData();

    }, []);
    if (error) {
        return <p>{error}</p>
    }
    if (!data) {
        return <p>Loading checklist...</p>
    }
    return (
        <div className="col-md-12">
            <h5 className="field-label" data-toggle="collapse" href=".check-wrapper.province">
                <a href={"#!"}> Province</a>
            </h5>
            <div className="check-wrapper province collapse out">
              <ul>
                    {
                        data.map( ( d ) => <CheckItem {...d} checkHandler={checkHandler} checkedItems={checkedItems} />)
                    }
              </ul>
            </div>      
        </div>
    );
};

export default Province;