import React, { Component } from "react";
import _ from "lodash";
import {withRouter} from "react-router-dom";
import qs from "querystring";
//common
import Table from "../../common/table/table";
import Errors from "../../common/notif/errors";
import Success from "../../common/notif/success";
import Loader from "../../common/loader";
import BreadCrumbs from "../../layout/breadcrumbs";

//model
import AuditLog from "../../../models/AuditLog";
import User from "../../../models/User";


class List extends Component {

    constructor (props) {
        super(props);

        // this.loadPage = this.loadPage.bind( this );
        this.state = {
            data: [],
            error: null,
            dependencyComplete: false,
            paginationMeta: {},
            currentPage: 0,
            users: []            
        }
    }

    isSuccessful (message, e) {
        this.setState({
            success: message,
            dependencyComplete: false,
            data: []
        });
    }

    // setFilter (data, e) {
    //     this.setState({ 
    //         data: data
    //     });
    // }

    // async componentDidUpdate (prevProps, prevState) {
    //     console.log("prevProps:", prevProps);
    //     console.log("prevState:", prevState);
    //     console.log("currentState:", this.state.currentPage);
    //     const  previousPage = prevState.currentPage;
    //     const {currentPage} = this.state;
    //     if (currentPage != previousPage) {
    //         await this.getData( 10, currentPage);
    //     }

    // }

    async componentDidMount () {
        let {dependencyComplete} = this.state;
        const {location} = this.props;
        let {search} = location;
        search = qs.parse( _.trim(search, "?") );

        if (!dependencyComplete) {
            await this.getData( search.limit || 50, search.page);
        }
    }

    async getData (limit = 10, pageNumber = 1) {
        let params = {
            limit: limit,
            page: pageNumber
        };
        let {data, error, meta} = await AuditLog.Get(params);
        let userResult = await User.Get();        
        let users = {};
        // console.log("userResult:",userResult)
        if (userResult.data) {
            _.each(userResult.data, (u) => {
                users[u.userId] = _.startCase(_.toLower(`${u.firstName} ${u.lastName}`));
            });
            _.each(data, log => {
                log.userFullName = users[ log.actorId ] || log.actorId;
            });
        }
        if (error) this.setState({error: error});
        if (data && _.size(data) > 0) {
            this.setState({
                data: data, 
                error: null,
                paginationMeta: meta
            });
        } else {
            this.setState({error: "No Record"});
        }
        this.setState({
            dependencyComplete: true
        });
        
    }

    // loadPage = data => {
    //     window.location = `/admin/system-logs?limit=10&page=${(data.selected + 1)}`
    //     // const pageNumber = data.selected;
    //     // this.setState({
    //     //     currentPage: pageNumber + 1,
    //     //     dependencyComplete: false
    //     // });

    // };    

    render () {
        let {data, error, success, dependencyComplete, paginationMeta} = this.state;

        if (!dependencyComplete) {
            return <div className="loading-screen"><Loader message={"Loading systems logs..."}/></div>
        }

        return (
            <React.Fragment>
                <BreadCrumbs />
                <div className="col-md-12">
                    <Success success={success} /> 
                    <Errors error={error} /> 
                </div>
                <Table data={data} {...AuditLog.table} />        

                <div className="col-md-12">
                    <Paginate {...paginationMeta} />
                </div>
            </React.Fragment>


        );

    }
}

const Paginate = props => {
    // console.log("props:", props);
    const {totalPages, page} = props;
    const items = [];
    for (let i = 1; i <= totalPages; i++) {
        let isActive = (page === i) ? `active` : null;
        let params = qs.stringify({
            limit: props.limit,
            page: i
        });
        items.push(<li key={i} className={isActive}><a href={`/admin/system-logs?${params}`}>{i}</a></li>)
    }
    return (
        <ul className="pagination"> 
            {items}
        </ul>
    )
};

export default withRouter(List);