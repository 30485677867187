import React, { Component } from "react";
import {withRouter, NavLink} from "react-router-dom";
import _ from "lodash";
//common
import BreadCrumbs from "../../layout/breadcrumbs";
import Errors from "../../common/notif/errors";
import Success from "../../common/notif/success";
import Input from  "../../common/form/input";
import ButtonRow from  "../../layout/button-loader";

//model
import User from "../../../models/User";

class ChangePassword extends Component {

    constructor (props) {
        super(props);
        this.userId =  _.get(this.props, "match.params.id");        
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeCPassword = this.onChangeCPassword.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            password: "",
            c_password: "",
            buttonDisabled: false,
            showLoader: false,
            error: null,
            data: null

        };
    }

    onChangePassword (e) {
        this.setState({
            password: e.target.value
        });
    }

    onChangeCPassword (e) {
        this.setState({
            c_password: e.target.value
        });
    }

    async onSubmit (e) {

        e.preventDefault();

        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null
        });

        let {password, c_password} = this.state;
        if (password !== c_password) {
            this.setState({
                error: "Password & Confirmation did not match",
                buttonDisabled: false,
                showLoader: false
            });
            return false;
        }

        let {data, error} = await User.ChangePassword(this.userId, password);   
        if (data) {
            this.setState({
                data: data,
                success: "Password Updated",
                error: null,
                showLoader: false,
                buttonDisabled: false
            });
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
        }

    }

    render () {
        let {error, success} = this.state;
        return (
            <React.Fragment>
                <BreadCrumbs /> 
                <div className="col-md-12">
                    <Success success={success} />
                    <Errors error={error} /> 
                </div>

                <div className="col-md-8">
                     <div className="card">
                         <div className="header">
                             <div className="col-md-1 col-back">
                                 <NavLink to={"/admin/users/" + this.props.match.params.id} className="btn btn-xs btn-danger btn-simple" exact> &laquo; back </NavLink>
                             </div>
                         </div>
                         <div className="content">
                             <hr />
                             <form onSubmit={this.onSubmit}>
                                 <Form {...this} />
                                 <ButtonRow isEditMode={true} {...this.state} disableEditMode={() => {window.location.href= "/admin/users/" + this.props.match.params.id}}/>
                             </form>
                         </div>
                     </div>                     
                 </div>                 

            </React.Fragment>            
        );
    }

}

const Form = props => {
    return (
        <React.Fragment>
            <h5><span className="ti-lock"></span> Change Password</h5>
            <Input 
                type={"password"}
                label={"New Password"} 
                onChange={props.onChangePassword} 
                value={props.state.password} 
                name={"password"} 
                disabled={false}                        
            />
            <Input 
                type={"password"}
                label={"Confirm New Password"} 
                onChange={props.onChangeCPassword} 
                value={props.state.c_password} 
                name={"c_password"} 
                disabled={false}                        
            />
        </React.Fragment>

    );
}

export default withRouter(ChangePassword);