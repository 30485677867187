import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import AclService from "../../services/AclService";

import _ from "lodash";
//load config
import Navigation from '../../config/navigation';


class Nav extends Component {

    _currentUserRole = null;

    render () {
        this._currentUserRole = AclService.getRole();
        return (
            <React.Fragment>
            <ul className="nav">
                {this.buildNavigation()}

            </ul>
            </React.Fragment>

        )
    }

    buildNavigation () {
        return Navigation.map( (item, i) => {
            let subnavigation = "";
            let currentPage = window.location.pathname;
            let currentRoute = _.nth(_.split(currentPage, "/"), 1);
            let active = (currentRoute === _.trim(item.route, "/")) ? "active" : null;
            
            if ( _.size(item.subNav) > 0) {

                if (_.includes(item.roles, this._currentUserRole) || item.roles === true) {
                    subnavigation = this.buildSubNavigation( item.subNav, i ) ;
                    return (
                        <li className={active} key={item.route}>
                            <NavLink to="#"  data-target={`#${i}`} data-toggle="collapse" className="collapse">
                                <i className={`${item.icon}`}></i><p>{item.label} <b className="caret"></b></p>
                            </NavLink>
                            { subnavigation }
                        </li>
                    );
                } else {
                    return null;
                }
            } else {
                if (_.includes(item.roles, this._currentUserRole) || item.roles === true) {
                    return (
                        <li className={active} key={item.route}>
                            <NavLink exact to={item.route}>
                                <i className={`${item.icon}`}></i><p>{item.label}</p>
                            </NavLink>
                        </li>
                    );
                } else {
                    return null;
                }

            }
        });
    }

    buildSubNavigation (subNavigation, id) {

        const sub = subNavigation.map( (item, i) => {
            // if (_.includes(item.roles, this._currentUserRole) || item.roles === true) {
                return (
                    <li key={item.route}>
                        <NavLink to={item.route}>{item.label}</NavLink>
                    </li>
                );
            // } else {
            //     return null;
            // }
        });
        return (
            <div className="collapse sub-menu" id={id}  aria-expanded="false">
                <ul className="nav">
                    {sub}
                </ul> 
            </div>
        );
  
    }


}

export default Nav;