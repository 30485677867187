import React, {Fragment, useState} from "react";
import GoogleMapReact from "google-map-react";
import MapConfig from "../../../../config/map";
import _ from "lodash";

import Image from "../../../../services/Image";

//common
import Input from "../../../common/form/input";
import Select from "../../../common/form/select";

import ButtonRow from "../../../layout/button-loader";

import BoundarySettings from "./boundary-settings";
import Radius from "./radius";
import TimeField from 'react-simple-timefield';

let PageisEditMode = false;
let brandObject = {};
let searchPlace = null; 
let _marker = null;
const Form = props => {
    const {isEditMode, geofence, geofenceData} = props.state; 
    PageisEditMode = isEditMode;
    let {hasSubType} = props.state;
    let disabledSubtype = (!hasSubType) ? "disabled" : "";
    brandObject = props.state.brandObject;
    searchPlace = props.searchPlace;

    const [mapObj, setMapObj] = useState({});
    const [mapsObj, setMapsObj] = useState({});
    const [markerObj, setMarkerObj] = useState(null);

    if (isEditMode && _marker) {
        _marker.setDraggable(true);
    }

    const onTimeChange = (event, time) => {
        props.onChangeSpTravelTime(time);
    }

    return (
        <Fragment>
            <div className="poi-map-container">
                <MapContainer 
                    geometryHandler={props.geometryHandler} 
                    geometry={props.state.geometry} 
                    isRegister={props.state.isRegister} 
                    mapObjHandler={setMapObj}
                    mapsObjHandler={setMapsObj}
                    markerObjHandler={setMarkerObj}
                    isEditMode={isEditMode}
                     />

                <br/>

                <Radius map={mapObj} maps={mapsObj} marker={markerObj}/>
                

                <BoundarySettings map={mapObj} maps={mapsObj} geofence={geofence} data={geofenceData} geofenceHandler={props.setGeofence} isRegister={props.state.isRegister}  isEditMode={PageisEditMode} />
                

                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-12"><h5><span className="ti-info-alt"></span> Basic & Contact Information</h5></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12"><Input label={"Name"} onChange={props.onChangeName} onBlur={(e) => {
                            }} value={props.state.name} name={"name"} disabled={!isEditMode} /></div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <Input label={"Owner"} required={false} onChange={props.onChangeOwner} value={props.state.owner || ""} name={"name"} disabled={!isEditMode} />                            
                            </div>
                            <div className="col-md-6"><Select label={"PIN Logo"} name={"brand"} value={props.state.brand} onChange={props.onChangeBrand}  options={props.state.brands} disabled={!isEditMode} /></div>
                        </div>

                        <div className="row">
                            <div className="col-md-6"><Select label={"Type"} required={false} name={"type"} value={props.state.type} onChange={props.onChangeType} options={props.state.types} disabled={!isEditMode} /></div>
                            <div className="col-md-6"><Select label={"Sub-Type"} required={false} name={"subType"} value={props.state.subType || ""} onChange={props.onChangeSubType} options={props.state.subTypeList} disabled={disabledSubtype ||!isEditMode}/></div>
                        </div>

                        <div className="row">
                            <div className="col-md-6"><Input label={"Landline"} required={false} onChange={props.onChangeLandline} value={props.state.landLine || ""} name={"landline"}  disabled={!isEditMode} /></div>
                            <div className="col-md-6"><Input label={"Mobile"} required={false} onChange={props.onChangeMobile} value={props.state.mobile || ""} name={"mobile"} disabled={!isEditMode} /></div>
                        </div>
                        <div className="row">
                            <div className="col-md-6"><Input label={"Landline 2"} required={false} onChange={props.onChangeLandline2} value={props.state.landLine2 || ""} name={"landline2"}  disabled={!isEditMode} /></div>
                            <div className="col-md-6"><Input label={"Mobile 2"} required={false} onChange={props.onChangeMobile2} value={props.state.mobile2 || ""} name={"mobile2"} disabled={!isEditMode} /></div>
                        </div>
                        <div className="row">
                            <div className="col-md-6"><Input label={"Landline 3"} required={false} onChange={props.onChangeLandline3} value={props.state.landLine3 || ""} name={"landline3"}  disabled={!isEditMode} /></div>
                            <div className="col-md-6"><Input label={"Mobile 3"} required={false} onChange={props.onChangeMobile3} value={props.state.mobile3 || ""} name={"mobile3"} disabled={!isEditMode} /></div>
                        </div>

                    </div>


                    {/* OTHER HALF OF THE FORM */}
                    <div className="col-md-6">

                        <div className="row">
                            <div className="col-md-12">
                                <h5><span className="ti-map-alt"></span> Location Details</h5>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                            <Input label={"Address"} required={false} onChange={props.onChangeAddress} value={props.state.address} name={"address"} disabled={!isEditMode} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12"><Input required={false} label={"Landmark/s"} onChange={props.onChangeLandMark} value={props.state.landMark} name={"landmark"}  disabled={!isEditMode} /></div>
                            {/*<div className="col-md-6"><Select label={"District"} name={"district"} required={false} value={props.state.district} onChange={props.onChangeDistrict} options={props.state.districts} disabled={!isEditMode} /></div>*/}
                        </div>

                        <div className="row">
                            <div className="col-md-6"><Select label={"Province"} name={"province"} value={props.state.province} onChange={props.onChangeProvince} options={props.state.provinces} disabled={!isEditMode} /></div>
                            <div className="col-md-6"><Select label={"Municipality"} name={"municipality"} value={props.state.municipality} onChange={props.onChangeMunicipality} options={props.state.municipalities} disabled={!isEditMode} /></div>
                        </div>

                        <div className="row">
                            <div className="col-md-12"><Select required={false} label={"Refilling Plant"} name={"poientity"} value={props.state.poientity} onChange={props.onChangePoiEntity} options={props.state.refillingPlants} disabled={!isEditMode} /></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Remarks</label>
                                    <textarea value={props.state.remarks} onChange={props.onChangeRemarks} className="form-control  border-input" name="name" rows="" style={{height: '133px'}} disabled={!isEditMode} ></textarea>
                                </div>
                            </div>                            
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <h5><span className="ti-map-alt"></span> Servicing Plant:</h5>
                            </div>
                        </div>                    
                        <div className="row">

                            <div className="col-md-6">
                                <Input type="number" required={false} label={"Distance in Km"} onChange={props.onChangeSpDistance} value={props.state.spDistance} name={"spDistance"}  disabled={!isEditMode} />
                            </div>
                            <div className="col-md-6">
                                <TimeField 
                                    value={props.state.spTravelTime || "00:00"} 
                                    onChange={onTimeChange}
                                    input={<Input type="text" required={false} label={"Travel Time"}  name={"spTravelTime"}  disabled={!isEditMode} />}
                                    />
                            </div>
                        </div>


                    </div>


                </div>


                <div className="row">

                </div>

                <ButtonRow {...props.state} isEditMode={isEditMode}  disableEditMode={props.disableEditMode} deleteAction={props.deleteAction}/>

            </div>
                   
        </Fragment>
    );
}

const MapContainer = props => {

    // console.log("MapContainer:", props);

    const {mapObjHandler, mapsObjHandler, markerObjHandler} = props;
    let createMapOptions = function (maps) {
        return {
            styles: [{
              "featureType": "all",
              "elementType": "labels",
              "stylers": [{
                "visibility": "#on"
              }]
            }],
            streetViewControl: true     
        }
    }  

    let bootstrapURLKeys = {
        key: MapConfig.apiKey,
        libraries: ['drawing', 'places', 'geometry'].join(',')
    }   

    if (!_.isEmpty(props.geometry)) {
        return (
            <div className="register-map-container">
                <p className="text-center">Click anywhere on the map to pin location..</p>
                <GoogleMapReact 
                    bootstrapURLKeys={bootstrapURLKeys} 
                    defaultCenter={MapConfig.center} 
                    center={props.geometry.coordinates}
                    options={createMapOptions}                    
                    defaultZoom={MapConfig.zoom} 
                    onGoogleApiLoaded={({map, maps}) => {
                        mapObjHandler(map);
                        mapsObjHandler(maps);
                        pinLocation(map, maps, props.geometryHandler, props.geometry || {}, markerObjHandler);  
                    } }
                    yesIWantToUseGoogleMapApiInternals >
                </GoogleMapReact>
            </div>        
        );           
    }
   
    if (props.isRegister) {
        return (
            <div className="register-map-container">
                <p className="text-center">Click anywhere on the map to pin location..</p>
                <GoogleMapReact 
                    bootstrapURLKeys={bootstrapURLKeys} 
                    defaultCenter={MapConfig.center} 
                    defaultZoom={MapConfig.zoom} 
                    options={createMapOptions}       
                    onGoogleApiLoaded={({map, maps}) => {
                        mapObjHandler(map);
                        mapsObjHandler(maps);
                        pinLocation(map, maps, props.geometryHandler, {}, markerObjHandler);  
                    } }
                    yesIWantToUseGoogleMapApiInternals >
                </GoogleMapReact>
            </div>               
        );
    }
    return null;      

}


const pinLocation = (map, maps, geometryHandler, geometry, markerObjHandler = null) => {
    if (searchPlace) {
        searchPlace(map, maps);
    }
    
    let _icon = null;
    if (brandObject) {
        let brandIcon = brandObject.icon;
        if (brandIcon) {
            _icon = {
                url: Image.toUrlPath(brandIcon.filename),
                scaledSize: new maps.Size(brandObject.width, brandObject.height ),
            };
        }
    }

    map.mapTypeControl = true;
    if (!_.isEmpty(geometry)) {
        const coords = {
            lat: _.get(geometry, "coordinates[0]"),
            lng: _.get(geometry, "coordinates[1]"),
        };

        let markerParams = {
            position:coords,
            map:map,
            draggable: (PageisEditMode) ? true : false
            // draggable: true
        }
        if (_icon) {
            _.set(markerParams, "icon", _icon);
        }
        _marker = new maps.Marker(markerParams);
        if (markerObjHandler) {
            markerObjHandler(_marker);
        }
        // console.log("_marker_marker_marker_marker:", _marker);
        map.setCenter(coords)
    }

    //force center

    if (_marker) {
        maps.event.addListener(_marker, 'dragend', function(event) {
            geometryHandler(event.latLng);
        });    
    }

    maps.event.addListener(map, "click", function(event) {

        if (map.POI_MODE) { //temp hack
            return false;
        }
        if (!PageisEditMode) {
            return false;
        }

      if (_marker) {
        _marker.setPosition(event.latLng);
        _marker.setMap( map )
      } else {
        _marker = new maps.Marker({
          position: event.latLng,
          map: map,
          draggable:true
        });
      }

        if (markerObjHandler) {
            markerObjHandler(_marker);
        }

      geometryHandler(event.latLng);
    });



};

export default Form;