import React, { Component, Fragment } from "react";
import Type from "../../../models/Type";
import Error from "../../common/notif/errors";
import _ from "lodash";

// import _ from "lodash";

//view
import View from  "../../views/admin/type/create";

class Create extends Component {

    constructor (props) {
        super(props);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangePinColor = this.onChangePinColor.bind(this);
        this.addSubSelection = this.addSubSelection.bind(this);
        this.removeSubSelection = this.removeSubSelection.bind(this);
        this.showColorPicker = this.showColorPicker.bind(this);
        this.closeColorPicker = this.closeColorPicker.bind(this);
        this.colorPickerChange = this.colorPickerChange.bind(this);
        
      
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            error: null,
            buttonDisabled: false,
            showLoader: false,
            name: "",
            pinColor: "",
            hasSubType: "",
            subType: "",
            subTypeList: [],
            isSuccessful: props.isSuccessful,
            displayColorPicker: false

        };
    }

    onChangeName (e) {
        this.setState({
            name: e.target.value
        });
    }

    colorPickerChange (color) {
        this.setState({
            pinColor: color.hex
        });
    }

    closeColorPicker (color) {
         this.setState({ displayColorPicker: false })
    }

    showColorPicker () {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    }

    onChangePinColor (e) {
        this.setState({
            pinColor: e.target.value
        });
    } 

    addSubSelection (e) {
        if (e.key === "Enter") {
            let tmpSubtype = _.clone(this.state.subTypeList);
            if ( !_.isEmpty(e.target.value) ) {
                tmpSubtype.push(e.target.value);
            }
            e.target.value = "";
            e.preventDefault();
            this.setState({
                subType: "",
                subTypeList: tmpSubtype
            });
        }
    }

    removeSubSelection (e) {
        let tmpSubtype = _.clone(this.state.subTypeList);
        _.pullAt(tmpSubtype, e.currentTarget.value) 
        this.setState({
            subTypeList: tmpSubtype
        });
       
    }

    closeModal () {
        window.$("#type-modal").modal("hide").on("hidden.bs.modal", (e) => {
            this.setState({
                buttonDisabled: false
            });            
        });
    }

    async onSubmit (e) {
        e.preventDefault();
        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null
        });

        let {name, pinColor, subTypeList} = this.state; 
        const params = { name, pinColor, subTypeList}
        _.set(params, "hasSubType", (_.size(subTypeList) > 0) );
        let {data, error} = await Type.Create(params);
        if (data) {
            this.reset();
            this.closeModal();
            this.state.isSuccessful("New Type Added");
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
        }
    }

    reset () {
        this.setState({
            showLoader: false,
            error: null,
            buttonDisabled: false,
            name: "",
            type: "",
            email: "",
            contactNumber: "",
            address: ""
        });
    }

    render () {
        const {error, showLoader} = this.state;
        let hasError = (error) ? <Error error={error} /> : null;
        
        return (
            <Fragment>
                <div>{hasError}</div>
                <View {...this} showLoader={showLoader} />
            </Fragment>
        );
    }


}

export default Create;