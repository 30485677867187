import React, { Component, Fragment } from "react";
import Error from "../../common/notif/errors";
import _ from "lodash";

//model
import Brand from "../../../models/Brand";

//config
import MapConfig from "../../../config/map";

//view
import View from  "../../views/admin/brand/create";

class Create extends Component {

    constructor (props) {
        super(props);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeCode = this.onChangeCode.bind(this);
        this.onChangeIcon = this.onChangeIcon.bind(this);
        this.onChangeWidth = this.onChangeWidth.bind(this);
        this.onChangeHeight = this.onChangeHeight.bind(this);
        this.onChangeFilterEnabled = this.onChangeFilterEnabled.bind(this);        
        this.onChangeToggleBarEnabled = this.onChangeToggleBarEnabled.bind(this);        
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            error: null,
            buttonDisabled: false,
            showLoader: false,
            name: "",
            code: "",
            updatedAt: "",
            createdAt: "",
            icon: "",
            iconUrl: "",
            filterEnabled: true,
            toggleBarEnabled: false,
            width: MapConfig.pin.width,
            height: MapConfig.pin.height,
            isSuccessful: props.isSuccessful
        };
    }

    onChangeIcon (e) {
        let _file = _.first(e.target.files);
        if (_file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({
                    iconUrl: e.target.result,
                    icon: _file
                });
            };
            reader.readAsDataURL(_file);
        }

    }
    onChangeName (e) {
        this.setState({
            name: e.target.value
        });
    }

    onChangeCode (e) {
        this.setState({
            code: e.target.value
        });
    }


    onChangeWidth (e) {
        this.setState({
            width: e.target.value
        });
    }

    onChangeHeight (e) {
        this.setState({
            height: e.target.value
        });
    }   

    onChangeFilterEnabled (checked) {
        this.setState({
            filterEnabled: checked
        });
    }


    onChangeToggleBarEnabled (checked) {
        this.setState({
            toggleBarEnabled: checked
        });
    }



    closeModal () {
        window.$("#brand-modal").modal("hide").on("hidden.bs.modal", (e) => {
            this.setState({
                buttonDisabled: false
            });
        });
    }

    async onSubmit (e) {
        e.preventDefault();
        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null
        });

        let { name, code, height, width, filterEnabled, toggleBarEnabled } = this.state; 
        let {data, error} = await Brand.Create(  {name, code, height, width, filterEnabled, toggleBarEnabled}  );
        if (data) {
            let iconResult = null;
            if (this.state.icon) {
                let formData = new FormData();
                formData.append("icon", this.state.icon);
                iconResult = await Brand.UploadIcon(data.id, formData);
                if (iconResult.error) {
                    this.setState({
                        error: error,
                        buttonDisabled: false,
                        showLoader: false
                    });
                    return false;
                }
            }            
            this.reset();
            this.closeModal();
            this.state.isSuccessful("New Brand Added");
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
        }
    }

    reset () {
        this.setState({
            error: null,
            buttonDisabled: false,
            showLoader: false,
            name: "",
            code: "",
            updatedAt: "",
            createdAt: ""
        });
    }

    render () {
        const {error, showLoader} = this.state;
        let hasError = (error) ? <Error error={error} /> : null;
        
        return (
            <Fragment>
                <div>{hasError}</div>
                <View {...this} showLoader={showLoader} />
            </Fragment>
        );
    }


}

export default Create;