import React, { Component } from "react";
import _ from "lodash";
import {NavLink} from "react-router-dom";


//common
import Table from "../../common/table/table";
import ControlBox from "../../common/table/control-box";
import Errors from "../../common/notif/errors";
import Success from "../../common/notif/success";
import Loader from "../../common/loader";
import BreadCrumbs from "../../layout/breadcrumbs";

//model
import RefillingPlant from "../../../models/RefillingPlant";
    
//misc
// import Create from "./create";

class List extends Component {

    constructor (props) {
        super(props);
        this.isSuccessful = this.isSuccessful.bind( this );
        this.setFilter = this.setFilter.bind( this );

        this.modalElement = "#refillingplant-modal";

        this.state = {
            data: [],
            error: null,
            success: _.get(props, "location.state.success", ""),
            dependencyComplete: false            
        }
    }

    isSuccessful (message, e) {
        this.setState({
            success: message,
            dependencyComplete: false,
            data: []
        });
    }

    setFilter (data, e) {
        this.setState({ 
            data: data
        });
    }

    async componentDidUpdate(prevProps) {
        let {dependencyComplete} = this.state;
        if (!dependencyComplete) {
            await this.getData();
        }
    }    


    async componentDidMount () {
        let {dependencyComplete} = this.state;
        if (!dependencyComplete) {
            await this.getData();
        }

        let _deleteNotif = _.get(this, "props.location.state.deleteSuccess", null);
        this.setState({
            success: _deleteNotif
        });
                
    }
    async getData() {
        let {data, error} = await RefillingPlant.Get();
        let {retryCount} = this.state;
        retryCount++;
        if (error) {
            this.setState({
                error: error,
                retryCount: retryCount
            });
        }
        if (data && _.size(data) > 0) {
            this.setState({ data: data, error: null });
        } else {
            this.setState({ error: "No Record"});
        }
        this.setState({
            dependencyComplete: true
        });

    }

    render () {
        return (
            <React.Fragment>
                <BreadCrumbs />
              {/*  <Modal 
                    element={this.modalElement} 
                    title={"Create new RefillingPlant"} 
                    FormBody={ Create }
                    isSuccessful={this.isSuccessful} 
                    size={"lg"} />*/}
                {this.DisplayTable()}
            </React.Fragment>
        );
    }

    DisplayTable () {

        let {data, error, success} = this.state;
        if (_.size(data) > 0 || error) {
            return (
                <React.Fragment>
                    <div className="col-md-12">
                        <Success success={success} /> 
                        <Errors error={error} /> 
                    </div>

                    <ControlBox setFilter={this.setFilter} data={data}>
                            <div className="col-md-3 col-md-offset-6">
                                <NavLink to="/admin/refilling-plants/register" className="float-right btn btn-info btn-fill" href="" >
                                    <i className="ti-plus"></i> Add New
                                </NavLink>
                            </div>                    
                    </ControlBox>

                    <Table data={data} {...RefillingPlant.table} />        
                </React.Fragment>
            );
        } else {
            return <div className="loading-screen"><Loader message={"Loading list of RefillingPlants..."}/></div>
        }
    }

}

export default List;