import React, { Component, Fragment } from "react";
import _ from "lodash";
import {Redirect} from "react-router-dom";

//common
import BreadCrumbs from "../../layout/breadcrumbs";
import Loader from "../../common/loader";
import Errors from "../../common/notif/errors";
import Success from "../../common/notif/success";
import Table from "../../common/table/table";
import Modal from "../../common/modal";

//model
import Province from "../../../models/Province";
import Municipality from "../../../models/Municipality";

//view
import ShowView from  "../../views/admin/province/show";
//misc
import MunicipalityCreate from "../municipality/create-modal";


class Show extends Component {

    constructor (props) {

        super(props);

        this.modalElement = "#municipality-modal";

        this.provinceId =  _.get(this.props, "match.params.id");
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeCode = this.onChangeCode.bind(this);
        this.deleteAction = this.deleteAction.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.municipalitySuccess = this.municipalitySuccess.bind(this);

        this.state = {
            buttonDisabled: false,
            showLoader: false,
            name: "",
            code: "",
            municipalities: [],
            updatedAt: "",
            createdAt: "",
            isSuccessful: props.isSuccessful,
            error: null,
            success: "",
            successMunicipality: "",
            data: []
        };

    }

    async deleteAction () {
        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null,
            isEditMode: false
        });

        let {data, error} = await Province.Delete(this.provinceId);
        if (data) {
            this.setState({
                redirect: true,
                success: "Province Deleted",
                error: null,
                showLoader: false,
                buttonDisabled: false,
            });
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
        }        
    }    



    municipalitySuccess (message, e) {
        this.setState({
            successMunicipality: message,
            data: []
        });
    }    


    onChangeName (e) {
        this.setState({
            name: e.target.value
        });
    }

    onChangeCode (e) {
        this.setState({
            code: e.target.value
        });
    }

    async componentDidUpdate(prevProps) {
        let {data} = this.state;
        if (_.isEmpty(data) || _.isNil(data) ) {
            await this.getData(this.provinceId);
        }
    }        

    async componentDidMount () {
        let {data} = this.state;
        if (_.isEmpty(data) || _.isNil(data) ) {
            await this.getData(this.provinceId);
        }
        let _deleteNotif = _.get(this, "props.location.state.deleteSuccess", null);
        this.setState({
            success: _deleteNotif
        });
        
    }

    async getData ( id ) {
        let {data, error} = await Province.Show( id );
        if (error) this.setState({error: error});
        if (data && _.size(data) > 0) {
            this.setState({
                data: data,
                name: data.name,
                code: data.code,
                municipalities: data.municipalities,
                createdAt: data.createdAt,
                updatedAt: data.updatedAt
            });
        } else {
            this.setState({error: "No Record"});
        }
    }

    async onSubmit (e) {
        e.preventDefault();
        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null
        });

        let {name, code } = this.state; 
        const params = {
            name: name,
            code: code,
        };
        let {data, error} = await Province.Update(this.provinceId, params);
        if (data) {
            this.setState({
                data: data,
                success: "Province Details Updated",
                error: null,
                showLoader: false,
                buttonDisabled: false,
                updatedAt: data.updatedAt
            });
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
        }
    }    

    render () {
        let {data, error, success, successMunicipality} = this.state;        
        let {redirect = null} = this.state;
        if (redirect) {
            return (<Redirect to = {{
                pathname: '/admin/provinces',
                state: { deleteSuccess: this.state.success} 
            }}/>) 
        }        
        if (_.size(data) > 0 || error) {
            return (
                <React.Fragment>
                    <BreadCrumbs />

                        <Modal 
                            element={this.modalElement} 
                            title={"Create new Municipality"} 
                            FormBody={ <MunicipalityCreate provinceId={this.provinceId} isSuccess={this.municipalitySuccess} />}
                            isSuccessful={this.isSuccessful} 
                            size={""} />                    

                        <div className="col-md-12">
                            <Success success={success} />
                            <Errors error={error} /> 
                        </div>

                        <ShowView {...this}/>


                        <MunicipalityTable 
                            data={ Municipality.reduceData({data: this.state.municipalities} ) } 
                            success={successMunicipality} 
                            editModal={this.editModal} 
                            provinceId={this.provinceId} 
                        />


                </React.Fragment>
            );
        } else {
            return <div className="loading-screen"><Loader /></div>
        }    
    }

}



const MunicipalityTable = props => {
    const mTable = _.clone(Municipality.table);
    _.set(mTable, "linkPath", `/admin/provinces/${props.provinceId}/municipalities`)
    return (    
            <Fragment>

                <div className="header">
                    <div className="col-md-12">

                        <h4>
                            Municipalities &nbsp;
                            <button type="button" className="btn btn-info btn-fill btn-sm" data-toggle="modal" data-target="#municipality-modal" >
                                <i className="ti-plus"></i> Add New
                            </button>
                        </h4>
                        <Success success={props.success} />
                    </div>
                </div>
                <div className="content">
                        <Table data={props.data} {...mTable} editModal={props.editModal}/>        
                </div>
            </Fragment>                     


    );
};



export default Show;