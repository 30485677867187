
import React from "react";
import { NavLink } from "react-router-dom";
import _ from "lodash";


const TableBody = props => {
    return (
        <tbody className={(_.size(props.data) > 0) ? "table-container" : null}>
            <Rows {...props}/>
        </tbody> 
    );            
};

const Rows = props => {
    return props.data.map((row, i) => {
        return  <tr key={i}> 
                    <Columns  row={row} linkField={props.linkField} linkPath={props.linkPath}/>
                </tr>
    });

};

const Columns = ({row, linkField, linkPath})  => {
    return _.keys(row).map((prop, key) => {
        if (prop !== "id") {
            return (prop === linkField) ? <td key={key}> <TableLink props={{link: linkPath, id: row["id"], label: row[prop]}} /> </td> : <td key={key}>{row[prop]}</td>;
        }
        return null;
    });    
};

const TableLink = ({props}) => {
    const {link, id, label} = props;
    return <NavLink to={`${link}/${id}`}>{label}</NavLink>
};

export default TableBody;