import React from "react";
import Input from "../../../../common/form/input";


const Name = props => {
    const {value, handler} = props;

    return (
      <div className="col-md-12">

      <Input 
          label={"Name"} 
          onChange={(e) => {handler(e.target.value)}} 
          value={value}
          name={"name"}
          disabled={false}                        
      />
      </div>
    );
};

export default Name;