import React, { Component } from "react";

import Modal from "../../common/modal";
import Form from "./form";
import Loader from "../../common/loader";
import Tile from "../../../models/Tile";
import {remove, includes} from "lodash";
import DragSortableList from "react-drag-sortable";
import BreadCrumbs from "../../layout/breadcrumbs";

import Errors from "../../common/notif/errors";
import Success from "../../common/notif/success";


class DragAndDropApp extends Component {

  constructor (props) {
    
    super(props);
    this.onSort = this.onSort.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);

    this.state = {
      data: [],
      error:  "",
      success: "",
      dependencyComplete: false,
    }
  }

  onDragStart = (ev, id) => {
    ev.dataTransfer.setData("id", id);
  };

  async onSort (list, e) {
    const orders = {};
    list.forEach( item => {
      orders[item.content.props.id] = (item.rank + 1); //always increase rank by 1 (starts with zero)
    });

    await Tile.UpdateOrder( orders );
  }


  async componentDidMount () {
    const {dependencyComplete} = this.state;

    const {search} = this.props.location;

    if (includes(search, "create")) {
        this.setState({
            success: "New Tile Added."
        });
    }

    if (!dependencyComplete) {
        await this.getData();
    }
  }

  async getData() {
      let {data, error} = await Tile.Get();
      this.setState({
        data: data,
        error: error,
        dependencyComplete: true
      });
  }  

  async deleteHandler(id) {
    const ans = window.confirm("Are you sure you want to delete this tile?");
    if (ans) {
        await Tile.Delete(id);
      const _data = this.state.data;
      remove(_data, {id: id});
      this.setState({
        data: _data,
        success: "Tile Deleted."
      });
    }
    
  }  

  render() {

    const {dependencyComplete, data, success, error} = this.state;

    if (!dependencyComplete) {
      return <Loader />
    }

    const list = [];
    if (data) {
        data.map( (tile, i) => list.push( 
          {
            content: <TilePattern {...tile} key={i} deleteHandler={this.deleteHandler}/>
          }) 
        )

    }

    var placeholder = (
        <div className="place-holder">drop here.</div>
    );

    return (
      <>
          <BreadCrumbs />

          <Modal element="tile-create-modal" title={"Create new Brand"} FormBody={ Form } size={"lg"} />

          <div className="row">
            <div className="col-md-12">
                <Success success={success} /> 
                <Errors error={error} /> 
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <button type="button" className="float-right btn btn-info btn-fill" data-toggle="modal" data-target="#tile-create-modal" >
                <i className="ti-plus"></i> Add New
              </button>
            </div>
          </div>      

         <DragSortableList items={list}  placeholder={placeholder} onSort={this.onSort} type="grid" />

      </>
    );
  }
};


const TilePattern = props => {
    let {brands = []} = props;
  return (
    <div className="item"  key={props.value}>
        <div className="card">
            <div className="content">
                <div className="row">
                    <div className="col-xs-3">
                        <div className="icon-big text-center icon-warning">
                            <i className="ti-location-pin"></i>
                        </div>
                    </div>
                    <div className="col-xs-9">
                        <div className="numbers">
                            <p>{props.name}</p>
                        </div>
                        <br />
                        <h6>Selected Brands: &nbsp; {brands.length}</h6>
                    </div>
                </div>
 
                  <div className="footer">
                    <hr />
                    <div className="stats">
                          <a href={`/admin/tiles/${props.id}`}>Edit</a> 
                          <button href={`#`} onClick={ () => {props.deleteHandler(props.id)} }>Delete</button>
                    </div>
                  </div> 
                            
            </div>
        </div>
    </div>  
  );
};

export default DragAndDropApp;



/**
*
* ref: https://codepen.io/abechiyo/pen/aJdQXm
**/