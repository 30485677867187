import React, { Component } from "react";
import { Switch } from "react-router-dom";
import AuthConfig from "../config/auth";
import { withRouter } from "react-router";

/*Dynamic Components*/
import NotFound from "./layout/notfound";
import Dashboard from "./Dashboard";
import Profile from "./Profile";
import ChangePassword from "./ChangePassword";
import Login from "./Login";
import MapOverview from "./MapOverview";

/*PIN Components*/
import PinList from "./point-of-interest/pin/list";
import PinRegister from "./point-of-interest/pin/register";
import PinShow from "./point-of-interest/pin/show";

/*Refilling Plants Components*/
import RefillingPlantList from "./admin/refilling-plants/list"
import RefillingPlantRegister from "./point-of-interest/refilling-plants/register";
import RefillingPlantShow from "./point-of-interest/refilling-plants/show";

/*Geofence Components*/
import GeofenceList from "./geofence/list";
import GeofenceRegister from "./geofence/register";
import GeofenceShow from "./geofence/show";

import AclRoute from "./AclRoute";

import BrandList from "./admin/brand/list";
import BrandShow from "./admin/brand/show";

import ProvinceList from "./admin/province/list";
import ProvinceShow from "./admin/province/show";


// import MunicipalityList from "./admin/municipality/list";
import MunicipalityShow from "./admin/municipality/show";

import TypeList from "./admin/type/list";
import TypeShow from "./admin/type/show";


import UserList from "./admin/user/list";
import UserShow from "./admin/user/show";
import UserChangePassword from "./admin/user/change-password";

import SystemLogs from "./admin/system-logs/list";
import AuthCallBack from "./auth/callback";

import TileList from "./admin/tile/list";
import TileListShow from "./admin/tile/show";

class Routes extends Component {

    render () {
        return (
            <Switch>

                <AclRoute path="/login" exact component={Login}  roles={"*"} ></AclRoute>
                <AclRoute path="/logout" render={ () => {
                    localStorage.removeItem(AuthConfig.storageKey);
                    localStorage.removeItem(AuthConfig.tokenKey);
                    localStorage.removeItem(AuthConfig.adminKey);
                    localStorage.removeItem(AuthConfig.rbacKey);
                    window.location.href="/login";
                }} roles={"*"} ></AclRoute>

                <AclRoute path="/auth/callback" exact component={ AuthCallBack } roles={"*"}></AclRoute>

                <AclRoute path="/" exact component={ Dashboard } roles={"*"}></AclRoute>
                <AclRoute path="/dashboard" exact component={ Dashboard } roles={"*"} ></AclRoute>
                <AclRoute path="/profile" exact component={ Profile } roles={"*"} ></AclRoute>
                <AclRoute path="/profile/change-password" exact component={ ChangePassword } roles={"*"} ></AclRoute>

                <AclRoute path="/map-overview" roles={["super_admin", "admin", "user"]} roleChecking={"user"} module={"map-overview"} exact component={ MapOverview }  ></AclRoute>

                <AclRoute path="/point-of-interests" exact component={ PinList }  roles={"*"}   ></AclRoute>
                <AclRoute path="/point-of-interests/maps"  roles={["super_admin", "admin", "user"]} exact component={ PinList } ></AclRoute>
                <AclRoute path="/point-of-interests/register" roles={["super_admin", "admin", "user"]} accessLevel={{brand: ["manage"]}} exact component={ PinRegister } ></AclRoute>
                <AclRoute path="/point-of-interests/:id" roles={["super_admin", "admin", "user"]} exact component={ PinShow } ></AclRoute>


                <AclRoute path="/admin/refilling-plants" roles={["super_admin", "admin"]} exact component={ RefillingPlantList } ></AclRoute>
                <AclRoute path="/admin/refilling-plants/register" roles={["super_admin", "admin"]}  exact component={ RefillingPlantRegister } ></AclRoute>
                <AclRoute path="/admin/refilling-plants/:id" roles={["super_admin", "admin"]} exact component={ RefillingPlantShow } ></AclRoute>

{/*                <AclRoute path="/refilling-plants" roles={["super_admin", "admin"]} exact component={ RefillingPlantList } ></AclRoute>
                <AclRoute path="/refilling-plants/maps" roles={["super_admin", "admin", "user"]} module={"refilling-plants"} roleChecking={"user"} exact component={ RefillingPlantList } ></AclRoute>
                <AclRoute path="/refilling-plants/register" roles={["super_admin", "admin", "user"]}  module={"refilling-plants"} roleChecking={"user"} manage={true} exact component={ RefillingPlantRegister } ></AclRoute>
                <AclRoute path="/refilling-plants/:id" roles={["super_admin", "admin", "user"]} module={"refilling-plants"} exact component={ RefillingPlantShow } ></AclRoute>
*/}
                <AclRoute path="/geofence" roles={["super_admin"]} exact component={ GeofenceList }></AclRoute>
                <AclRoute path="/geofence/maps" roles={["super_admin", "user"]} module={"geofence"} roleChecking={"user"} exact component={ GeofenceList } ></AclRoute>
                <AclRoute path="/geofence/register" roles={["super_admin", "user"]} module={"geofence"} roleChecking={"user"} manage={true} exact component={ GeofenceRegister } ></AclRoute>
                <AclRoute path="/geofence/:id" roles={["super_admin", "user"]} module={"geofence"} roleChecking={"user"} manage={true} exact component={ GeofenceShow } ></AclRoute>

                <AclRoute exact path="/admin/brands" roles={["super_admin"]} component={ BrandList } ></AclRoute>
                <AclRoute exact path="/admin/brands/:id" roles={["super_admin"]}  component={ BrandShow } ></AclRoute>

                <AclRoute exact path="/admin/provinces" roles={["super_admin"]}  component={ ProvinceList } ></AclRoute>
                <AclRoute exact path="/admin/provinces/:id" roles={["super_admin"]}  component={ ProvinceShow } ></AclRoute>
                <AclRoute exact path="/admin/provinces/:id/municipalities" roles={["super_admin"]}  component={ ProvinceShow } ></AclRoute>
                <AclRoute exact path="/admin/provinces/:province_id/municipalities/:id" roles={["super_admin"]}  component={ MunicipalityShow } ></AclRoute>

                <AclRoute exact path="/admin/types" roles={["super_admin"]}  component={ TypeList } ></AclRoute>
                <AclRoute exact path="/admin/types/:id" roles={["super_admin"]}  component={ TypeShow } ></AclRoute>

                <AclRoute exact path="/admin/users" roles={["super_admin", "admin"]}  component={ UserList } ></AclRoute>
                <AclRoute exact path="/admin/users/:id" roles={["super_admin", "admin"]}   component={ UserShow } ></AclRoute>
                <AclRoute exact path="/admin/users/:id/change-password" roles={["super_admin"]}   component={ UserChangePassword } ></AclRoute>


                <AclRoute exact path="/admin/tiles" roles={["super_admin"]}   component={ TileList } ></AclRoute>
                <AclRoute exact path="/admin/tiles/:id" roles={["super_admin"]}   component={ TileListShow } ></AclRoute>

                <AclRoute exact path="/admin/system-logs" roles={["super_admin"]}  component={ SystemLogs } ></AclRoute>

                <AclRoute render={(props) => <NotFound {...props} pageTitle="Point of Interest" />}></AclRoute>
            </Switch>
    );
  }
}

export default withRouter(Routes);