import React, { Component, Fragment } from "react";
import BreadCrumbs from "./layout/breadcrumbs";

/*common*/
import Errors from "./common/notif/errors";
import Loader from "./common/loader";

/*config*/
// import DashConfig from "../config/dashboard";

/*models*/
import Tile from "../models/Tile";

/*view*/
import View from "./views/dashboard";

class Dashboard extends Component {

    constructor (props) {
        super(props);
        this.state = {
            items: [],
            dependencyComplete: false,
            dependencyError: false,
            auditLogs: []
        };
    }

    async componentDidMount () {
        let {dependencyComplete} = this.state;
        if (!dependencyComplete) {
            await this.getData();
        }
    }

    async getData(params) {

        let {data, error} = await Tile.GetSummary();

        this.setState({
            dependencyComplete: true,
            dependencyError: error,
            items: data
        });

    }

    render () {

        let {dependencyError, dependencyComplete, items, auditLogs} = this.state;

        if (!dependencyComplete) {
            return <div className="loading-screen"><Loader message={"Loading Dashboard..."}/></div>
        }

        if (dependencyError) {
            return (
                <div className="col-md-12">
                    <Errors error={dependencyError} /> 
                    <p><a href="/dashboard">Click here</a> to try again.</p>
                </div>
            );
        }

        if (dependencyComplete) {
            return (
                <Fragment>

                    <BreadCrumbs />

                    <View items={items} auditLogs={auditLogs} />
        
                </Fragment>
            );
        } 
    }

}

export default Dashboard;