import axios from "axios";
import Exception from "./Exception";
import Util from "./Util";

class AuthApi {

  constructor (authConfig, token,  apiVersion = 'v1') {
    this.config = authConfig;
    this.axios = axios.create({
      baseURL: `${authConfig.baseUrl}${apiVersion}`,
      headers: {
        "X-Requested-By": "Twisted Logix",
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `bearer ${token}`,
      },
      responseType: "json"
    });    
    this.apiVersion = apiVersion;
    this.token = token;
    this.util = new Util(authConfig);
  }


  async EmployeeSearch (keyword) {

    let result = null;
    try {
      result = await this.axios.get(`/employees?keyword=${keyword}`);
    } catch (e) {
      if (e && e.response) {
        throw e.response.data;
      } else {
        throw Exception.authErrorFormat( new Exception.ServerError(e.message) );
      }
    }
    return (result && result.data)  ? result.data : false;
  }

}


export default AuthApi;