import React, {useState} from "react";
import _ from "lodash";
import Input from "../../../common/form/input";
import Loader from "../../../common/loader";
import AuthConfig from "../../../../config/auth";
import AuthSDKApi from "../../../../libs/identity-server/Api";
import AclConfig from  "../../../../config/acl";
import Select from "../../../common/form/select";
import {toLower, upperFirst} from "lodash";
import UserModel from "../../../../models/User";
import ErrorNotif from "../../../common/notif/errors";
import SuccessNotif from "../../../common/notif/success";

const Form = props => {

  const {data} = props;

  const employeeNos = _.map(data, "employeeNo");

  let token = localStorage.getItem( AuthConfig.tokenKey );
  const AuthSDKObj = new AuthSDKApi( AuthConfig, token );

  const onSubmit = async e => {
    e.preventDefault();
    setDisabled(true);

    let result = null;
    try {
      result = await AuthSDKObj.EmployeeSearch(keyword); 
    } catch (e) {
      console.log("e:", e);
    }
    let employees = [];
     _.each(result.data, (i) => {
      if (!_.includes(employeeNos, i.employeeNo)) {
        employees.push(i);
      }
     });
    setEmployees(employees);
    setDisabled(false);
  };


  const [keyword, setKeyword] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [employees, setEmployees] = useState([]);

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="row">

            <div className="col-md-9">
                <Input 
                    label={"Search for Employee"} 
                    name={"keyword"} 
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                    placeHolder="search for employee id or name"
                    required={false}
                    disabled={disabled}
                />
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label>&nbsp;</label>
                <input style={{marginTop: "24px"}} type="submit" className="btn btn-info btn-fill" value="Search" disabled={disabled} />
              </div>
            </div>

          <div className="col-md-12">
            {disabled && <Loader />}
            </div>
        </div>
      </form>

      {
        (employees && employees.length > 0) && <RenderDataResult employees={employees} />
      }
    </>

  );
};

const RenderDataResult = props => {
  const {employees = []} = props;

  const [disableAll, setDisableAll] = useState(false);

  return (
    <ul className="list-unstyled employee-search-result">
    <li>
        <div className="row">
            <div className="col-xs-7 ">
                <h6>Employee</h6>
            </div>

            <div className="col-xs-3 role-select">
                <h6>Role</h6>
            </div>

            <div className="col-xs-1 text-right">
            </div>
        </div>
    </li>      
      { employees.map( (emp, i) => <Employee {...emp} key={i} disableAll={disableAll} disableHandler={setDisableAll} />) }
    </ul>
  );

};

const Employee = props => {
  const {disableAll, disableHandler} = props;

  const [role, setRole] = useState("user");
  const [showLoader, setShowLoader] = useState(false);
  const [disabled, setDisabled] = useState(disableAll);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const InviteUser = async props => {

    // const ans = window.confirm("Are you sure you want to invite this user?");
    // if (ans) {
      
      setShowLoader(true);
      disableHandler(true);

      let params = {
        userId: props.userId,
        employeeNo: props.employeeNo,
        lastName: props.lastName,
        firstName: props.firstName,
        email: props.companyEmail,
        role: role
      };
      let {data, error} = await UserModel.Create(params);
      if (error) {
        setError(error);
      }
      if (data) {
        setSuccess("User Added, Redirecting...");
        setDisabled(true);
        window.location = `/admin/users/${data.data.id}`;
      }
      setShowLoader(false);
    // }

  };

  return (
    <li>
        { error && <ErrorNotif error={error} />}
        { success && <SuccessNotif success={success} />}
        <div className="row">
            <div className="col-xs-7">
                { upperFirst (toLower( props.firstName ) ) } { upperFirst (toLower( props.lastName) ) }
                <br />
                <span className="text-muted"><small>{props.employeeNo}</small></span>
            </div>

            <div className="col-xs-3 role-select">
                <Select 
                    name={"type"}
                    options={AclConfig.roles}
                    value={role}
                    required={false}
                    onChange={ (e) => {setRole(e.target.value)} }
                    disabled={disabled}
                />
            </div>

            <div className="col-xs-2 text-right">
                { showLoader  && <Loader /> } 
                { (!disableAll) ? <button className="btn btn-sm btn-success btn-icon" onClick={ () => InviteUser( props ) } ><i className="fa fa-envelope"></i></button> : <button className="btn btn-sm btn-success btn-icon" onClick={ () => InviteUser( props ) } disabled={disableAll} ><i className="fa fa-check" ></i></button> }
            </div>
        </div>
    </li>    
  );

};

export default Form;