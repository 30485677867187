import React from "react";
import GoogleMapReact from "google-map-react";
import Pins from "./pins";

/*layout*/

/*config*/
import MapConfig from "../../../../config/map";


let _map = null;
const MapContainer = props => {

    const {data, showGeofence, refillingPlants, showRefillingPlant, showRadius, radius, brandTogglePins, toggleVisibleBrands} = props;

    if (_map) {
        if (_map.POLYGON_LIST && _map.POLYGON_LIST.length > 0) _map.POLYGON_LIST.map((p) => p.setVisible(showGeofence) );
        if (_map.REFILLING_PLANT_LIST && _map.REFILLING_PLANT_LIST.length > 0) _map.REFILLING_PLANT_LIST.map((p) => p.setVisible(showRefillingPlant) );

        if (_map.RADIUS_LIST && _map.RADIUS_LIST.length > 0) {
            
            _map.RADIUS_LIST.map((p) => {
                p.setRadius( (radius * 1000) );
                p.setVisible(showRadius) 
                return p;
            })

        };

        if (_map.BRAND_TOGGLE_LIST) {

            //hide all here.      
            for (let brandId in _map.BRAND_TOGGLE_LIST) {
                if (_map.BRAND_TOGGLE_LIST[brandId].length > 0) _map.BRAND_TOGGLE_LIST[brandId].map((p) => p.setVisible(false) );
            }          

            if (toggleVisibleBrands.length > 0) {
                toggleVisibleBrands.forEach( brandId => {
                    if (_map.BRAND_TOGGLE_LIST[brandId].length > 0) _map.BRAND_TOGGLE_LIST[brandId].map((p) => p.setVisible(true) );
                });
            } 
        }


        //  _map.BRAND_TOGGLE_LIST["5cfa1114d07780000662d5a3"].map((p) => p.setVisible(toggleVisibleBrands));

        // console.log("this._map.BRAND_TOGGLE_LIST:", _map.BRAND_TOGGLE_LIST);
    }

    let bootstrapURLKeys = {
        key: MapConfig.apiKey,
        libraries: ['drawing', 'places', 'geometry'].join(',')
    }

    let createMapOptions = function (maps) {
        return {
            styles: [{
              "featureType": "all",
              "elementType": "labels",
              "stylers": [{
                "visibility": "#on"
              }]
            }],
            streetViewControl: true     
        }
    }        

    return (
        <div id="map-container" className="map-container" style={{ height: window.innerHeight, width: '100%' }}>
            <GoogleMapReact 
                bootstrapURLKeys={bootstrapURLKeys} 
                defaultCenter={MapConfig.center} 
                defaultZoom={MapConfig.zoom} 
                options={createMapOptions}
                onGoogleApiLoaded={({map, maps}) => {
                    _map = map;
                    new Pins(map, maps, data, refillingPlants, showRefillingPlant, showRadius, radius, brandTogglePins)    
                }}
                yesIWantToUseGoogleMapApiInternals={true} />
        </div>
    );
   
};

export default MapContainer;