import React, {useEffect, useState} from "react";

import Input from "../../common/form/input";
import Brand from "../../../models/Brand";
import {includes, pull, uniq} from "lodash";

import Tile from "../../../models/Tile";
// import ButtonLoader from "../../layout/button-loader";

const Form = props => {

  const [brandsSelection, setBrandsSelection] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [name, setName] = useState("");
  const [brands, setBrands] = useState([]);

  useEffect( () => {

    const GetBrandList = async () => {
      let {data} = await Brand.GetDropdownList();
      if (data) {
        setBrandsSelection(data);
      }
    };
    GetBrandList(); 
  }, []);


  const onSubmit = async e => {
    e.preventDefault();
    const params = {
      name, brands
    };
    await Tile.Create(params);
    setDisabled(true);
    window.location = "/admin/tiles?created=true";
  };

  return (
    <form onSubmit={onSubmit}>
            <div className="row">
                <div className="col-md-12">
                    <Input 
                        label={"Name"} 
                        name={"name"} 
                        onChange={(e) => {setName(e.target.value)}}
                        value={name}
                    />                
                </div>

                <div className="col-md-12">
                  <label>Category Selection</label>

                  <div className="check-wrapper brand collapse in">
                    <ul>
                          {
                              brandsSelection.map( ( d ) => <CheckItem {...d} checkHandler={setBrands} checkedItems={brands}/>)
                          }
                    </ul>
                  </div>

                </div>

            </div>

            <input type="submit" className="btn btn-info btn-fill" value="Submit"  disabled={disabled}   />
            <br />
            <br />


        </form>     
  );
};

const CheckItem = props => {

  const {value, label, checkHandler, checkedItems} = props;


  const checkItem = (e) => {
    const {checked, value} = e.target;
    let _checkedItems = checkedItems;
    if (checked) {
        _checkedItems.push(value);
    } else {
        _checkedItems = pull(_checkedItems, value);
    }
    checkHandler( uniq(_checkedItems) );    
  }

    return (
      <li key={value} className="form-check">
          <label className="form-check-label">
            <input className="form-check-input" type="checkbox"  onChange={(e) => {checkItem(e)}} checked={( includes(checkedItems, value) )}   value={value}/> {label}
          </label>
      </li>      
    ); 
}

export default Form;