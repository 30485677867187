import React from "react";
import {NavLink} from "react-router-dom";
import RecordMetaData from "./metadata";
import ButtonRow from "./button-loader";

const EditFormContainer = props => {

    const {
        cols = 8, 
        listLink, 
        isEditMode,
        form: Form,
        enableEditMode,
        recordMetaData,
        onSubmit } = props;

    const btnClass = (isEditMode) ? "hide " : null;  
    const columnClass = `col-md-${cols}`;
    return (

        <div className={columnClass}>
             <div className="card">
                 <div className="header">
                 <div className="col-md-1 col-back">
                     <NavLink to={listLink} className="btn btn-xs btn-danger btn-simple" exact> &laquo; back to list </NavLink>
                 </div>
                     <div className="col-md-1 float-right">
                         <button  onClick={enableEditMode} type="button" className={`btn btn-xs btn-danger btn-simple ${btnClass}`}>
                             <i className="ti-pencil"></i> Edit
                         </button>
                     </div>
                 </div>
                 <div className="content">
                     <hr />
                     <form onSubmit={onSubmit}>
                         {Form}
                         <ButtonRow {...props.button} isEditMode={isEditMode}/>
                     <hr />
                     </form>
                     <RecordMetaData 
                        {...recordMetaData}
                     />
                 </div>
             </div>                     
         </div> 

    );

};

export default EditFormContainer;