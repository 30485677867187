module.exports = {
  roles: [
    {
      key: "super_admin",
      value: "super_admin",
      label: "Super Administrator"
    },
    {
      key: "admin",
      value: "admin",
      label: "Administrator"
    },
    {
      key: "user",
      value: "user",
      label: "User"
    }
  ],

  
};