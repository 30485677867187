import React, { Component } from "react";
import logo from "../../assets/logo.png";
import { NavLink } from "react-router-dom";

import Nav from "./nav";

class Sidebar extends Component {

    state = {};

    render () {
        return (
            <div className="sidebar" data-background-color="white" data-active-color="danger">
                <div className="sidebar-wrapper">
                    <div className="logo">
                        <a href="/" className="simple-text">
                            <img src={logo} alt="BIVMC" />
                        </a>
                        <span className="logo-title">Business Mapper & GEO Fencing App</span>
                    </div>
                    
                    <Nav />

                </div>

                <div className="logout-wrapper">

                    <ul className="nav bottom">            
           
                        <li className="active-pro">
                                <NavLink to="/logout"><i className="ti-power-off"></i> <p>&nbsp; Logout</p></NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Sidebar;