import React, {useState, useEffect} from "react";
import {map, each, includes, compact} from "lodash";
import DataModel from "../../../../../models/Brand";
import CheckItem from "../check-item";
import AclService from "../../../../../services/AclService";
import {orderBy} from "lodash";

const Brand = props => {

    let role = AclService.getRole();
    let permittedBrands =map(AclService.getBrandPermissions(), "id");

    const {checkHandler, checkedItems} = props;

    const [data, setData] = useState([]); 
    const [error, setError] = useState([]); 

    useEffect(() => {

        async function GetData () {
            let {data, error} = await DataModel.GetDropdownList( true );

            if (role === "user") { //only allow permitted brands on selected user
                each(data, (brand, i) => {
                    if (!includes(permittedBrands, brand.value)) {
                        delete data[i];
                    }
                });
                data = compact(data);

            }

            

            setData( orderBy(data, 'label', 'asc') ) ;
            setError(error);
        }
        GetData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (error) {
        return <p>{error}</p>
    }
    if (!data) {
        return <p>Loading checklist...</p>
    }
    return (
        <div className="col-md-12">
            <h5 className="field-label" data-toggle="collapse" href=".check-wrapper.brand">
                <a href={"#!"}> Category</a>
            </h5>
            <div className="check-wrapper brand collapse in">
              <ul>
                    {
                        data.map( ( d ) => <CheckItem {...d} checkHandler={checkHandler} checkedItems={checkedItems} />)
                    }
              </ul>
            </div>      
        </div>
    );
};

export default Brand;