import React from "react";
import { TwitterPicker } from 'react-color';
import {Redirect} from "react-router-dom";
import Input from "../../common/form/input";
// import Select from "../../common/form/select";
import MapConfig from "../../../config/map";
import Loader from "../../common/loader";
class RegisterForm extends React.Component {

    constructor (props) {
        super(props);
        this.colorPickerFillColorOnClick = this.colorPickerFillColorOnClick.bind(this);
        this.colorPickerFillColorChange = this.colorPickerFillColorChange.bind(this);

        this.colorPickerStrokeColorOnClick = this.colorPickerStrokeColorOnClick.bind(this);
        this.colorPickerStrokeColorOnChange = this.colorPickerStrokeColorOnChange.bind(this);
        this.deletePolygon = this.deletePolygon.bind(this);
        this.returnToList = this.returnToList.bind(this);

        this.polygonUpdate = props.updatePolygonProps;
        this.removePolygon = props.removePolygon;
        this.deleteAction = props.deleteAction;
        this.onChangeOwner = props.onChangeOwner;
        this.onChangeName = props.onChangeName;

        this.state = {
            fillColor: MapConfig.polygon.fillColor,
            strokeColor: MapConfig.polygon.strokeColor,
            displayColorPickerFillColor: false,
            displayColorPickerStrokeColor: false,

            buttonDisabled: props.state.buttonDisabled,
            owners: props.state.owners,
            owner: props.state.owner,
            name: props.state.name,

            redirectToList: false


        };
    }

    colorPickerFillColorOnClick () {
        this.setState({
            displayColorPickerFillColor: !this.state.displayColorPickerFillColor
        });
    }

    colorPickerFillColorChange (color) {
        this.setState({
            fillColor: color.hex
        });
        this.polygonUpdate({fillColor: color.hex});
    }

    colorPickerStrokeColorOnClick () {
        this.setState({
            displayColorPickerStrokeColor: !this.state.displayColorPickerStrokeColor
        });
    }

    colorPickerStrokeColorOnChange (color) {
        this.setState({
            strokeColor: color.hex
        });
        this.polygonUpdate({strokeColor: color.hex});
    }

    deletePolygon () {
        this.removePolygon();
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            name: nextProps.state.name,
            owner: nextProps.state.owner,
            owners: nextProps.state.owners,
            buttonDisabled: nextProps.state.buttonDisabled,
            showLoader: nextProps.state.showLoader
        });
    }

    returnToList () {
        this.setState({
            redirectToList: true
        });
    }

    render () {
        let {fillColor, strokeColor, displayColorPickerFillColor, displayColorPickerStrokeColor, buttonDisabled, showLoader, redirectToList} = this.state;
        let loader = (showLoader) ? <Loader message={"Saving..."}/> : null;
        if (redirectToList) {
            return <Redirect to="/geofence" />
        }
        return (
            <React.Fragment>
                <br />
                <div className="row">
                    <div className="col-md-6">
                        <h5><span className="ti-info-alt"></span> Basic Information</h5>
                        <Input type={"text"} label={"Name"} name={"name"} onChange={this.onChangeName} value={this.state.name} disabled={false} required={true} />
                        <Input label={"Owner"} required={false} onChange={this.onChangeOwner} value={this.state.owner} name={"owner"} /> 
                        {/*<Select label={"Owner"} name={"owner"} required={false} value={this.state.owner} onChange={this.onChangeOwner} options={this.state.owners}/>*/}
                    </div>

                    <div className="col-md-6">
                        <h5><span className="ti-brush"></span> Color Scheme</h5>
                        <div className="form-group">
                            <label>Fill Color</label>
                            <div className="color-picker" onClick={this.colorPickerFillColorOnClick} >
                                <div className="color" style={{backgroundColor: fillColor}}></div>
                                <div className="color-label">{fillColor}</div>
                                {displayColorPickerFillColor ? <div className="color-label close-btn">[close picker]</div> : null}
                                
                            </div>
                            { 
                                displayColorPickerFillColor ?  
                                    <div className="colorpicker-cover">
                                        <TwitterPicker color={fillColor} disableAlpha={ true } onChange={this.colorPickerFillColorChange}/>
                                    </div>
                                : null 
                            }                    
                        </div>

                        <div className="form-group">
                            <label>Border Color</label>
                            <div className="color-picker" onClick={this.colorPickerStrokeColorOnClick} >
                                <div className="color" style={{backgroundColor: strokeColor}}></div>
                                <div className="color-label">{strokeColor}</div>
                                {displayColorPickerStrokeColor ? <div className="color-label close-btn">[close picker]</div> : null}
                                
                            </div>
                            { 
                                displayColorPickerStrokeColor ?  
                                    <div className="colorpicker-cover">
                                        <TwitterPicker color={fillColor} disableAlpha={ true } onChange={this.colorPickerStrokeColorOnChange}/>
                                    </div>
                                : null 
                            }
                        </div>

                    </div>
                    
                </div>

                {loader}

                <div className="float-right">

                    <button type="submit" className="btn btn-info btn-fill" disabled={buttonDisabled}>
                        <i className="ti-save"></i> Save Geofence
                    </button>
                    &nbsp;&nbsp;
                    <button type="button" className="btn btn-info " disabled={buttonDisabled} onClick={this.returnToList}>
                        Cancel
                    </button>


                </div>
                <RemoveButton {...this} />

                

            </React.Fragment>
             
        );
    }


};
export default RegisterForm;


const RemoveButton = props => {
    let {buttonDisabled} = props.state;
    return (
        <React.Fragment>
            <button type="button" className="btn btn-danger btn-fill" onClick={() => {
                let ans = window.confirm("Are you sure you want to delete this item?");
                return (ans) ? props.deleteAction() : () => {}
            }} disabled={buttonDisabled}  >
                <i className="ti-trash"></i> Delete
            </button>
            &nbsp;        
            <button type="button" className="btn btn-danger btn-fill" onClick={props.deletePolygon}  disabled={buttonDisabled}>
                <i className="ti-reload"></i> Remove & Start Again
            </button>

        </React.Fragment>
    );
};