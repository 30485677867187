import React, { Component } from "react";
import ReactDOM from "react-dom";
import BreadCrumbs from "../layout/breadcrumbs";
import {Redirect} from "react-router-dom";
import _ from "lodash";
import MapConfig from "../../config/map";
import MapContainer from "./list/map";
import Loader from "../common/loader";
import Errors from "../common/notif/errors";
import Success from "../common/notif/success";
import ShowForm from "../views/geofence/form";
import Owner from "../../models/Owner";
import Geofence from "../../models/Geofence";
import Util from "../../services/Util";

let selectedShape;
let drawingManager;
let polygonList = [];

class Show extends Component {

    constructor (props) {
        super(props);

        this.geofenceId =  _.get(this.props, "match.params.id");

        this.updatePolygonProps = this.updatePolygonProps.bind(this);
        this.mapHandler = this.mapHandler.bind(this);
        this.onChangeOwner = this.onChangeOwner.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.deleteAction = this.deleteAction.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.removePolygon = this.removePolygon.bind(this);

        this.state = {

            //on page states
            hasPolygon: false,
            dependencyError: false,
            dependencyComplete: false,
            buttonDisabled: false,
            showLoader: false,
            error: null,
            success: null,

            //field states
            data: [],
            currentGeofence: null,
            owner: "",
            name: "",
            polygonCoords: [],
            fillColor: "",
            strokeColor: "",

            //dropdown states
            owners: [],
            geofences: [],
            redirect: ""
        };

    }


    async deleteAction () {
        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null,
            isEditMode: false
        });

        let {data, error} = await Geofence.Delete(this.geofenceId);
        if (data) {
            this.setState({
                redirect: true,
                success: "Geofence Deleted",
                error: null,
                showLoader: false,
                buttonDisabled: false,
            });
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
        }        
    }        

    onChangeOwner (e) {
        this.setState({
            owner: e.target.value || undefined
        });
    }

    onChangeName (e) {
        this.setState({
            name: e.target.value
        });
    }

    getUpdatedCoords () {
      let len = selectedShape.getPath().getLength();
      let coords = [];
      for (let i = 0; i < len; i++) {
          coords.push(selectedShape.getPath().getAt(i).toUrlValue(5));
      }
      return coords;
    }

    async onSubmit (e) {
      e.preventDefault();
        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null
        });

        let params = {
            owner: this.state.owner || "",
            name: this.state.name,
            polygonCoords: this.getUpdatedCoords(),
            fillColor: this.state.fillColor || MapConfig.polygon.fillColor,
            strokeColor: this.state.strokeColor || MapConfig.polygon.strokeColor
        };

        if (_.isEmpty(params.polygonCoords)) {
            this.setState({
                error: "Please draw fence boundary on the map.",
                buttonDisabled: false,
                showLoader: false
            });
            window.scrollTo(0, 0);                        
            return false;
        }
        let {data, error} = await Geofence.Update(this.geofenceId, params);
        if (data) {
            this.setState({
                success: "Geofence Updated",
                error: null,
                buttonDisabled: false,
                showLoader: false
            });
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
        }
        window.scrollTo(0, 0);                    
    }    


    async componentDidUpdate(prevProps) {
        let {dependencyComplete} = this.state;
        if (!dependencyComplete > 0) {
            await this.getData();
        }
    }


    async componentDidMount () {
        let {dependencyComplete} = this.state;
        if (!dependencyComplete > 0) {
            await this.getData();
            await this.getDropdownValues();
        }
    }

    async getDropdownValues () {
        if ( _.isEmpty(this.state.owners) ) {
            //owners
            let ownerResult = await Owner.GetDropdownList();
            if (ownerResult.error) { 
                this.setState({ dependencyError: ownerResult.error});
            } else {
                this.setState({owners: ownerResult.data});
            }
            this.setState({
                dependencyComplete: true
            });
        }
    }    

    async getData () {
        let {data, error} = await Geofence.Get();

        if (error) this.setState({error: error});
        if (data && _.size(data) > 0) {
            let currentGeofence = _.find(data, {id: this.geofenceId});
            this.setState({
              geofences: data, 
              currentGeofence: currentGeofence, 
              owner: currentGeofence.owner,
              name: currentGeofence.name,
              polygonCoords: currentGeofence.polygonCoords || [],
              fillColor: currentGeofence.fillColor,
              strokeColor: currentGeofence.strokeColor,
              error: null
            });
        } else {
            this.setState({error: "No Record"});
        }
        this.setState({
            dependencyComplete: true
        });
    }

    updatePolygonProps (params) {
        this.setState(params);
        if (!_.isEmpty(selectedShape)) {
            selectedShape.setOptions(params);
        } else {
            let polygonOptions = drawingManager.polygonOptions;
            drawingManager.setOptions({polygonOptions: _.merge(polygonOptions, params)});
        }
    }


    drawingManager (map, maps) {
        map.mapTypeControl = true;
        let self = this;

        if (maps.drawing) {
            drawingManager = new maps.drawing.DrawingManager({
                // drawingMode: maps.drawing.OverlayType.POLYGON,
                drawingControl: false,
                drawingControlOptions: {
                    position: maps.ControlPosition.TOP_CENTER,
                    drawingModes: [
                        maps.drawing.OverlayType.POLYGON,
                    ]
                },
                polygonOptions: {
                    clickable: true,
                    draggable: true,
                    editable: true,
                    fillColor: MapConfig.polygon.fillColor,
                    fillOpacity: 0.5,
                    strokeWeight: MapConfig.polygon.strokeWeight,
                    strokeColor: MapConfig.polygon.strokeColor
                }
            }); 

            drawingManager.setMap(map);

            maps.event.addListener(drawingManager, 'polygoncomplete', function(event) {
                let len = event.getPath().getLength();
                let coords = [];
                for (let i = 0; i < len; i++) {
                    coords.push(event.getPath().getAt(i).toUrlValue(5));
                    self.setState({
                        hasPolygon: true,
                        polygonCoords: coords
                    });
                }
            });

            maps.event.addListener(drawingManager, 'overlaycomplete', function(event) {
                let  all_overlays = [];
                all_overlays.push(event);
                if (event.type !== maps.drawing.OverlayType.MARKER) {
                    drawingManager.setDrawingMode(null);
                    //Write code to select the newly selected object.

                    var newShape = event.overlay;
                    newShape.type = event.type;
                    maps.event.addListener(newShape, 'click', function() {
                        setSelection(newShape);
                    });

                    setSelection(newShape);
                }
            });            

            function setSelection(shape) {
                clearSelection();
                selectedShape = shape;
                shape.setEditable(true);
            }
            function clearSelection() {
                if (selectedShape) {
                    selectedShape.setEditable(false);
                    selectedShape = null;
                }
            }

        }
      
    }

    removePolygon () {

        if (selectedShape) {
            const answer = window.confirm("Are you sure you want to delete the current fence and start all over?");
            if (answer) {
                selectedShape.setMap(null);
                drawingManager.setOptions({drawingMode: this.maps.drawing.OverlayType.POLYGON });
                this.setState({
                    hasPolygon: false
                });
            }
        }

    }

    searchPlace (map, maps) {
        let input = ReactDOM.findDOMNode(this.refs.input);
        this.searchBox = new maps.places.SearchBox(input);
        this.searchBox.addListener('places_changed', this.onPlacesChanged);
    }

    onPlacesChanged = () => {
        let places = this.searchBox.getPlaces();

        let bounds = new this.maps.LatLngBounds();
        places.forEach(function(place) {
            if (!place.geometry) {
              console.log("Returned place contains no geometry");
              return;
            }
            bounds.extend(place.geometry.location);
        });   
        this.map.fitBounds(bounds);   
        this.map.setZoom(MapConfig.geofenceZoom);
    }

    mapHandler (map, maps) {
        this.map = map;
        this.maps = maps;

        if (!maps) {
            return false;
        }

        maps.event.addListener(map, "maptypeid_changed", function (e) {
            const mapType = map.getMapTypeId();
            if (mapType === "satellite" || mapType === "hybrid") {
                _.each(polygonList, (i) => {
                    i.setOptions({ fillOpacity: 0.7, fillColor: "#efefef"});
                });
            } else {
                _.each(polygonList, (i) => {
                    i.setOptions({ fillOpacity: 0.3});
                });
            }
        });

        this.showCurrentFences(map, maps);

        this.drawingManager(map, maps);
        this.searchPlace(map, maps);

    }    

    showCurrentFences (map, maps) {

        let fences = this.state.geofences;

        _.each(fences, (geofence, k) => {

            if (geofence.id === this.state.currentGeofence.id) {
              let coords = Util.convertCoordinates(geofence.polygonCoords);
              let polygon = new maps.Polygon({
                  path: coords,
                  strokeColor: geofence.strokeColor,
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: geofence.fillColor,
                  fillOpacity: 0.3,
                  editable: true,
                  draggable: true
              });
              polygon.setMap(map);
              _.set(geofence, "polygon", polygon);
              polygonList.push(polygon);

              //set the selected polygon
              selectedShape = polygon; 

              //re-center map based on current geofence
              let bounds = new maps.LatLngBounds();

              _.each(polygon.getPath().getArray(), (element) => {
                bounds.extend(element);
              });
              map.fitBounds(bounds);   
              map.setZoom(MapConfig.geofenceZoom);              
            } else {
              let coords = Util.convertCoordinates(geofence.polygonCoords);
              let polygon = new maps.Polygon({
                  path: coords,
                  strokeColor: "#8C8484",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "#8C8484",
                  fillOpacity: 0.3,
                  editable: false
              });

                       

              polygon.setMap(map);

              polygon.addListener("mouseover", () => {
                  console.log("test-hover");
                  console.log(this);
              });

              _.set(geofence, "polygon", polygon);
              polygonList.push(polygon);

            }

        });        
    }

    render () {
        let {dependencyComplete, error, success, redirect} = this.state;
        if (redirect) {
            return (<Redirect to = {{
                pathname: '/geofence',
                state: { updateNotif: success} 
            }}/>) 
        }        
        if (dependencyComplete) {
            return (
                <React.Fragment>
                    <BreadCrumbs />
                    <div className="col-md-12">
                        <Errors error={error} /> 
                        <Success success={success} /> 
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header"><h4 className="title">View / Update Geofence</h4></div>

                            <div className="content">

                                <br />
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <input ref="input" type="text" placeholder="type a place to center the map..."  className="form-control border-input" />
                                            </div>
                                        </div>                                
                                    </div>
                                </div>                                

                                <br />
                                <div className="poi-map-container">
                                    <MapContainer handler={this.mapHandler} data={this.state.geofences} includeDrawing={true}/>
                                </div>

                                <form onSubmit={this.onSubmit}>
                                    <ShowForm {...this}/>
                                </form>    
                                <br />                    
                            </div>
                        </div>
                    </div>
              </React.Fragment>                
            )

        } else {
            return <div className="loading-screen"><Loader message={"Loading Geofences..."}/></div>
        }
    }

};

export default Show;