import { each, join, compact, template, get } from "lodash";
import MapConfig from "../../../../config/map";

import Image from "../../../../services/Image";
import Geofence from "./geofence";
import Radius from "./radius";
import MeasureTool from "measuretool-googlemaps-v3";

/*assets*/
import refillingPlantIcon from "../../../../assets/icon-google-map-10.jpg";


class PoiPins {

  constructor(map, maps, data, refillingPlants, showRefillingPlant, showRadius = false, radius, brandTogglePins = []) {
    this._map = map;
    this._maps = maps;
    this._data = data;
    this._refillingPlants = refillingPlants;

    this._showRadius = showRadius;

    /*map inits*/
    map.mapTypeControl = true;

    this.LoadMeasuringTool();

    this.LoadPins();

    this.LoadRefillingPlants(showRefillingPlant);

    this.LoadBrandTogglePins(brandTogglePins);

  }


  LoadBrandTogglePins(togglePins, visible = false) {

    let brandToggleList = this._map.BRAND_TOGGLE_LIST || {};


    // const geofenceObj = new Geofence(this._map, this._maps);
    // const radiusObj = new Radius(this._map, this._maps);

    each(togglePins, (item, index) => {

      let tmpArray = brandToggleList[item.brandId] || [];

      //setup landline
      item.landlines = this.arrangeLandLines(item);
      item.mobiles = this.arrangeMobiles(item);

      //setup refilling plants
      item.refillingPlants = this.arrangeRefillingPlants(item);
      let infowindow = new this._maps.InfoWindow({
        content: PoiInfoBoxTemplate({ poi: item })
      });

      item.icon = this.arrangeBrandIcon(item.brand);
      let _marker = this.CreateMarker(item, this._map, infowindow);
      _marker.setVisible(visible);

      // geofenceObj.Draw( item.geofence, infowindow ); //draw geofence
      // radiusObj.Draw( item.geometry, this._showRadius );

      tmpArray.push(_marker);
      brandToggleList[item.brandId] = tmpArray;
    });

    this._map.BRAND_TOGGLE_LIST = brandToggleList;

  }

  LoadPins() {

    const geofenceObj = new Geofence(this._map, this._maps);
    const radiusObj = new Radius(this._map, this._maps);

    each(this._data, (item, index) => {

      //setup landline
      item.landlines = this.arrangeLandLines(item);
      item.mobiles = this.arrangeMobiles(item);

      //setup refilling plants
      item.refillingPlants = this.arrangeRefillingPlants(item);
      let infowindow = new this._maps.InfoWindow({
        content: PoiInfoBoxTemplate({ poi: item })
      });

      item.icon = this.arrangeBrandIcon(item.brand);
      this.CreateMarker(item, this._map, infowindow);
      geofenceObj.Draw(item.geofence, infowindow); //draw geofence
      radiusObj.Draw(item.geometry, this._showRadius);

    });

  }

  LoadRefillingPlants(visible) {

    let rfList = this._map.REFILLING_PLANT_LIST || [];

    each(this._refillingPlants, (item, index) => {

      let infowindow = new this._maps.InfoWindow({
        content: RefillingPlantInfoBoxTemplate({ name: item.name, address: item.address, landLine: item.landLine })
      });

      let icon = {
        url: item.iconUrl || refillingPlantIcon,
        // scaledSize: new this._maps.Size( ((item.width || MapConfig.pin.width) + 20), ((item.height || MapConfig.pin.height) + 20) )
        scaledSize: new this._maps.Size((item.width || MapConfig.pin.width), (item.height || MapConfig.pin.height))
      }
      let coords = item.geometry.coordinates;
      let marker = new this._maps.Marker({
        position: new this._maps.LatLng(get(coords, "[0]", null), get(coords, "[1]", null)),
        map: this._map,
        title: item.name,
        icon: icon
      });

      marker.setVisible(visible);

      marker.addListener("mouseover", function () {
        infowindow.open(this._map, marker);
      });

      marker.addListener("mouseout", function () {
        infowindow.close(this._map, marker);
      });

      rfList.push(marker);

      this._map.REFILLING_PLANT_LIST = rfList;

    });

  }

  LoadMeasuringTool() {
    new MeasureTool(this._map, {
      contextMenu: true,
      showSegmentLength: true,
      tooltip: true,
      unit: MeasureTool.UnitTypeId.METRIC // metric, imperial, or nautical
    });
  }

  CreateMarker(item, map, infowindow) {
    let coords = get(item, "geometry.coordinates", []);
    let marker = new this._maps.Marker({
      position: new this._maps.LatLng(coords[0], coords[1]),
      map,
      title: item.name,
      icon: item.icon
    });
    marker.set("id", item.id);
    //marker events
    marker.addListener("click", function () {
      window.location = `/point-of-interests/${this.get("id")}`;
    });

    marker.addListener("mouseover", function () {
      infowindow.open(this._map, marker);
    });

    marker.addListener("mouseout", function () {
      infowindow.close(this._map, marker);
    });

    return marker;

  }

  arrangeLandLines(item) {
    return join(compact([item.landLine, item.landLine2, item.landLine3]), " / ",);
  }
  arrangeMobiles(item) {
    return join(compact([item.mobile, item.mobile2, item.mobile3]), " / ",);
  }
  arrangeRefillingPlants(item) {
    return (item && item.poientity) ? item.poientity.name : null;
  }
  arrangeBrandIcon(brand = {}) {
    let _icon = null;
    let brandIcon = brand && brand.icon ? brand.icon : undefined;
    let pinConfig = MapConfig.pin || {};
    if (brandIcon) {
      _icon = {
        url: Image.toUrlPath(brandIcon.filename),
        scaledSize: new this._maps.Size(brand.width || pinConfig.width, brand.height || pinConfig.height)
      };
    } else {
      _icon = {
        path: `M32 2a20 20 0 0 0-20 20c0 18 20 40 20 40s20-22 20-40A20 20 0 0 0 32 2zm0 28a8 8 0 1 1 8-8 8 8 0 0 1-8 8z`,
        strokeColor: "#adadad",
        strokeWeight: 1,
        fillColor: "#CC0000",
        fillOpacity: 1.0,
        scale: 0.6
      };
    }

    return _icon;
  }


};


const PoiInfoBoxTemplate = template(`<div class="map-info-container">
        <h5 class="title"><%- poi.name %></h5>

        <% if (poi.address) { %>
            <p class="address"><%- poi.address %></p>
        <% } %>         

        <% if (poi.landlines) { %>
            <p class="bold">Landline: <%- poi.landlines %></p>
        <% } %> 

        <% if (poi.mobiles) { %>
            <p class="bold">Mobile: <%- poi.mobiles %></p>
        <% } %>   

        <% if (poi.owner) { %>
            <p class="bold">Owner: <%- poi.owner %></p>
        <% } %>

        <% if (poi.refillingPlants) { %>
          <hr>
            <p class="bold">Servicing Plant: <%- poi.refillingPlants %></p>
        <% } %>   

        <% if (poi.spDistance) { %>
          <% var time = poi.spTravelTime %>
          <% var timeSplit = time.split(":"); %>
            <hr>
            <h6>Plant Delivery Reference</h6>
            <p class="meta">Distance: <%- poi.spDistance %>km</p>
            <% if (timeSplit) { %>
              <p class="meta">Travel Time: <%- timeSplit[0] %>hr/s <%- timeSplit[1] %>mins</p>
            <% } %>
            
        <% } %>  

        <% if (poi.remarks) { %>
            <hr>
            <p class="meta">Remarks: <%- poi.remarks %></p>
        <% } %>        

    </div>`);

const RefillingPlantInfoBoxTemplate = template(`<div class="map-info-container">
        <h6 class="heading">Refilling Plant</h6>    
        <h5 class="title"><%- name %></h5>
        <p class="address"><%- address %></p>
        <p class="phone"><%- landLine %></p>
    </div>`);

export default PoiPins;