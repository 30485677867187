import React from "react";
import GoogleMapReact from "google-map-react";
import MapConfig from "../../../../config/map";
import _ from "lodash";
import Loader from "../../../common/loader";

const MapContainer = props => {
    let bootstrapURLKeys = {
        key: MapConfig.apiKey,
        libraries: ['drawing', 'places', 'geometry'].join(',')
    }   

    if (props.error) {
        return null;
    }

    if (_.size(props.data) > 0) {

        let createMapOptions = function (maps) {
            return {
                styles: [{
                  "featureType": "all",
                  "elementType": "labels",
                  "stylers": [{
                    "visibility": "#on"
                  }]
                }],
                streetViewControl: true     
            }
        }        


            return (
                <div id="map-container" className="map-container" style={{ height: window.innerHeight, width: '100%' }}>
                    <GoogleMapReact 
                        bootstrapURLKeys={bootstrapURLKeys} 
                        defaultCenter={MapConfig.center} 
                        defaultZoom={MapConfig.zoom} 
                        options={createMapOptions}
                        onGoogleApiLoaded={({map, maps}) => props.handler(map, maps)}
                        yesIWantToUseGoogleMapApiInternals={true} />
                </div>
            );
    } else {
        return <div className="loading-screen"><Loader message={"Loading Point of Interests..."}/></div>
    }

};

export default MapContainer;