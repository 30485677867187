import React, {useState, useEffect} from "react";
import CheckItem from "./check-item";
import {remove, includes, each, find, map, compact} from "lodash";
import ErrorNotif from "../../../common/notif/errors";
import BrandModel from "../../../../models/Brand";
import Tile from "./tile";

const Permission = props => {

  const {moduleHandler, brandHandler, tileHandler, data} = props;
  const {permissions} = data;

  let _moduleList = map(permissions.modules, "id") || [];
  let _modulePermission = map(permissions.modules, (module) => {
    if (module.manage) return module.id; 
  });

  let _brandsList = map(permissions.brands, "id") || [];
  let _brandPermission = map(permissions.brands, (brand) => {
    if (brand.manage) return brand.id; 
  });


  const [selectedModules, setSelectedModules] = useState( _moduleList ); //eslint-disable-line no-unused-vars
  const [permittedModules, setPermittedModules] = useState( compact(_modulePermission) ); //eslint-disable-line no-unused-vars
  const [brandError, setBrandError] = useState("");
  const [brandsList, setBrandsList] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState( _brandsList );
  const [permittedBrands, setPermittedBrands] = useState( _brandPermission );

  //------------------------------------------------------------------------------------

  useEffect(() => {

    const GetBrands = async () => {
      const {data, error} = await BrandModel.Get();
      if (error) setBrandError("Error Loading Brands Lists...");
      if (data) setBrandsList(data);
    };

    GetBrands();

  }, []);  

  //------------------------------------------------------------------------------------
  useEffect (() => {
    moduleHandler ( BuildModulePermission() );
    brandHandler ( BuildBrandPermmission() );
  }, [selectedModules, permittedModules, selectedBrands, permittedBrands]); //eslint-disable-line react-hooks/exhaustive-deps

  //------------------------------------------------------------------------------------
  const BuildBrandPermmission = () => {
    let brands = [];
    each(selectedBrands, (id) => {
        brands.push({
            id: id,
            manage: includes(permittedBrands, id)
        });
    });
    return brands;    
  };

  //------------------------------------------------------------------------------------
  const BuildModulePermission = () => {
    let modules = [];
    each(selectedModules, (id) => {
        modules.push({
            id: id,
            manage: includes(permittedModules, id)
        });
    });
    return modules;    
  };

  //------------------------------------------------------------------------------------
  const CheckBrandHandler = e => {
    const {checked, value} = e.target;
    if (checked) {
      setSelectedBrands(selectedBrands => [...selectedBrands, value]);
    } else {
      setSelectedBrands( remove(selectedBrands, (v) => v !== value) );
    }
  }

  //------------------------------------------------------------------------------------ 
  const BrandPermissionHandler = e => {
    const {checked, value} = e.target;
    if (checked) {
      setPermittedBrands(permittedBrands => [...permittedBrands, value]);
    } else {
      setPermittedBrands( remove(permittedBrands, (v) => v !== value) );
    }
  }

  //------------------------------------------------------------------------------------

  return (
      <div className="row" id="permission-checkbox-container">
          <div className="col-md-12">
              <div className="form-group">
                  <p><strong>PERMISSION SETTINGS:</strong></p>
              </div>

              <Tile permittedTiles={permissions.tiles || []} tileHandler={tileHandler}/>

              <hr />

              <p>Brand View Settings:</p>

              {
                brandError && <ErrorNotif error={brandError} />
              }

              {
                (brandsList.length > 0) && (
                  <>
                    <div className="col-md-6"><h6><u>Brand</u></h6></div>
                    <div className="col-md-6"><h6><u>Permission</u></h6></div>
                    {
                      brandsList.map( brand => {
                        let currentBrand = find(data.permissions.brands, {id: brand.id});                      
                        return <CheckItem {...brand} selectionList={data.permissions.brands} key={brand.id} handler={CheckBrandHandler} permissionHandler={BrandPermissionHandler}  current={currentBrand}/>
                      })
                    }
                  </>
                )
              }


          </div>
      </div>       
    )
};


export default Permission;