import React, {useState} from "react";
import {isEmpty} from "lodash";

const PointOfInterestRadius = props => {
  const {marker, maps, map} = props;

  let options = [];
  for (var ctr = 1; ctr <=10; ctr++) {
      options.push(<option value={ctr} key={ctr}>{ctr}</option>)
  }

  const [toggleView, setToggleView] = useState(true);
  const [radiusMarker, setRadiusMarker] = useState(null);
  const [radiusUnit, setRadiusUnit] = useState(5);

  // useEffect(() => {
  //   if (!marker) {
  //     setRadiusMarker(null);
  //   }
  // }, []);
  
  // console.log("maps:", maps);
  if ( isEmpty(map) && isEmpty(maps) ) {
    return null;
  }

  if (radiusMarker && !toggleView) {
    radiusMarker.setVisible(false);
  }

  if (marker && radiusMarker) {
      maps.event.addListener(marker, 'dragend', function(event) {
        radiusMarker.setCenter( event.latLng );
      });    
  }

  // console.log("marker:", marker);
  if (marker && toggleView) {

    const lat = marker.getPosition().lat();
    const lng = marker.getPosition().lng();

    if (radiusMarker) {
      radiusMarker.setVisible(true);
      radiusMarker.setCenter( new maps.LatLng( lat, lng ) );
      radiusMarker.setRadius(radiusUnit * 1000); // * 1000 to convert to km
    } else {
      if (!isEmpty(maps) ) {
        // console.log("maps:", maps);
        let _radiusMarker = new maps.Circle({
          strokeColor: '#fc8403',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#fcba03',
          fillOpacity: 0.35,
          map: map,
          center: new maps.LatLng( lat, lng ),
          radius: (radiusUnit * 1000) // * 1000 to convert to km
        });       
        setRadiusMarker(_radiusMarker);  
      }

    }
 
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="radius-container float-right">
          <input className="form-check-input" defaultChecked={toggleView} id="radius-view" type="checkbox" onChange={(e) => setToggleView(e.target.checked)}/> 
          <label className="form-check-label" htmlFor="radius-view">&nbsp; Radius</label>
          &nbsp;
          <select onChange={(e) => setRadiusUnit(e.target.value)} defaultValue={radiusUnit}>
              {options}
          </select>
        </div>
      </div>
    </div>
  );
};

export default PointOfInterestRadius;