import React, { Component, Fragment } from "react";
import _ from "lodash";
import BreadCrumbs from "../../layout/breadcrumbs";
import RecordMetaData from "../../layout/metadata";
import Errors from "../../common/notif/errors";
import Success from "../../common/notif/success";
import Loader from "../../common/loader";
import {Redirect} from "react-router-dom";

/*Load Models*/
import _PoiEntity from "../../../models/PoiEntity";
import District from "../../../models/District";
import Brand from "../../../models/Brand";
import Type from "../../../models/Type";
import Province from "../../../models/Province";
import Owner from "../../../models/Owner";


/*load services*/
import AclService from "../../../services/AclService";

import PoiList from "./list/poi";



/**/
import Form from "../../views/point-of-interest/refilling-plant/form";
const PoiEntity = new _PoiEntity("refilling plant");


class RefillingPlantShow extends Component {

    _currentUserRole = null;

    constructor (props) {
        super(props);
        this.poiEntityId =  _.get(this.props, "match.params.id");
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeDistrict = this.onChangeDistrict.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeProvince = this.onChangeProvince.bind(this);
        this.onChangeMunicipality = this.onChangeMunicipality.bind(this);
        this.onChangeRemarks = this.onChangeRemarks.bind(this);
        this.geometryHandler = this.geometryHandler.bind(this);
        this.enableEditMode = this.enableEditMode.bind(this);
        this.disableEditMode = this.disableEditMode.bind(this);
        this.onChangeWidth = this.onChangeWidth.bind(this);
        this.onChangeHeight = this.onChangeHeight.bind(this);
        this.onChangeIcon = this.onChangeIcon.bind(this);
        this.deleteAction = this.deleteAction.bind(this);

        this.onSubmit = this.onSubmit.bind(this);


        this._currentUserRole = AclService.getRole();
        if (this._currentUserRole === "user") {
            this._permittedBrands = _.map(AclService.getBrandPermissions(), "id");
            this._brandManageAccess = _.map(_.filter(AclService.getBrandPermissions(), (obj) => {return obj.manage}), "id");        
        }


        this.state = {

            //on page states
            buttonDisabled: false,
            showLoader: false,
            error: null,
            success: _.get(props, "location.state.success", ""),
            dependencyComplete: false,
            typeList: [],
            provinceList: [],
            hasSubType: false,
            subTypeList: [],
            isEditMode: true,

            //dropdown states
            districts: [],
            provinces: [],
            municipalities: [],
            poi: [],

            //field states
            name: "",
            district: "",
            address: "",
            province: "",
            municipality: "",
            remarks: "",
            geometry: {},
            icon: "",
            iconUrl: "",
            width: "",
            height: "",

        };

    }



    async deleteAction () {
        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null,
            isEditMode: true
        });

        let {data, error} = await PoiEntity.Delete(this.poiEntityId);
        if (data) {
            this.setState({
                redirect: true,
                success: "Refilling Plant Deleted",
                error: null,
                showLoader: false,
                buttonDisabled: false,
            });
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
            window.scrollTo(0, 0);            
        }        
    }      


    onChangeIcon (e) {
        let _file = _.first(e.target.files);
        if (_file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({
                    iconUrl: e.target.result,
                    icon: _file
                });
            };
            reader.readAsDataURL(_file);
        }

    }    


    onChangeWidth (e) {
        this.setState({
            width: e.target.value
        });
    }

    onChangeHeight (e) {
        this.setState({
            height: e.target.value
        });
    }       

    onChangeName (e) {
        this.setState({
            name: e.target.value
        });
    }

    geometryHandler (coords) {
        let geometry = {
            "type": "Point",
            "coordinates": [coords.lat(), coords.lng()]
        };
        this.setState({
            geometry: geometry      
        });
    }

    onChangeDistrict (e) {
        this.setState({
            district: e.target.value
        });
    }

    onChangeBrand (e) {
        this.setState({
            brand: e.target.value
        });
    }

    onChangeSubType (e) {
        this.setState({
            subType: e.target.value
        });
    }

    onChangeAddress (e) {
        this.setState({
            address: e.target.value
        });
    }

    onChangeLandMark (e) {
        this.setState({
            landMark: e.target.value
        });
    }

    onChangeOwner (e) {
        this.setState({
            owner: e.target.value
        });
    }

    onChangeRemarks (e) {
        this.setState({
            remarks: e.target.value
        });
    }

    onChangeMobile (e) {
        this.setState({
            mobile: e.target.value
        });
    }

    onChangeLandline (e) {
        this.setState({
            landLine: e.target.value
        });
    }

    enableEditMode () {
        this.setState({
            isEditMode: true
        });
    }

    disableEditMode () {
        this.setState({
            isEditMode: true
        });
    }

    onChangeProvince (e) {
        
        let provinceId = e.target.value;
        let provinceList = this.state.provinceList;
        let selectedProvince = _.find(provinceList, {id: provinceId}) || {};
        let municipalities = [];
        if (_.size(selectedProvince.municipalities) > 0) {
            _.each(selectedProvince.municipalities, (item) => {
                municipalities.push({
                    key: item.id,
                    value: item.id,
                    label: item.name
                });
            } );
        }
        this.setState({
            province: e.target.value,
            municipalities: municipalities
        });
    }

    onChangeMunicipality (e) {
        this.setState({
            municipality: e.target.value
        });
    }

    onChangeType (e) {
        let typeId = e.target.value;
        let typeList = this.state.typeList;
        let selectedType = _.find(typeList, {id: typeId}) || {};
        let hasSubType = false;
        let subTypeList = [];
        if (_.size(selectedType.subTypeList) > 0) {
            hasSubType = true;
            _.each(selectedType.subTypeList, (item) => {
                subTypeList.push({
                    key: item,
                    value: item,
                    label: item
                });
            } );            
        }
        this.setState({
            type: e.target.value,
            hasSubType: hasSubType,
            subTypeList: subTypeList
        });
    }


    async onSubmit (e) {
        e.preventDefault();
        let {geometry} = this.state;
        // if (_.isEmpty(geometry)) {
        //     this.setState({
        //         error: "Please pin a location on the map."
        //     });
        //     return false;
        // } 
        
        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null
        });

        let params = {
            name: this.state.name,
            address: this.state.address,
            district: this.state.district,
            brand: this.state.brand,
            municipality: this.state.municipality,
            province: this.state.province,
            type: this.state.type,
            subType: this.state.subType,
            landMark: this.state.landMark,
            landLine: this.state.landLine,
            owner: this.state.owner,
            mobile: this.state.mobile,
            remarks: this.state.remarks,
            geometry: geometry,
            height: this.state.height,
            width: this.state.width
        };
        
        let {data, error} = await PoiEntity.Update(this.poiEntityId, params);
        if (data) {
            let iconResult = null;
            if (this.state.icon) {
                let formData = new FormData();
                formData.append("icon", this.state.icon);
                iconResult = await PoiEntity.UploadIcon(data.id, formData);
                if (iconResult.error) {
                    this.setState({
                        error: error,
                        buttonDisabled: false,
                        showLoader: false
                    });
                    return false;
                }
            }
            this.setState({
                success: "Refilling Plant Details Updated",
                error: null,
                buttonDisabled: false,
                showLoader: false,
                isEditMode: true,
            });
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });

        }  

        window.scrollTo(0, 0);
        return false;      
            
    }


    async getDropdownValues () {
        //district
        let districtResult = await District.GetDropdownList();
        if (districtResult.error) { 
            this.setState({ dependencyError: districtResult.error});
        } else {
            this.setState({districts: districtResult.data});
        }

        //brand
        let brandResult = await Brand.GetDropdownList();
        if (brandResult.error) { 
            this.setState({ dependencyError: brandResult.error});
        } else {
            this.setState({brands: brandResult.data});
        }

        //type
        let typeResult = await Type.GetDropdownList();
        if (typeResult.error) { 
            this.setState({ dependencyError: typeResult.error});
        } else {
            this.setState({types: typeResult.data, typeList: typeResult.list});
        }

        //provinces
        let provinceResult = await Province.GetDropdown();
        if (provinceResult.error) { 
            this.setState({ dependencyError: provinceResult.error});
        } else {
            this.setState({provinces: provinceResult.data, provinceList: provinceResult.list});
        }

        //owners
        let ownerResult = await Owner.GetDropdownList();
        if (ownerResult.error) { 
            this.setState({ dependencyError: ownerResult.error});
        } else {
            this.setState({owners: ownerResult.data});
        }

        // //poi list
        // let poiResult = await Poi.Get({poientity: this.poiEntityId});
        // if (poiResult.error) { 
        //     this.setState({ dependencyError: poiResult.error});
        // } else {
        //     this.setState({poi: Poi.reduceData( poiResult )});
        // }

        this.setState({
            dependencyComplete: true
        });

    }


    async componentDidMount () {
        let {data} = this.state;
        await this.getDropdownValues();        
        if (_.isEmpty(data) || _.isNil(data) ) {
            await this.getData(this.poiEntityId);
        }
    }

    async getData ( id ) {
        let {data, error} = await PoiEntity.Show( id );
        if (error) this.setState({error: error});
        const provinceId = _.get(data, "province.id");
        this.onChangeProvince({target: {value: provinceId}});
        if (data && _.size(data) > 0) {
            this.setState({
                data: data,
                name: data.name,
                address: data.address,
                district: _.get(data, "district.id"),
                province: _.get(data, "province.id"),
                municipality: _.get(data, "municipality.id"),
                remarks: data.remarks,
                geometry: data.geometry,
                updatedAt: data.updatedAt,
                width: data.width,
                height: data.height,
                iconUrl: data.iconUrl,
                createdAt: data.createdAt
            });
        } else {
            this.setState({error: "No Record"});
        }
    }    

    render () {
        let {dependencyComplete, dependencyError, error, success} = this.state;
        const recordMetaData = {createdAt: this.state.createdAt, updatedAt: this.state.updatedAt};
        let showEdit = true;  //eslint-disable-line no-unused-vars
        if (this._currentUserRole === "user") {
            showEdit = AclService.checkModulePermission("refilling-plants", true);
        }        
        if (dependencyError) {
            return (
                <Fragment>
                    <BreadCrumbs />
                    <div className="col-md-12">
                        <Errors error={dependencyError} /> 
                        <p><a href="/point-of-interests/refilling-plants/register">Click here</a> to try again.</p>
                    </div>
                </Fragment>
            );
        }
        let {redirect = null} = this.state;
        if (redirect) {
            return (<Redirect to = {{
                pathname: '/admin/refilling-plants',
                state: { deleteSuccess: success} 
            }}/>)
        }        
        if (dependencyComplete || error) {
            return (
                <Fragment>
                    <BreadCrumbs />
                    <div className="col-md-12">
                        <Errors error={error} /> 
                        <Success success={success} /> 
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                        
                            <div className="content">
                                <form onSubmit={this.onSubmit}>
                                    <Form {...this}/>
                                </form>    
                                <RecordMetaData {...recordMetaData} />                    

                            </div>
                        </div>

                        <PoiList poiEntityId={this.poiEntityId} />

                                
                    </div>
                    
              </Fragment>
            );
        } else {
            return <div className="loading-screen"><Loader message={"Loading dependencies..."}/></div>
        }
    }
};

export default RefillingPlantShow;