import React, {useEffect, useState} from "react";
import {remove, includes} from "lodash";

import TileModel from "../../../../models/Tile";

const TilePermissions = props => {

  const {permittedTiles = [], tileHandler} = props;

  const [tileList, setTileList] = useState( [] ); //eslint-disable-line no-unused-vars
  const [error, setError] = useState( "" ); //eslint-disable-line no-unused-vars
  const [selectedTiles, setSelectedTiles] = useState( permittedTiles ); //eslint-disable-line no-unused-vars

  //------------------------------------------------------------------------------------
  useEffect(() => {

    const GetTiles = async () => {
      const {data, error} = await TileModel.Get();
      if (error) setError("Error Loading Tile Lists...");
      if (data) setTileList(data);
    };
    
    GetTiles();

  }, []);   

  //------------------------------------------------------------------------------------
  useEffect (() => {
    tileHandler ( selectedTiles );

  }, [selectedTiles]); //eslint-disable-line react-hooks/exhaustive-deps
  //------------------------------------------------------------------------------------

  const CheckTileHandler = e => {
    const {checked, value} = e.target;
    if (checked) {
      setSelectedTiles(selectedTiles => [...selectedTiles, value]);
    } else {
      setSelectedTiles( remove(selectedTiles, (v) => v !== value) );
    }
  }  
  //------------------------------------------------------------------------------------

  if (!tileList) {
    return <span>loading...</span>
  }
  return (
    <>
      <p>Dashboard Settings:</p>

      {
        (tileList.length > 0) && (
          <>
            {
              tileList.map( tile => {
                let currentTile = includes(permittedTiles, tile.id);                      
                return (
                  <div className="row permission-check" key={tile.id}>
                      <div className="col-md-12 item">
                          <input type="checkbox" value={tile.id} id={`check-${tile.id}`} onChange={CheckTileHandler} defaultChecked={currentTile}/>
                          <label htmlFor={`check-${tile.id}`}>&nbsp; {tile.name}</label>
                      </div>
                  </div>                     
                );
              })
            }
          </>
        )
      }  
    </>  
  );
};

export default TilePermissions;