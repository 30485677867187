import CoreService from "../services/Core";
import Util from "../services/Util";
import _ from "lodash";

class Owner {

    constructor () {
        this.path = "/types";
        
        this.table = {
            headers: ["Name", "Sub Selection", "Last Updated", ],
            listKeys: ["name", "subSelection", "updatedAt", "id"],
            linkPath: "/admin/types",
            linkField: "name"
        }
    }

    async GetDropdownList () {
        let {data, error} = await this.Get(true);
        let _data = [];
        _.each(data, (item) => {
            _data.push({
                key: item.id,
                value: item.id,
                label: `${item.name}`,
                subTypes: item.subTypeList
            });
        } );
        return {data: _data, error, list: data};
    }

    async Show (id) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.get(`${this.path}/${id}`);
            _.set(result, "data", response.data.data );
        } catch (e) {
            _.set(result, "error",  ((e.response)) ? _.get(e, "response.data.error.message") : "Cannot Communicate to the API Server." );
        }
        return result;
    }


    async Get (raw = false) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.get(this.path);
            _.set(result, "data", (!raw) ? this.reduceData( response.data ) : response.data.data );
        } catch (e) {
            _.set(result, "error",  ((e.response)) ? _.get(e, "response.data.error.message") : "Cannot Communicate to the API Server." );
        }
        return result;
    }

    async Create (params) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.post(this.path, params);
            _.set(result, "data", this.reduceData( response.data ) );
        } catch (e) {
            let _error = "Cannot Communicate to the API Server.";
            if (e.response) {
                _error = _.get(e, "response.data.meta.message");
            }
            _.set(result, "error",  _error);
        }
        return result;
    }

    async Update (id, params) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.patch(`${this.path}/${id}`, params);
            _.set(result, "data", response.data.data );
        } catch (e) {
            let _error = "Cannot Communicate to the API Server.";
            if (e.response) {
                _error = _.get(e, "response.data.meta.message");
            }
            _.set(result, "error",  _error);
        }
        return result;
    }

    async Delete (id) {
        let result = { data: null, error: null };
        try {
            let response = await CoreService.delete(`${this.path}/${id}`);
            _.set(result, "data", response.data.data );
        } catch (e) {
            let _error = "Cannot Communicate to the API Server.";
            if (e.response) {
                _error = _.get(e, "response.data.meta.message");
            }
            _.set(result, "error",  _error);
        }
        return result;
    }

    




   //------------------------------------------------------------------------ 
    /**helpers*/
    reduceData (result) {
        let newData = _.transform(result.data, (r, n, k) => { 
            _.set(n, "subSelection", _.size(n.subTypeList));
            _.set(n, "updatedAt", Util.readableDateFormat(n.updatedAt) );            
            r.push( _.pick(n, _.get(this, "table.listKeys")) );
        }, []);
        return newData;
    }

}

export default new Owner();