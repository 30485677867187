import React from "react";
// import Brand from "../../../../models/Brand";

const ToggleCategories = props => {

  const {toggleBrands, toggleHandler} = props;

    return (
      <>
        {
          toggleBrands.map((brand, i) => {
            return (
              <span className="check-label-wrapper" key={i} >
                <input className="form-check-input" id={`brand-toggle-${brand.id}`} type="checkbox" onClick={(e) => {toggleHandler(e.target.checked, brand.id)}}/> 
                <label className="form-check-label" htmlFor={`brand-toggle-${brand.id}`}>&nbsp; {brand.name}</label>
                &nbsp;&nbsp;&nbsp;   
              </span>        
            );
          })
        }
      </>

    );
    

};

export default ToggleCategories;