import React, { Component } from "react";
import Sidebar from "./components/layout/sidebar";
import Main from "./components/layout/main";
import Login from "./components/Login";
import AuthConfig from "./config/auth";
import jsonwebtoken from "jsonwebtoken";
import AclService from "./services/AclService";
import { withRouter } from "react-router";


import AuthCallback from "./components/auth/callback";

// import Errors from "./components/common/notif/errors";
//layout
import TopNav from "./components/layout/topnav";

class App extends Component {

    constructor (props) {
        super(props);
        this.authenticated = false;
        let hasLocalStorage = localStorage.getItem(AuthConfig.storageKey);
        if (hasLocalStorage) {
            this.authenticated = true;
        }
        this.callBackPath = "/auth/callback";
    }

    initPermission () {
        let currentPath = window.location.pathname;
        return AclService.checkPagePermission(currentPath);
    }

    render() {

        let {location} = this.props;
        
        if (location.pathname === this.callBackPath) {
            return <AuthCallback />
        }



        if (!this.authenticated) {
            return <Login />
        } else {
            // if ( !this.initPermission() ) {
            //     return (
            //         <React.Fragment>
            //             <div id="login-container">
            //                 <div className="col-md-12">
            //                     <Errors error={"You are not allowed to access this page."} /> 
            //                 </div>
            //             </div>
            //         </React.Fragment>
            //     )
            // }
            const sessionExpired = this.isSessionExpired();
            if (sessionExpired) {
                return window.location.href="/login?session-expired";
            }
            return (
                <React.Fragment>
                    <Sidebar />
                    <div className="main-panel">    
                        { <TopNav /> }
                        <div className="content">
                            <Main />
                        </div>
                    </div>
                </React.Fragment>
            );            
        }

    }

    isSessionExpired () {
        let accessToken = localStorage.getItem(AuthConfig.tokenKey);
        let decoded = jsonwebtoken.decode(accessToken) || {};
        let currentTime = ((Date.parse( new Date() ) / 1000) - 300);
        let isExpired = false;
        if (currentTime > decoded.exp) {
            isExpired = true;
            localStorage.removeItem(AuthConfig.storageKey);
            localStorage.removeItem(AuthConfig.tokenKey);
            localStorage.removeItem(AuthConfig.adminKey);
        }
        return isExpired;
    }
}

export default withRouter(App);