import React, { Fragment } from "react";
import Loader from "../../../common/loader";

import Form from "./form";

const Create = props => {
    //map fns
    props.state.isEditMode = true;
    return (
        <form onSubmit={props.onSubmit}>
            <Fragment>
                <Form {...props}/>
                <hr />
                <ButtonRow 
                    buttonDisabled={props.state.buttonDisabled}
                    showLoader={props.state.showLoader}
                />

            </Fragment>
        </form>
    );
}


const ButtonRow = ({buttonDisabled, showLoader}) => {
    let loader = (showLoader) ? <Loader message={"Saving..."}/> : null;
    return (
        <div className="row">
            <div className="col-md-4">
            </div>
            <div className="col-md-3 text-center">
                {loader}
            </div>
            <div className="col-md-5 text-right">
                <div className="form-group float-right">
                    <Fragment>
                        <input type="submit" className="btn btn-info btn-fill" value="Submit" disabled={buttonDisabled} />
                        &nbsp;
                        <button type="button" className="btn btn-info" data-dismiss="modal" disabled={buttonDisabled} >
                            Close
                        </button>
                    </Fragment>
                </div>            
            </div>        
        </div>        
    );

}

export default Create;