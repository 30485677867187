import React from "react";

const CheckItem = props => {

  const {id, name, handler, permissionHandler, current} = props;

  let defaultChecked = null;
  if (current && current.id === id) {
    defaultChecked = "checked";
  }
  let defaultCheckedManage = null;
  if (current && current.id === id && current.manage) {
    defaultCheckedManage = "checked";
  }
  return (
    <div className="row permission-check">
        <div className="col-md-6 item">
            <input type="checkbox" value={id} id={`check-${id}`} onChange={handler} defaultChecked={defaultChecked}/>
            <label htmlFor={`check-${id}`}>&nbsp; {name}</label>
        </div>
        <div className="col-md-6 item">
            <input type="checkbox" value={id} id={`permission-${id}`} onChange={permissionHandler} defaultChecked={defaultCheckedManage} />
            <label htmlFor={`permission-${id}`}>&nbsp; Can Manage</label>
        </div>
    </div>    
  );

};

export default CheckItem;