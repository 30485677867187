import React from "react";
import Loader from "../../../common/loader";
import UserModel from "../../../../models/User";

const ButtonRow = props => {
    const {showLoader, data} = props;
    //---------------------------
    const DeleteUser = async id => {
        const ans = window.confirm("Are you sure you want to remove this user?");
        if (ans) {
            await UserModel.DeleteUser(id);
            window.location = "/admin/users";
        }
    }

    
    return (
        <div className="row">
            <div className="col-md-4">
                    <button type="button" className="btn btn-danger btn-fill" onClick={ () => DeleteUser(data.id)  }  >
                        <i className="ti-trash"></i> Delete
                    </button>
            </div>
            <div className="col-md-3 text-center">
                {showLoader && <Loader />}
            </div>

            <div className="col-md-5 text-right">
                <div className="form-group">
                    <input type="submit" className="btn btn-info btn-fill" disabled={showLoader} value="Submit"  />
                    &nbsp;&nbsp;
                    <button type="button" className="btn btn-info" disabled={showLoader} onClick={() => {window.location=`/admin/users`}}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>          
    );
};

export default ButtonRow;