import React from "react";
import {includes, pull, uniq} from "lodash";


const CheckItem = props => {
  const {value, label, checkHandler, checkedItems} = props;

  const checkItem = (e) => {
    const {checked, value} = e.target;
    let _checkedItems = checkedItems;
    if (checked) {
        _checkedItems.push(value);
    } else {
        _checkedItems = pull(_checkedItems, value);
    }
    checkHandler( uniq(_checkedItems) );    
  }
  return (
      <li key={value} className="form-check">
          <label className="form-check-label">
            <input className="form-check-input" type="checkbox" onChange={(e) => {checkItem(e)}} checked={( includes(checkedItems, value) )} value={value}/> {label}
          </label>
      </li>    
  );
}

export default CheckItem;