import React, { PureComponent } from "react";
import _ from "lodash";

//layout 
import BreadCrumbs from "../../layout/breadcrumbs";
import Errors from "../../common/notif/errors";
import Loader from "../../common/loader";

//model
import User from "../../../models/User";

//form
import FormWrapper from "./show/wrapper";
import Form from "./show/form";

class Show extends PureComponent {

    constructor (props) {

        super(props);
        this.userId =  _.get(this.props, "match.params.id");
        this.state = {
            error: "",
            data: {}
        }

    }

    async componentDidMount () {
        let {data} = this.state;
        if (_.isEmpty(data) || _.isNil(data) ) {
            await this.getData(this.userId);
        }
    }

    async getData ( id ) {
        let {data, error} = await User.Show( id );

        this.setState({
            data: data,
            error: error
        });
    }

    render () {
        let {data, error} = this.state;
        if (_.size(data) > 0) {
            return (
                <React.Fragment>
                    <BreadCrumbs /> 
                    <div className="col-md-12">
                        <Errors error={error} /> 
                    </div>

                    <FormWrapper listLink={"/admin/users"}>
                        <Form data={data}/>
                    </FormWrapper>

                </React.Fragment>
            );            

        } else {
            return <div className="loading-screen"><Loader  message={"Loading User Info..."}/></div>
        }


    }

}


export default Show;