import React, { Component } from "react";
import _ from "lodash";

import BreadCrumbs from "../../layout/breadcrumbs";

import Loader from "../../common/loader";
import Errors from "../../common/notif/errors";
import Success from "../../common/notif/success";
import ControlBox from "../../common/table/control-box";
import Table from "../../common/table/table";

import Modal from "./invite/modal";
import Form from "./invite/form";



//model
import User from "../../../models/User";

class List extends Component {

    constructor (props) {
        super(props);

        this.setFilter = this.setFilter.bind( this );
        this.isSuccessful = this.isSuccessful.bind( this );
        this.state = {
            data: [],
            error: null,
            success: null,
            dependencyComplete: false
        }

        this.modalElement = "#user-modal";
    }


    setFilter (data, e) {
        this.setState({ 
            data: data
        });
    }

    isSuccessful (message, e) {
        this.setState({
            success: message,
            data: [],
            dependencyComplete: false
        });
    }


    // async componentDidUpdate(prevProps) {
    //     let {dependencyComplete} = this.state;
    //     if (!dependencyComplete) {
    //         await this.getData();
    //     }
    // }    


    async componentDidMount () {
        let {dependencyComplete} = this.state;
        if (!dependencyComplete) {
            await this.getData();
        }
    }

    async getData() {
        let {data, error} = await User.Get();
        if (error) this.setState({error: error});
        if (data && _.size(data) > 0) {
            this.setState({data: data, error: null});
        } else {
            this.setState({error: "No Record"});
        }
        this.setState({
            dependencyComplete: true
        });
        
    }

    render () {
        let {data, error, success} = this.state;

        if (_.size(data) > 0 || error) {
            return (
                <React.Fragment>

                    <BreadCrumbs />

                    <Modal element={this.modalElement} title="Invite new User">
                        <Form data={data}/>
                    </Modal>

                    <div className="col-md-12">
                        <Success success={success} /> 
                        <Errors error={error} /> 
                    </div>
                    <ControlBox element={this.modalElement} setFilter={this.setFilter} data={data} />
                    <Table data={data} {...User.table} />        
                </React.Fragment>
            );
        }   else {
            return <div className="loading-screen"><Loader message={"Loading list of users..."}/></div>
        }  

    }
}

export default List;