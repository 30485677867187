import React, { Component, Fragment } from "react";
import BreadCrumbs from "./layout/breadcrumbs";
import AclService from "../services/AclService";
import AuthConfig from "../config/auth";
import Errors from "./common/notif/errors";
import Success from "./common/notif/success";

/*view*/
import View from "./views/profile";

//model
import User from "../models/User";
import Login from "../models/Login";

class Profile extends Component {

    constructor (props) {
        super(props);

        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
          id: "",
          username: "",
          email: "",
          firstName: "",
          lastName: "",

          error: null,
          success: null,
          dependencyComplete: false,
          showLoader: false,
          buttonDisabled: false
        };

    }

    async onSubmit (e) {
      e.preventDefault();
      this.setState({
          buttonDisabled: true,
          showLoader: true,
          error: null
      });

      let {id, email, lastName, firstName} = this.state; 
      const params = {email, lastName, firstName};
      let {data, error} = await User.Update(id, params);

      //update local storage
      let response = await Login.Token(id);
      //update localstorage
      localStorage.setItem(AuthConfig.tokenKey, response.data.access_token);
      localStorage.setItem(AuthConfig.storageKey, response.data.id_token);
      localStorage.setItem(AuthConfig.adminKey, response.data.admin_token);


      if (data) {
          this.setState({
              data: data,
              success: "User Details Updated",
              error: null,
              showLoader: false,
              buttonDisabled: false,
              updatedAt: data.updatedAt
          });
      }
      if (error) {
          this.setState({
              error: error,
              buttonDisabled: false,
              showLoader: false
          });
      }
    }

    onChangeEmail (e) {
        this.setState({
            email: e.target.value
        });
    }

    onChangeFirstName (e) {
        this.setState({
            firstName: e.target.value
        });
    }

    onChangeLastName (e) {
        this.setState({
            lastName: e.target.value
        });
    }


    async componentDidMount () {
        let {dependencyComplete} = this.state;
        if (!dependencyComplete) {
            await this.getData();
        }
    }    

    async getData() {
        let userProfile = AclService.extractUserInfo();
        this.setState({
            id: userProfile.id,
            username: userProfile.username,
            lastName: userProfile.lastName,
            firstName: userProfile.firstName,
            email: userProfile.email,
            dependencyComplete: true
        });
    }    


    render () {
        let {success, error} = this.state;
        return (
            <Fragment>

                <BreadCrumbs />
                <div className="col-md-12">
                    <Success success={success} />
                    <Errors error={error} /> 
                </div>


                <View {...this} />
    
            </Fragment>
        );
       
    }

}

export default Profile;