import React from "react";
import "./css/style.css";

const ToggleSwitch = props => {
  const {label, value = 0, name, handler, disabled}  = props;
  return (
    <div className="toggle-wrap">
      <label className="toggle">
        <input className="toggle-checkbox" type="checkbox" disabled={disabled} name={name} defaultChecked={value} onChange={(e) => handler(e.target.checked)}/>
        <div className={`toggle-switch ${(disabled) ?  `disabled` : null}`}></div>
        { label && <span className="toggle-label">{label}</span>}
      </label>    
    </div>
  );
};

export default ToggleSwitch;