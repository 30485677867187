import React, {useEffect, useState} from "react";
import Table from "../../../common/table/table";
import Poi from "../../../../models/Poi";
import ElasticSearch from "../../../../models/ElasticSearch";
import Loader from "../../../common/loader";

const PoiList = props => {

  const {poiEntityId} = props;

  const [pois, setPois] = useState([]);
  const [complete, setComplete] = useState(false);

  useEffect(() => {

    const GetPoiList = async () => {
      // //poi list
      let {data} = await ElasticSearch.GetPOIs({poientity: poiEntityId});
      if (data) setPois(data); 
      setComplete(true);
    };

    GetPoiList();//eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  if (!complete) {
    return <Loader message="Loading associated Point of Interests"/>
  }
  // console.log("Poi.reduceData( pois ):", Poi.reduceData( pois ));
  return (
    <>
      <div className="row">
          <div className="col-md-12"><h5><span className="ti-link"></span> Point of Interests linked to this refilling plant</h5></div>
      </div>
      <div className="row">
          <Table data={Poi.reduceData( {data: pois} )} {...Poi.table} />
      </div>
    </>
  );


};

export default PoiList;