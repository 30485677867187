import React from "react";
import {withRouter} from "react-router-dom";
//common
import BreadCrumbs from "../layout/breadcrumbs";
import Errors from "../common/notif/errors";
import Success from "../common/notif/success";
import Input from  "../common/form/input";
import Loader from "../common/loader";


const ChangePassword = props => {

    let {error, success, showLoader, buttonDisabled} = props.state;
    let loader = (showLoader) ? <Loader message={"Saving..."}/> : null;

    return (
        <React.Fragment>
            <BreadCrumbs /> 
            <div className="col-md-12">
                <Success success={success} />
                <Errors error={error} /> 
            </div>

            <div className="col-md-8">
                 <div className="card">
                     <div className="content">

                         <form onSubmit={props.onSubmit}>
                             <Form {...props} />

                              <div className="row">
                                  <div className="col-md-4">
                                  </div>
                                  <div className="col-md-3 text-center">
                                      {loader}
                                  </div>
                                  <div className="col-md-5 text-right">
                                      <div className="form-group">
                                          <input type="submit" className="btn btn-info btn-fill" value="Submit"  disabled={buttonDisabled}   />
                                      </div>
                                  </div>
                              </div>
                         </form>

                     </div>
                 </div>                     
             </div>                 

        </React.Fragment>            
    );
};

const Form = props => {
    return (
        <React.Fragment>
            <h5><span className="ti-lock"></span> Change Password</h5>
            
{/*            <Input 
                type={"password"}
                label={"Old Password"} 
                onChange={props.onChangeOldPassword} 
                value={props.state.oldPassword} 
                name={"password"} 
                disabled={false}                        
            />*/}

            <Input 
                type={"password"}
                label={"New Password"} 
                onChange={props.onChangePassword} 
                value={props.state.password} 
                name={"password"} 
                disabled={false}                        
            />
            <Input 
                type={"password"}
                label={"Confirm New Password"} 
                onChange={props.onChangeConfirmPassword} 
                value={props.state.confirmPassword} 
                name={"c_password"} 
                disabled={false}                        
            />
        </React.Fragment>

    );
}

export default withRouter(ChangePassword);