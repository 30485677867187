
import React, { Fragment } from "react";
import Form from "./form";
import Loader from "../../../common/loader";

const Create = (props) => {
    props.state.isEditMode = true;    
    return (
        <form onSubmit={props.onSubmit}>
            <Form {...props}/>
            <hr />
            <ButtonRow 
                buttonDisabled={props.state.buttonDisabled}
                showLoader={props.state.showLoader}
            />

        </form>
    );
}


const ButtonRow = ({buttonDisabled, showLoader}) => {
    let loader = (showLoader) ? <Loader message={"Saving..."}/> : null;
    return (
        <div className="row">
            <div className="col-md-4">
            </div>
            <div className="col-md-3 text-center">
                {loader}
            </div>
            <div className="col-md-5 text-right">
                <div className="form-group float-right">
                    <Fragment>
                        <input type="submit" className="btn btn-info btn-fill" value="Submit" disabled={buttonDisabled} />
                        &nbsp;
                        <button type="button" className="btn btn-info" data-dismiss="modal" disabled={buttonDisabled} >
                            Close
                        </button>
                    </Fragment>
                </div>            
            </div>        
        </div>        
    );

}
export default Create;