import React, { Component, Fragment } from "react";
import AclService from "../services/AclService";

/*model*/
import User from "../models/User";

/*view*/
import View from "./views/change-password";

class ChangePassword extends Component {

    constructor (props) {
        super(props);

        this.onChangeOldPassword = this.onChangeOldPassword.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {

          oldPassword: "",
          password: "",
          confirmPassword: "",

          error: null,
          success: null,
          dependencyComplete: false,
          showLoader: false,
          buttonDisabled: false
        }
    }


    onChangeOldPassword (e) {
        this.setState({
            oldPassword: e.target.value
        });
    }

    onChangePassword (e) {
        this.setState({
            password: e.target.value
        });
    }

    onChangeConfirmPassword (e) {
        this.setState({
            confirmPassword: e.target.value
        });
    }
    

    async onSubmit (e) {

        e.preventDefault();

        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null
        });

        let {password, confirmPassword} = this.state;
        if (password !== confirmPassword) {
            this.setState({
                error: "Password & Confirmation did not match",
                buttonDisabled: false,
                showLoader: false
            });
            return false;
        }

        let userProfile = AclService.extractUserInfo();

        let {data, error} = await User.ChangePassword(userProfile.id, password);   
        if (data) {
            this.setState({
                data: data,
                success: "Password Updated",
                error: null,
                showLoader: false,
                buttonDisabled: false
            });
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
        }

    }    

    render () {
    return (
        <Fragment>

            <View {...this} />

        </Fragment>
    );
       
    }
}

export default ChangePassword;