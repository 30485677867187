import React from "react";

const Modal = props => {
    const {children} = props;
    return (
        <div className="modal fade" id="filter-modal" data-modal="on">
            <div className="modal-dialog  modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Search & Filter</h5>
                        <button type="button" className="close" data-dismiss="modal">
                          <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        {children}
                    </div> 
                </div>
            </div>
        </div>  
    );

};

export default Modal;