import React, {useState} from "react";
import Input from "../../../common/form/input";
import Select from "../../../common/form/select";
import AclConfig from "../../../../config/acl";
import Permission from "./permission";
import Button from "./button";
import {withRouter} from "react-router";
import SuccessNotif from "../../../common/notif/success";
import ErrorNotif from "../../../common/notif/errors";

//models
import UserModel from "../../../../models/User";

const Form = props => {
    
  const {data, match} = props;

  const userId = match.params.id;

  const [selectedRole, setSelectedRole] = useState(data.role);
  const [showPermissionSetup, setShowPermissionSetup] = useState( !!(data.role === "user") );
  const [modulePermission, setModulePermission] = useState();
  const [brandPermission, setBrandPermission] = useState([]);
  const [tilePermission, setTilePermission] = useState([]);
    
  //page state  
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  // console.log("\n");
  // console.log("\n");
  // console.log("tilePermission:", tilePermission);  
  // console.log("\n");
  // console.log("\n");

  const OnSubmit = async e => {
    e.preventDefault();
    setShowLoader( true );
    let params = {
        role: selectedRole,
        permissions: {
            modules: modulePermission,
            brands: brandPermission,
            tiles: tilePermission
        }
    };
    let {data, error} = await UserModel.Update(userId, params);
    if (error) {
        setErrorMessage(error);
    }
    if (data) {
        setSuccess("User details updated!");
    }
    setShowLoader( false );
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });        

  }

  const ChangeRole = e => {
    const {value} = e.target;
    setShowPermissionSetup( !(value !== "user") );
    setSelectedRole(value);
  }

  return (
    <form onSubmit={OnSubmit}>

        { success && <SuccessNotif success={success} /> }
        { errorMessage && <ErrorNotif error={errorMessage} /> }

        <div className="row">
            <div className="col-md-6">
                <Input 
                    label={"Employee No."} 
                    name={"employeeNo"} 
                    value={data.employeeNo}
                    disabled={true}
                    required={false}
                />
            </div>
            <div className="col-md-6">
                <Input 
                    label={"Email"} 
                    type={"email"}
                    value={data.email}
                    name={"email"} 
                    disabled={true}
                    required={false}
                />                         
            </div>  
        </div>  

        <div className="row">
            <div className="col-md-6">
                <Input 
                    label={"Last Name"} 
                    name={"lastName"} 
                    value={data.lastName}
                    disabled={true}
                    required={false}
                />
            </div>
            <div className="col-md-6">
                <Input 
                    label={"First Name"} 
                    name={"firstName"} 
                    value={data.firstName}
                    disabled={true}
                    required={false}
                />                       
            </div>  
        </div>  

        <div className="row">
            <div className="col-md-12">
                <Select 
                    label={"Role"}
                    name={"type"}
                    options={AclConfig.roles}
                    onChange={(e) => ChangeRole(e)}
                    value={selectedRole}
                />
            </div>
        </div>  

        {
            showPermissionSetup && <Permission moduleHandler={setModulePermission} brandHandler={setBrandPermission} tileHandler={setTilePermission} data={data} />
        }      
        <br /> 
        <br /> 
        <Button showLoader={showLoader} data={data} />
    </form>
  );
};


export default withRouter(Form);

