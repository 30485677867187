import React, {Component} from "react";
import _ from "lodash";
import MapContainer from "./list/map";
import {Redirect} from "react-router-dom";
import Errors from "../common/notif/errors";
import Success from "../common/notif/success";
import Loader from "../common/loader";
// import GeofenceItemList from "./list/item-list";
import Geofence from "../../models/Geofence";

import Util from "../../services/Util";

let polygonList = [];
class List extends Component {
    constructor (props) {
        super(props);
        this.mapHandler = this.mapHandler.bind(this);
        this.state = {
            data: [],
            dependencyComplete: false,            
            dependencyError: "",
            geofenceRedirectId: ""
        };

    }  

    async componentDidMount () {
        let {dependencyComplete} = this.state;
        if (!dependencyComplete > 0) {
            await this.getData();
        }

        let updateNotif = _.get(this, "props.location.state.updateNotif", null);
        this.setState({
            success: updateNotif
        });        
    }

    async getData () {
        let {data, error} = await Geofence.Get();
        if (error) this.setState({error: error});
        if (data && _.size(data) > 0) {
            this.setState({data: data, error: null});
        } else {
            this.setState({error: "No Record"});
        }    
        this.setState({
            dependencyComplete: true
        });

    }

    clickPolygon (id) {
        this.setState({
            geofenceRedirectId: id
        });
    }


    mapHandler (map, maps) {
        map.mapTypeControl = true;
        let fences = this.state.data;
        let self = this;

        maps.event.addListener(map, "maptypeid_changed", function (e) {
            const mapType = map.getMapTypeId();
            if (mapType === "satellite" || mapType === "hybrid") {
                _.each(polygonList, (i) => {
                    i.setOptions({ fillOpacity: 0.7});
                });
            } else {
                _.each(polygonList, (i) => {
                    i.setOptions({ fillOpacity: 0.3});
                });
            }
        });

        _.each(fences, (geofence, k) => {
            let coords = Util.convertCoordinates(geofence.polygonCoords);
            let polygon = new maps.Polygon({
                path: coords,
                strokeColor: geofence.strokeColor,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: geofence.fillColor,
                fillOpacity: 0.3,
                editable: false
            });

            if (maps.geometry) {
                let area = maps.geometry.spherical.computeArea(polygon.getPath());
                _.set(geofence, "area", `${(area / 1e+6).toFixed(2)} sqkm`);
            }

            polygon.setMap(map)

            polygonList.push(polygon);
            _.set(geofence, "polygon", polygon);
            
            let infowindow = new maps.InfoWindow({
                content: PolygonInfoTemplate({name: geofence.name, owner: geofence.owner || "Unassigned" })
            });

            polygon.set("id", geofence.id);
            polygon.addListener("click", function() {
                self.clickPolygon(this.get("id"));
            });

            polygon.addListener("mouseover", function(event) {
                infowindow.setPosition(event.latLng);                
                infowindow.open(map);
            });

            polygon.addListener("mouseout", function () {
              infowindow.close(map);
            });

        });

        this.setState({
            data: fences
        });

    }

    render () {

        let {dependencyComplete, data, error, success, geofenceRedirectId} = this.state;

        if (geofenceRedirectId) {
            return <Redirect to={`/geofence/${geofenceRedirectId}`} />
        }
        if (dependencyComplete) {
            return (
                <React.Fragment>
                    <div className="col-md-12">
                        <Success success={success} /> 
                        <Errors error={error} /> 
                        { (error || success) ? <br /> : null}
                    </div>                
                    <div className="col-md-12">
                        <div id="poi-container">
                            <div className="poi-map-container">

                                <MapContainer handler={this.mapHandler} data={data}/>

                            </div>

{/*                            <div className="poi-list-container">
                                <GeofenceItemList data={data || []}/>
                            </div>
*/}
                        </div>
                    </div>
                </React.Fragment>            
            )            
        } else {
            return <div className="loading-screen"><Loader message={"Loading Geofences..."}/></div>
        }      

    }
}

export default List;

const PolygonInfoTemplate = _.template(`<div class="map-info-container">
        <h5 class="title"><%- name %></h5>
        <p class="address"><b>Owner: </b><%- owner %></p>
    </div>`);
