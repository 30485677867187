import CoreService from "../services/Core";
import _ from "lodash";
import qs from "querystring";

class ElasticSearch {

    constructor () {
        this.path = "/es";
    }

    async GetPOIs (params) {
        let result = { data: null, error: null };
        let queryParams = qs.stringify(params);
        try {
            let response = await CoreService.get(`${this.path}/point-of-interests?${queryParams}`);
            _.set(result, "data",  response.data.data );
        } catch (e) {
            _.set(result, "error",  ((e.response)) ? _.get(e, "response.data.error.message") : "Cannot Communicate to the API Server." );
        }
        return result;
    }

};

export default new ElasticSearch();