// let encryptor = require("simple-encryptor")(process.env.ENCRYPT_KEY);
import Encryptor from "simple-encryptor";

class Util {

  constructor (AuthConfig) {
    const {encryptKey} = AuthConfig;
    this.encryptor = new Encryptor( encryptKey );
  }

  //base64 decode
  decode (str) {
    return  JSON.parse(new Buffer(str, "base64").toString("ascii"));    
  } 

  //base64 encode
  encode (str) {
    return new Buffer(str).toString("base64");
  }

  encrypt (str) {
    return this.encryptor.encrypt(str);
  }

  decrypt (str) {
    return this.encryptor.decrypt(str);
  }

}

export default Util;