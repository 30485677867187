import ServiceConfig from '../config/services';

class Image {

  baseUrl = null;

  constructor () {
    this.baseUrl = ServiceConfig.images.url;
  }

  toUrlPath (imagePath) {
    return `${this.baseUrl}/${imagePath}`;
  }


}

export default new Image();