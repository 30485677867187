import React from "react";

/**
*
* @label of the input field
* @name of the input field
* @required | bool  |   defaults to true
* @onChange | fn
* @value    | fn
**/
// const Input = ({type="text", disabled="", label, name, placeHolder, required=true, onChange=()=>{}, value}) => {
//     let className=`form-control border-input`;
//     return (
//         <div className="form-group">
//             {
//                 label ? <label>{label} {(required) ? "*" : null}</label> : null
//             }
//             <input 
//                 className={className}
//                 type={type} 
//                 name={name} 
//                 required={ (required) ? "required" : null }
//                 onChange={ onChange } 
//                 value={ value } 
//                 disabled={ disabled } 
//                 placeholder={ placeHolder } 

//             />
//         </div>

//     );
// };

const Input = props => {
    // ({type="text", disabled="", label, name, placeHolder, required=true, onChange=()=>{}, value}) 
    let {type, disabled, label, name, placeHolder, required = true, onChange, value, onBlur} = props;
    let {accept} = props;

    let className=`form-control border-input`;
    return (
        <div className="form-group">
            {
                label ? <label>{label} {(required) ? "*" : null}</label> : null
            }
            <input 
                className={className}
                type={type} 
                name={name} 
                required={ (required) ? "required" : null }
                onChange={ onChange } 
                value={ value } 
                disabled={ disabled } 
                placeholder={ placeHolder } 
                onBlur={ onBlur }
                accept={accept}
            />
        </div>

    );
};


export default Input;