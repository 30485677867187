import axios from "axios";
import qs from "qs";
import Exception from "./Exception";
import Util from "./Util";

class Auth {

  constructor (authConfig) {
    this.config = authConfig;
    this.axios = axios.create({
      baseURL: authConfig.baseUrl,
      headers: {
        "X-Requested-By": "Twisted Logix",
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      responseType: "json"
    });    
    this.util = new Util(authConfig);
  }

  BuildLoginUrl () {
    const {baseUrl, appId, redirectUri} = this.config;
    let path = "oauth/authorize";
    let params = {
      scope: "openid",
      response_type: "code",
      client_id: appId,
      redirect_uri: redirectUri
    };
    return `${baseUrl}${path}?${qs.stringify(params)}`;
  }

  BuildTokenUrl () {
    const {baseUrl} = this.config;
    let path = "oauth/token";
    return `${baseUrl}${path}`;
  }   

  async AuthCodeToAccessToken (code) {
    const {redirectUri} = this.config;

    let tokenEndpoint = this.BuildTokenUrl();
    let authHeader = this.util.encode( `${this.config.appId}:${this.config.appSecret}:${code}`);

    authHeader = this.util.encrypt(authHeader);

    let bodyParams = {
      grant_type: "authorization_code",
      redirect_uri: redirectUri,
      code: code
    };
    const axiosParams = {
      headers: {
        "x-authorization-encrypt": true,
        "authorization": `Basic ${authHeader}`
      }
    };

    let result = null;
    try {
      result = await this.axios.post(tokenEndpoint, bodyParams, axiosParams);
    } catch (e) {
      if (e && e.response) {
        throw e.response.data;
      } else {
        throw Exception.authErrorFormat( new Exception.ServerError(e.message) );
      }
    }

    return (result && result.data)  ? result.data : false;

  }    


  /* helper methods */
  UserInfo (rbac) {
    return this.util.decrypt(rbac);
  }


}


export default Auth;