import React, { Component, Fragment } from "react";
import {Redirect} from "react-router-dom";
import ReactDOM from "react-dom";
import _ from "lodash";
import BreadCrumbs from "../../layout/breadcrumbs";
import Errors from "../../common/notif/errors";
import Loader from "../../common/loader";

/*Load Models*/
import Poi from "../../../models/Poi";
import _PoiEntity from "../../../models/PoiEntity";
import District from "../../../models/District";
import Brand from "../../../models/Brand";
import Type from "../../../models/Type";
import Province from "../../../models/Province";
import PoiGeofence from "../../../models/PoiGeofence";
// import Owner from "../../../models/Owner";

/**/
import Form from "../../views/point-of-interest/pin/form";

import AclService from "../../../services/AclService";

let RefillingPlant = new _PoiEntity("refilling plant");

class PointOfInterest extends Component {

    _currentUserRole = null;
    _permittedBrands = null;

    constructor (props) {
        super(props);

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeDistrict = this.onChangeDistrict.bind(this);
        this.onChangeBrand = this.onChangeBrand.bind(this);
        this.onChangeType = this.onChangeType.bind(this);
        this.onChangeSubType = this.onChangeSubType.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeProvince = this.onChangeProvince.bind(this);
        this.onChangeMunicipality = this.onChangeMunicipality.bind(this);
        this.onChangeLandMark = this.onChangeLandMark.bind(this);
        this.onChangeOwner = this.onChangeOwner.bind(this);
        this.onChangeRemarks = this.onChangeRemarks.bind(this);
        this.onChangeLandline = this.onChangeLandline.bind(this);
        this.onChangeLandline2 = this.onChangeLandline2.bind(this);
        this.onChangeLandline3 = this.onChangeLandline3.bind(this);
        this.onChangeMobile = this.onChangeMobile.bind(this);
        this.onChangeMobile2 = this.onChangeMobile2.bind(this);
        this.onChangeMobile3 = this.onChangeMobile3.bind(this);
        this.onChangePoiEntity = this.onChangePoiEntity.bind(this);
        this.geometryHandler = this.geometryHandler.bind(this);
        this.setGeofence = this.setGeofence.bind(this);

        this.onChangeSpTravelTime = this.onChangeSpTravelTime.bind(this);
        this.onChangeSpDistance = this.onChangeSpDistance.bind(this);

        this.searchPlace = this.searchPlace.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this._currentUserRole = AclService.getRole();
        if (this._currentUserRole === "user") {
            this._permittedBrands = _.map(AclService.getBrandPermissions(), "id");
        }

        this.state = {

            //on page states
            buttonDisabled: false,
            showLoader: false,
            error: null,
            success: null,
            dependencyComplete: false,
            typeList: [],
            provinceList: [],
            hasSubType: false,
            subTypeList: [],
            isEditMode: true,
            isRegister: true,

            //dropdown states
            districts: [],
            brands: [],
            types: [],
            provinces: [],
            municipalities: [],
            // owners: [],
            refillingPlants: [],

            //field states
            name: "",
            district: "",
            brand: "",
            type: "",
            subType: "",
            address: "",
            province: "",
            owner: "",
            municipality: "",
            landMark: "",
            landLine: "",
            landLine2: "",
            landLine3: "",
            mobile: "",
            mobile2: "",
            mobile3: "",
            remarks: "",
            geometry: {},
            poientity: "",
            geofence: {},
            spDistance: "",
            spTravelTime: ""
            
        };

    }

    onChangeName (e) {
        this.setState({
            name: e.target.value
        });
    }

    geometryHandler (coords) {
        let geometry = {
            "type": "Point",
            "coordinates": [coords.lat(), coords.lng()]
        };

        this.setState({
            geometry: geometry      
        });
    }

    onChangeDistrict (e) {
        this.setState({
            district: e.target.value
        });
    }

    onChangeBrand (e) {
        this.setState({
            brand: e.target.value
        });
    }

    onChangeSubType (e) {
        this.setState({
            subType: e.target.value
        });
    }

    onChangeAddress (e) {
        this.setState({
            address: e.target.value
        });
    }

    onChangeLandMark (e) {
        this.setState({
            landMark: e.target.value
        });
    }

    onChangeOwner (e) {
        this.setState({
            owner: e.target.value
        });
    }

    onChangeRemarks (e) {
        this.setState({
            remarks: e.target.value
        });
    }

    onChangeMobile (e) {
        this.setState({
            mobile: e.target.value
        });
    }
    onChangeMobile2 (e) {
        this.setState({
            mobile2: e.target.value
        });
    }
    onChangeMobile3 (e) {
        this.setState({
            mobile3: e.target.value
        });
    }

    onChangeLandline (e) {
        this.setState({
            landLine: e.target.value
        });
    }

    onChangeLandline2 (e) {
        this.setState({
            landLine2: e.target.value
        });
    }

    onChangeLandline3 (e) {
        this.setState({
            landLine3: e.target.value
        });
    }

    onChangePoiEntity (e) {
        this.setState({
            poientity: e.target.value
        });
    }

    onChangeSpDistance (e) {
        this.setState({
            spDistance: e.target.value
        });
    }

    onChangeSpTravelTime (time) {
        // const {value} = e.target;
        // let timeSplit = value.split(":");
        // if (timeSplit[0] > 12) {
        //     timeSplit[0] = `0${(timeSplit[0] - 12)}`;
        // }
        // timeSplit = timeSplit.join(":");
        this.setState({
            spTravelTime: time
        });
    }

    onChangeProvince (e) {
        let provinceId = e.target.value;
        let provinceList = this.state.provinceList;
        let selectedProvince = _.find(provinceList, {id: provinceId}) || {};
        let municipalities = [];
        if (_.size(selectedProvince.municipalities) > 0) {
            _.each(selectedProvince.municipalities, (item) => {
                municipalities.push({
                    key: item.id,
                    value: item.id,
                    label: item.name
                });
            } );
        }
        this.setState({
            province: e.target.value,
            municipalities: municipalities
        });
    }

    onChangeMunicipality (e) {
        this.setState({
            municipality: e.target.value
        });
    }

    setGeofence (gValues) {
        this.setState({
            geofence: gValues
        });
    }

    onChangeType (e) {
        let typeId = e.target.value;
        let typeList = this.state.typeList;
        let selectedType = _.find(typeList, {id: typeId}) || {};
        let hasSubType = false;
        let subTypeList = [];
        if (_.size(selectedType.subTypeList) > 0) {
            hasSubType = true;
            _.each(selectedType.subTypeList, (item) => {
                subTypeList.push({
                    key: item,
                    value: item,
                    label: item
                });
            } );            
        }
        this.setState({
            type: e.target.value,
            hasSubType: hasSubType,
            subTypeList: subTypeList
        });
    }

    async onSubmit (e) {
        e.preventDefault();
        let {geometry, geofence} = this.state;

        if (_.isEmpty(geometry)) {
            // this.setState({
            //     error: "Please pin a location on the map."
            // });
            alert("Please pin a location on the map.");
            return false;
        } 
        
        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null
        });

        let params = {
            name: this.state.name,
            address: this.state.address,
            district: this.state.district,
            brand: this.state.brand,
            municipality: this.state.municipality,
            province: this.state.province,
            type: this.state.type,
            subType: this.state.subType,
            landMark: this.state.landMark,
            landLine: this.state.landLine,
            landLine2: this.state.landLine2,
            landLine3: this.state.landLine3,
            owner: this.state.owner,
            mobile: this.state.mobile,
            mobile2: this.state.mobile2,
            mobile3: this.state.mobile3,
            remarks: this.state.remarks,
            geometry: geometry,
            poientity: this.state.poientity,
            spDistance: this.state.spDistance,
            spTravelTime: this.state.spTravelTime
        };


        let {data, error} = await Poi.Create(params);
        if (data) {
            geofence.poiId = data.data.id;
            await PoiGeofence.Create(geofence);
            this.setState({success: "New Point of Interest Registered"});
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
            window.scrollTo(0, 0);
        }        

    }


    async getDropdownValues () {
        //district
        let districtResult = await District.GetDropdownList();
        if (districtResult.error) { 
            this.setState({ dependencyError: districtResult.error});
        } else {
            this.setState({districts: districtResult.data});
        }

        //brand
        let brandResult = await Brand.GetDropdownList();
        if (brandResult.error) { 
            this.setState({ dependencyError: brandResult.error});
        } else {
            let brandList = brandResult.data;

            // limit dropdown choice to permitted brands if role = user
            if (this._currentUserRole === "user") {
                let brandManageAccess = _.map(_.filter(AclService.getBrandPermissions(), (obj) => {return obj.manage}), "id");
                brandList = _.filter(brandList, (obj) => {
                    return (_.includes(brandManageAccess, obj.value)) ? obj : null;
                });
            }
            this.setState({brands: brandList});
        }


        //type
        let typeResult = await Type.GetDropdownList();
        if (typeResult.error) { 
            this.setState({ dependencyError: typeResult.error});
        } else {
            this.setState({types: typeResult.data, typeList: typeResult.list});
        }

        //provinces
        let provinceResult = await Province.GetDropdown();
        if (provinceResult.error) { 
            this.setState({ dependencyError: provinceResult.error});
        } else {
            this.setState({provinces: provinceResult.data, provinceList: provinceResult.list});
        }

        // //owners
        // let ownerResult = await Owner.GetDropdownList();
        // if (ownerResult.error) { 
        //     this.setState({ dependencyError: ownerResult.error});
        // } else {
        //     this.setState({owners: ownerResult.data});
        // }

        //refilling plants
        let refillingPlantResult = await RefillingPlant.GetDropdownList();
        if (refillingPlantResult.error) { 
            this.setState({dependencyError: refillingPlantResult.error});
        } else {
            this.setState({refillingPlants: refillingPlantResult.data});
        }

        this.setState({
            dependencyComplete: true
        });

    }


    searchPlace (map, maps) {
        let input = ReactDOM.findDOMNode(this.refs.input);
        if (maps.places) {
            this.searchBox = new maps.places.SearchBox(input);
            this.searchBox.addListener('places_changed', () => {
                let places = this.searchBox.getPlaces();

                let bounds = new maps.LatLngBounds();
                places.forEach(function(place) {
                    if (!place.geometry) {
                      console.log("Returned place contains no geometry");
                      return;
                    }
                    bounds.extend(place.geometry.location);
                });   
                map.fitBounds(bounds);
                map.setZoom(17.4);                
            });
        }
    }

    async componentDidMount () {
        // check if role === user and if permittedBrands is empty, throw error
    
        if (this._currentUserRole === "user") {
            if (_.isEmpty(this._permittedBrands)) {
                this.setState({
                    permissionError: "No Brand is associated with yout account, contact your administrator.",
                    dependencyComplete: true
                });
                return false;
            }  
            let brandManageAccess = _.map(_.filter(AclService.getBrandPermissions(), (obj) => {return obj.manage}), "id");        
            if (_.isEmpty(brandManageAccess)) {
                this.setState({
                    permissionError: "Your account cannot `manage` any brand. contact your administrator",
                    dependencyComplete: true
                });
                return false;
            }      
        }
        await this.getDropdownValues();
    }

    render () {
        let {dependencyComplete, dependencyError, error, success, permissionError} = this.state;

        if (dependencyError) {
            return (
                <Fragment>
                    <BreadCrumbs />
                    <div className="col-md-12">
                        <Errors error={dependencyError} /> 
                        <p><a href="/point-of-interests/pins/register">Click here</a> to try again.</p>
                    </div>
                </Fragment>
            );
        }
        if (success) {
            return (<Redirect to = {{
                pathname: '/point-of-interests',
                state: { updateNotif: success} 
            }}/>) 
        }
        if (permissionError) {
            return (
                <React.Fragment>
                <BreadCrumbs />
                <div className="col-md-12">
                    <Errors error={permissionError} /> 
                </div>
                </React.Fragment>
            )            
        }
        if (dependencyComplete || error) {
            return (
                <Fragment>
                    <BreadCrumbs />
                    <div className="col-md-12">
                        <Errors error={error} /> 
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            {/*<div className="header"><h4 className="title">Register Point of Interest</h4></div>*/}

                            <div className="content">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <input ref="input" type="text" placeholder="type a place to center the map..."  className="form-control border-input" />
                                            </div>
                                        </div>                                
                                    </div>
                                </div>                                

                                <form onSubmit={this.onSubmit}>
                                    <Form {...this}/>
                                </form>                        
                            </div>
                        </div>
                    </div>
              </Fragment>
            );
        } else {
            return <div className="loading-screen"><Loader message={"Loading dependencies..."}/></div>
        }
    }
}

export default PointOfInterest;