import React, { Component } from "react";
import _ from "lodash";
import {Redirect} from "react-router-dom";

//common
import BreadCrumbs from "../../layout/breadcrumbs";
import Loader from "../../common/loader";
import Errors from "../../common/notif/errors";
import Success from "../../common/notif/success";

//model
import Municipality from "../../../models/Municipality";

//view
import ShowView from  "../../views/admin/municipality/show";

class Show extends Component {

    constructor (props) {

        super(props);

        this.provinceId =  _.get(this.props, "match.params.province_id");
        this.municipalityId =  _.get(this.props, "match.params.id");
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeCode = this.onChangeCode.bind(this);
        this.deleteAction = this.deleteAction.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            error: null,
            buttonDisabled: false,
            showLoader: false,
            name: "",
            code: "",
            updatedAt: "",
            createdAt: "",
            isSuccessful: props.isSuccessful
        };

    }

    async deleteAction () {
        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null,
            isEditMode: false
        });

        let {data, error} = await Municipality.Delete(this.municipalityId);
        if (data) {
            this.setState({
                redirect: true,
                success: "Municipality Deleted",
                error: null,
                showLoader: false,
                buttonDisabled: false,
            });
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
        }        
    }    


    onChangeName (e) {
        this.setState({
            name: e.target.value
        });
    }

    onChangeCode (e) {
        this.setState({
            code: e.target.value
        });
    }


    async componentDidMount () {
        let {data} = this.state;
        if (_.isEmpty(data) || _.isNil(data) ) {
            await this.getData(this.municipalityId);
        }
    }

    async getData ( id ) {
        let {data, error} = await Municipality.Show( id );
        if (error) this.setState({error: error});
        if (data && _.size(data) > 0) {
            this.setState({
                data: data,
                name: data.name,
                code: data.code,
                createdAt: data.createdAt,
                updatedAt: data.updatedAt
            });
        } else {
            this.setState({error: "No Record"});
        }
    }

    async onSubmit (e) {
        e.preventDefault();
        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null
        });

        let {name, code } = this.state; 
        const params = {
            name: name,
            code: code,
        };
        let {data, error} = await Municipality.Update(this.municipalityId, params);
        if (data) {
            this.setState({
                data: data,
                success: "Municipality Details Updated",
                error: null,
                showLoader: false,
                buttonDisabled: false,
                updatedAt: data.updatedAt
            });
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
        }
    }    

    render () {
        let {data, error, success} = this.state;        
        let {redirect = null} = this.state;
        if (redirect) {
            return (<Redirect to = {{
                pathname: `/admin/provinces/${this.provinceId}`,
                state: { deleteSuccess: this.state.success} 
            }}/>) 
        }

        if (_.size(data) > 0 || error) {
            return (
                <React.Fragment>
                    <BreadCrumbs />

                        <div className="col-md-12">
                            <Success success={success} />
                            <Errors error={error} /> 
                        </div>

                        <ShowView {...this}/>

                        {/*<MunicipalityTable data={ Municipality.reduceData({data: this.state.municipalities} ) } />*/}

                </React.Fragment>
            );
        } else {
            return <div className="loading-screen"><Loader /></div>
        }    
    }

}


export default Show;