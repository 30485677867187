import React, { Component } from "react";
import _ from "lodash";
import FormData from 'form-data';
// import {Redirect} from "react-router-dom";
//common
import BreadCrumbs from "../../layout/breadcrumbs";
import Loader from "../../common/loader";
import Errors from "../../common/notif/errors";
import Success from "../../common/notif/success";

//model
import Brand from "../../../models/Brand";


//view
import ShowView from  "../../views/admin/brand/show";

class Show extends Component {

    constructor (props) {

        super(props);

        this.modalElement = "#brand-modal";
        this.brandId =  _.get(this.props, "match.params.id");
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeCode = this.onChangeCode.bind(this);
        this.onChangeWidth = this.onChangeWidth.bind(this);
        this.onChangeHeight = this.onChangeHeight.bind(this);
        this.deleteAction = this.deleteAction.bind(this);
        this.onChangeIcon = this.onChangeIcon.bind(this);
        this.onChangeFilterEnabled = this.onChangeFilterEnabled.bind(this);
        this.onChangeToggleBarEnabled = this.onChangeToggleBarEnabled.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            error: null,
            buttonDisabled: false,
            showLoader: false,
            name: "",
            code: "",
            updatedAt: "",
            createdAt: "",
            icon: "",
            iconUrl: "",
            width: "",
            height: "",
            filterEnabled: "",
            toggleBarEnabled: "",
            isSuccessful: props.isSuccessful
        };

    }

    async deleteAction () {
        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null,
            isEditMode: false
        });

        let {data, error} = await Brand.Delete(this.brandId);
        if (data) {
            this.setState({
                redirect: true,
                success: "Brand Deleted",
                error: null,
                showLoader: false,
                buttonDisabled: false,
            });
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
        }        
    }    

    onChangeIcon (e) {
        let _file = _.first(e.target.files);
        if (_file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({
                    iconUrl: e.target.result,
                    icon: _file
                });
            };
            reader.readAsDataURL(_file);
        }

    }
    onChangeName (e) {
        this.setState({
            name: e.target.value
        });
    }

    onChangeCode (e) {
        this.setState({
            code: e.target.value
        });
    }

    onChangeWidth (e) {
        this.setState({
            width: e.target.value
        });
    }

    onChangeHeight (e) {
        this.setState({
            height: e.target.value
        });
    }

    onChangeFilterEnabled (checked) {
        this.setState({
            filterEnabled: checked
        });
    }

    onChangeToggleBarEnabled (checked) {
        this.setState({
            toggleBarEnabled: checked
        });
    }

    async componentDidMount () {
        let {data} = this.state;
        if (_.isEmpty(data) || _.isNil(data) ) {
            await this.getData(this.brandId);
        }
    }

    async getData ( id ) {
        let {data, error} = await Brand.Show( id );
        if (error) this.setState({error: error});
        if (data && _.size(data) > 0) {
            this.setState({
                data: data,
                name: data.name,
                code: data.code,
                iconUrl: data.iconUrl,
                width: data.width,
                height: data.height,
                filterEnabled: data.filterEnabled,
                toggleBarEnabled: data.toggleBarEnabled,
                createdAt: data.createdAt,
                updatedAt: data.updatedAt
            });
        } else {
            this.setState({error: "No Record"});
        }
    }

    async onSubmit (e) {
        e.preventDefault();
        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null
        });

        let { name, code, width, height, filterEnabled, toggleBarEnabled} = this.state; 

        let {data, error} = await Brand.Update(this.brandId, { name, code, width, height, filterEnabled, toggleBarEnabled} );
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
            return;
        }        
        if (data) {
            let iconResult = {};
            if (this.state.icon) {
                
                let formData = new FormData();
                formData.append("icon", this.state.icon);
                iconResult = await Brand.UploadIcon(this.brandId, formData);
                if (iconResult.error) {
                    this.setState({
                        error: iconResult.error,
                        buttonDisabled: false,
                        showLoader: false
                    });
                    return false;
                }
            }

            this.setState({
                data: iconResult.data || data ,
                iconUrl: _.get(iconResult, "data.iconUrl", this.state.iconUrl),
                success: "Brand Details Updated",
                error: null,
                showLoader: false,
                buttonDisabled: false,
                updatedAt: data.updatedAt
            });
        }

    }    

    render () {
        let {data, error, success} = this.state;        
        // let {redirect = null} = this.state;
        // // if (redirect) {
        // //     return (<Redirect to = {{
        // //         pathname: '/admin/brands',
        // //         state: { deleteSuccess: this.state.success} 
        // //     }}/>) 
        // // }
        if (_.size(data) > 0 || error) {
            return (
                <React.Fragment>
                    <BreadCrumbs />

                        <div className="col-md-12">
                            <Success success={success} />
                            <Errors error={error} /> 
                        </div>

                        <ShowView {...this} />


                </React.Fragment>
            );
        } else {
            return <div className="loading-screen"><Loader /></div>
        }    
    }

}


export default Show;