import React, { Component, Fragment } from "react";
import Error from "../../common/notif/errors";


//model
import Province from "../../../models/Province";

//view
import View from  "../../views/admin/province/create";

class Create extends Component {

    constructor (props) {
        super(props);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeCode = this.onChangeCode.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            error: null,
            buttonDisabled: false,
            showLoader: false,
            name: "",
            code: "",
            updatedAt: "",
            createdAt: "",
            isSuccessful: props.isSuccessful
        };
    }

    onChangeName (e) {
        this.setState({
            name: e.target.value
        });
    }

    onChangeCode (e) {
        this.setState({
            code: e.target.value
        });
    }

    closeModal () {
        window.$("#province-modal").modal("hide").on("hidden.bs.modal", (e) => {
            this.setState({
                buttonDisabled: false
            });
        });
    }

    async onSubmit (e) {
        e.preventDefault();
        this.setState({
            buttonDisabled: true,
            showLoader: true,
            error: null
        });

        let { name, code } = this.state; 
        const params = {
            name: name,
            code: code
        };
        let {data, error} = await Province.Create(params);
        if (data) {
            this.reset();
            this.closeModal();
            this.state.isSuccessful("New Province Added");
        }
        if (error) {
            this.setState({
                error: error,
                buttonDisabled: false,
                showLoader: false
            });
        }
    }

    reset () {
        this.setState({
            error: null,
            buttonDisabled: false,
            showLoader: false,
            name: "",
            code: "",
            updatedAt: "",
            createdAt: ""
        });
    }

    render () {
        const {error, showLoader} = this.state;
        let hasError = (error) ? <Error error={error} /> : null;
        
        return (
            <Fragment>
                <div>{hasError}</div>
                <View {...this} showLoader={showLoader} />
            </Fragment>
        );
    }


}

export default Create;