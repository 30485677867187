import React, { Fragment } from "react";

//common
import Input from "../../../common/form/input";
import ToggleSwitch from "../../../common/toggle-switch";

const Form = props => {
    const {isEditMode} = props.state; 
    return (

        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <Input 
                        label={"Code"} 
                        onChange={props.onChangeCode} 
                        value={props.state.code} 
                        name={"code"} 
                        disabled={!isEditMode}                        
                    />  
                </div>   
            </div>           
            <div className="row">
                <div className="col-md-12">
                    <Input 
                        label={"Name"} 
                        onChange={props.onChangeName} 
                        value={props.state.name} 
                        name={"name"} 
                        disabled={!isEditMode}                        
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <Input 
                        type={"file"}
                        label={"Icon"} 
                        onChange={props.onChangeIcon} 
                        name={"icon"} 
                        disabled={!isEditMode}   
                        required={false}         
                        accept={"image/x-png,image/gif,image/jpeg"}            
                    />

                    <div className="row">
                        <div className="col-md-6">
                            <Input 
                                type="number"
                                label={"Width (px)"}
                                onChange={props.onChangeWidth} 
                                name={"width"} 
                                disabled={!isEditMode}   
                                required={false}         
                                value={props.state.width}
                            />
                        </div>
                        <div className="col-md-6">
                            <Input 
                                type={"number"}                            
                                label={"Height (px)"} 
                                onChange={props.onChangeHeight} 
                                name={"icon"} 
                                disabled={!isEditMode}   
                                required={false}         
                                value={props.state.height}                                         
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <ToggleSwitch label={"Allow Filter"} name="filterEnabled" disabled={!isEditMode} value={props.state.filterEnabled} handler={props.onChangeFilterEnabled} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <ToggleSwitch label={"Display On Toggle Bar"} name="toggleBarEnabled" disabled={!isEditMode} value={props.state.toggleBarEnabled} handler={props.onChangeToggleBarEnabled} />
                        </div>
                    </div>

                </div>
                <div className="col-md-6">
                    {
                        (props.state.iconUrl) ? <img alt="Brand Pin Preview" className="pin-icon-display" src={props.state.iconUrl} /> : null
                    }
                </div>
            </div>

            <div className="row">

            </div>            
        </Fragment>            
    );
}


export default Form;
