const navigation = [
  {
    route: "/dashboard",
    icon: "ti-panel",
    label: "Dashboard",
    subNav: [],
    roles: true
  },
  // {
  //   route: "/map-overview",
  //   icon: "ti-map",
  //   label: "Map Overview",
  //   subNav: [],
  //   roles: ["super_admin", "admin", "user"]
  // },
  {
    route: "/point-of-interests",
    icon: "ti-location-pin",
    label: "Point of Interest",
    subNav: [
      {
        route: "/point-of-interests/maps",
        label: "View Map",
        roles: ["super_admin", "admin", "user"]
      },
      {
        route: "/point-of-interests/register",
        label: "Register POI",
        roles: ["super_admin", "admin", "user"]
      }
    ],
    roles: ["super_admin", "admin", "user"]
  },
  // {
  //   route: "/refilling-plants",
  //   icon: "ti-pin-alt",
  //   label: "Refilling Plants",
  //   subNav: [
  //     {
  //       route: "/refilling-plants/maps",
  //       label: "View Map",
  //       roles: ["super_admin", "admin", "user"]
  //     },
  //     {
  //       route: "/refilling-plants/register",
  //       label: "Register Plant",
  //       roles: ["super_admin", "admin", "user"]
  //     }
  //   ],
  //   roles: ["super_admin", "admin", "user"]
  // },
  // {
  //   route: "geofence",
  //   icon: "ti-map-alt",
  //   label: "Geofence",
  //   subNav: [
  //     {
  //       route: "/geofence/maps",
  //       label: "View Map",
  //       roles: ["super_admin", "admin", "user"]
  //     },
  //     {
  //       route: "/geofence/register",
  //       label: "Register Geofence",
  //       roles: ["super_admin", "admin", "user"]
  //     }
  //   ],
  //   roles: ["super_admin", "admin", "user"]
  // },
  {
    route: "admin",
    icon: "ti-lock",
    label: "Admin Setup",
    roles: ["super_admin", "admin"],
    subNav: [
      {
        route: "/admin/refilling-plants",
        label: "Refilling Plants",
        roles: ["super_admin"]
      },
      {
        route: "/admin/tiles",
        label: "Tile Maintenance",
        roles: ["super_admin"]
      },    
      {
        route: "/admin/brands",
        label: "Category",
        roles: ["super_admin"]
      },    
      {
        route: "/admin/provinces",
        label: "Province",
        roles: ["super_admin"]
      },
      // {
      //   route: "/admin/districts",
      //   label: "District",
      //   roles: ["super_admin"]
      // },
      {
        route: "/admin/types",
        label: "Types",
        roles: ["super_admin"]
      },
      {
        route: "/admin/users",
        label: "Users",
        roles: ["super_admin","admin"]
      },
      {
        route: "/admin/system-logs",
        label: "System Logs",
        roles: ["super_admin"]
      }
    ]
  },
  // {
  //   route: "/profile",
  //   icon: "ti-user",
  //   label: "My Profile",
  //   roles: ["super_admin", "admin", "user"],
  //   subNav: []
  // }  
];


module.exports = navigation;