module.exports = {
  decryptKey: "ebWe3S63S6RmrDPR",
  storageKey: "bm_id_token",
  tokenKey: "bm_access_token",
  adminKey: "bm_admin_token",
  rbacKey: "bm_rbac",

  baseUrl: process.env.REACT_APP_AUTH_BASEURL,
  appId: process.env.REACT_APP_AUTH_APP_ID,
  appSecret: process.env.REACT_APP_AUTH_APP_SECRET,
  redirectUri: process.env.REACT_APP_AUTH_APP_REDIRECT_URI,
  encryptKey: process.env.REACT_APP_ENCRYPT_KEY


};